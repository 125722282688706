import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import ProgressBar from 'components/progress-bar/ProgressBar';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import './css/SectionI.css';
import { useFormContext } from 'ContextApi/FormContext';

const SectionI = () => {
	const [query] = useSearchParams();
	const currencies = [
		{ name: 'Nigerian Naira', symbol: '₦', code: 'NGN' },
		{ name: 'United States Dollar', symbol: '$', code: 'USD' },
		{ name: 'British Pound Sterling', symbol: '£', code: 'GBP' },
		{ name: 'Euro', symbol: '€', code: 'EUR' },
		{ name: 'Japanese Yen', symbol: '¥', code: 'JPY' },
		{ name: 'Swiss Franc', symbol: 'CHF', code: 'CHF' },
		{ name: 'Canadian Dollar', symbol: 'C$', code: 'CAD' },
		{ name: 'Australian Dollar', symbol: 'A$', code: 'AUD' },
		{ name: 'Indian Rupee', symbol: '₹', code: 'INR' },
		{ name: 'Chinese Yuan', symbol: '¥', code: 'CNY' },
		{ name: 'South African Rand', symbol: 'R', code: 'ZAR' },
		{ name: 'United Arab Emirates Dirham', symbol: 'د.إ', code: 'AED' },
		{ name: 'Singapore Dollar', symbol: 'S$', code: 'SGD' },
		{ name: 'Mexican Peso', symbol: 'Mex$', code: 'MXN' },
		{ name: 'Brazilian Real', symbol: 'R$', code: 'BRL' },
		{ name: 'Thai Baht', symbol: '฿', code: 'THB' },
		{ name: 'Hong Kong Dollar', symbol: 'HK$', code: 'HKD' },
		{ name: 'New Zealand Dollar', symbol: 'NZ$', code: 'NZD' },
		{ name: 'Swedish Krona', symbol: 'kr', code: 'SEK' },
		{ name: 'Norwegian Krone', symbol: 'kr', code: 'NOK' },
		{ name: 'Danish Krone', symbol: 'kr', code: 'DKK' },
		{ name: 'Russian Ruble', symbol: '₽', code: 'RUB' },
	];
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);
	const navigate = useNavigate();
	const userType = service.getUserType();

	const [isLoading, setIsLoading] = useState(false);
	const { formData, updateFormData } = useFormContext();

	const [form, setForm] = useState(
		formData.sectionIData.form || {
			current_salary: '',
			expected_salary: '',
			current_salary_currency: '',
			expected_salary_currency: '',
			hourly_rate: '',
			expected_income: '',
			service_charge: '',
		}
	);

	const [formErrors, setFormErrors] = useState(form);

	function handleOnChange(e) {
		const attribute = e.target.getAttribute('name');
		const value = e.target.value;

		setFormErrors({ ...formErrors, [attribute]: '' });

		if (attribute === 'hourly_rate') {
			const hourlyRate = parseFloat(value) || 0;
			const serviceCharge = hourlyRate * 0.1; // 10% of hourly rate
			const expectedIncome = hourlyRate - serviceCharge;

			setForm({
				...form,
				[attribute]: value,
				service_charge: serviceCharge.toFixed(2),
				expected_income: expectedIncome.toFixed(2),
			});
		} else {
			// Update form state for other fields
			setForm({ ...form, [attribute]: value });
		}
	}

	function hasErrors() {
		const freelancerFields = ['hourly_rate', 'expected_salary_currency', 'service_charge', 'expected_income'];
		const otherUserFields = ['current_salary', 'expected_salary', 'current_salary_currency', 'expected_salary_currency'];

		// Determine the fields to validate based on user type
		const fieldsToValidate = userType === service.FREELANCER ? freelancerFields : otherUserFields;

		for (const field of fieldsToValidate) {
			if (!form[field]) {
				validationHandler(field);
				console.warn(`{${field}} field is required`);
				return true;
			}
		}

		return false;
	}

	function validationHandler(field, errorMsg = 'field is required') {
		const formElement = document.forms[0].elements;
		formElement[field]?.focus();
		formElement[field]?.scrollIntoView({ block: 'center' });
		setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
	}

	function prepareFormForSubmission() {
		let updatedForm = { ...form };

		if (userType === service.FREELANCER) {
			updatedForm = {
				...updatedForm,
				current_salary: 0,
				expected_salary: 0,
				current_salary_currency: updatedForm.expected_salary_currency,
			};
		} else {
			updatedForm = {
				...updatedForm,
				hourly_rate: 0,
				service_charge: 0,
				expected_income: 0,
			};
		}

		return updatedForm;
	}

	function handleSubmit() {
		const updatedForm = prepareFormForSubmission(userType);
		if (!hasErrors()) {
			setIsLoading(true);
			function successHandler() {
				updateFormData('sectionIData', { form });
				const lastPoint = query.get('section')
				localStorage.setItem('lastOnboardingPoint',lastPoint)
				navigate({ search: `?section=8` });
			}
			function errorHandler(response) {
				console.log(response);
			}
			function finalHandler() {
				setIsLoading(false);
			}
			if (userType === service.FREELANCER) {
				api.freelancers.profile.update(updatedForm).then(successHandler).catch(errorHandler).finally(finalHandler);
			} else {
				api.talents.profile.update(updatedForm).then(successHandler).catch(errorHandler).finally(finalHandler);
			}
		}
	}
	return (
		<div id="brand-section" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-3" style={{ padding: 0 }}>
					<div className="header row justify-content-between py-3 align-items-center">
						<h2 className="display col-lg-6">How much do you charge ?</h2>
						<div className="col-lg-5">
							<ProgressBar percent="64" />
						</div>
						<p className="my-3 col-8 text_intro">
							Clients will see this rate on your profile and in search results once you publish your profile.
							<i className="img"></i>
						</p>
					</div>
				</div>
			</div>
			<div className="container-01" style={{ padding: 0 }}>
				<div className="form">
					<form>
						<div className="row flex-column gap-1">
							{/* talents */}
							{userType !== service.FREELANCER && (
								<div className="col-lg-7">
									<div className="control-form mb-0 ">
										<div className="d-lg-flex justify-content-between gap-4">
											<div className="w-100">
												<div className="d-lg-flex justify-content-between flex-column" style={{ flex: 1 }}>
													<div className="con-title d-flex align-items-center gap-3 mb-3">
														<h2 style={{ fontSize: '20px' }}>What is your current annual salary ?</h2>
													</div>
													{formErrors.current_salary && <label className="error-msg mb-3 col-lg-6">{formErrors.current_salary}</label>}
												</div>
												<div className="mb-3">
													<div className="input-group ">
														<select
															className={formErrors.current_salary_currency ? 'error form-control' : 'form-control'}
															style={{ flex: 'unset', width: '200px' }}
															aria-label="Select input"
															name="current_salary_currency"
															onChange={handleOnChange}
															value={form.current_salary_currency || currencies[0].code}>
															{currencies.map((currency, index) => {
																return (
																	<option value={currency.code} key={index} className="my-3 mx-2">
																		{' '}
																		{currency.name} ({currency.symbol})
																	</option>
																);
															})}
														</select>
														<input
															type="number"
															placeholder="Annual Salary "
															name="current_salary"
															className={formErrors.current_salary ? 'error form-control' : 'form-control'}
															value={form.current_salary}
															onChange={handleOnChange}
															min={1}
															style={{ flex: '7 7 auto' }}
														/>
													</div>
												</div>

												<label className="error-msg mb-3"> {formErrors.current_salary_currency}</label>
											</div>
										</div>
									</div>
								</div>
							)}
							{userType !== service.FREELANCER && (
								<div className="col-lg-7">
									<div className="control-form  mb-0">
										<div className="d-lg-flex justify-content-between gap-4">
											<div className="w-100">
												<div className="d-flex justify-content-between flex-column" style={{ flex: 1 }}>
													<div className="con-title d-flex align-items-center gap-3 mb-1">
														<h2 style={{ fontSize: '20px' }}>What is your expected salary ?</h2>
													</div>

													<label className="error-msg mb-3 col-lg-6">{formErrors.expected_salary}</label>
												</div>

												<div className="input-group mb-1">
													<select
														className={formErrors.expected_salary_currency ? 'form-select error' : 'form-select'}
														style={{ flex: 'unset', width: '200px' }}
														aria-label="Select input"
														name="expected_salary_currency"
														onChange={handleOnChange}
														value={form.expected_salary_currency || currencies[0].code}>

														{currencies.map((currency, index) => {
															return (
																<option value={currency.code} key={index} className="my-3 mx-2">
																	{' '}
																	{currency.name} ({currency.symbol})
																</option>
															);
														})}
													</select>

													<input
														type="number"
														placeholder="Annual Salary "
														name="expected_salary"
														className={formErrors.expected_salary ? 'error form-control' : 'form-control'}
														value={form.expected_salary}
														onChange={handleOnChange}
														min={1}
														style={{ flex: '7 7 auto' }}
													/>
												</div>

												<div>
													<label className="error-msg mb-3"> {formErrors.expected_salary_currency}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{/* Freelancers */}
							{userType === service.FREELANCER && (
								<div className="col-lg-7">
									<div className="control-form mb-0 ">
										<div className="d-lg-flex justify-content-between gap-4">
											<div className="w-100">
												<div className="d-flex justify-content-between flex-column" style={{ flex: 1 }}>
													<div className="con-title d-flex align-items-center gap-3 mb-1">
														<h2 style={{ fontSize: '20px' }}>Hourly rate (per hour)</h2>
													</div>

													{formErrors.hourly_rate && <label className="error-msg mb-3 col-lg-6">{formErrors.hourly_rate}</label>}
												</div>

												<div className="input-group mb-1">
													<select
														className={formErrors.expected_salary_currency ? 'form-select error' : 'form-select'}
														style={{ flex: 3 }}
														aria-label="Select input"
														name="expected_salary_currency"
														onChange={handleOnChange}
														value={form.expected_salary_currency}>
														<option defaultValue value={''}>
															Select Currency
														</option>

														{currencies.map((currency, index) => {
															return (
																<option value={currency.code} key={index} className="my-3 mx-2">
																	{' '}
																	{currency.name}
																</option>
															);
														})}
													</select>

													<input type="number" placeholder="Hourly rate " name="hourly_rate" className={formErrors.hourly_rate ? 'error form-control' : 'form-control'} value={form.hourly_rate} onChange={handleOnChange} min={1} style={{ flex: '7 7 auto' }} />
												</div>

												{/* <h2 className='warning_con'> <img src="/warn.svg" alt="" /> Total amount the client would see</h2> */}

												<div>
													<label className="error-msg mb-3"> {formErrors.hourly_rate}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{userType === service.FREELANCER && (
								<div className="col-lg-7">
									<div className="control-form  mb-0">
										<div className="d-lg-flex justify-content-between gap-4">
											<div className="w-100">
												<div className="d-flex justify-content-between flex-column" style={{ flex: 1 }}>
													<div className="con-title d-flex align-items-center gap-3 mb-1">
														<h2 style={{ fontSize: '20px' }}>Service charge</h2>
													</div>

													<label className="error-msg mb-3 col-lg-6">{formErrors.service_charge}</label>
												</div>

												<div className="input-group mb-1">
													<input
														type="number"
														placeholder="Service charge "
														name="service_charge"
														className={formErrors.service_charge ? 'error form-control' : 'form-control'}
														value={form.service_charge}
														onChange={handleOnChange}
														min={1}
														disabled
														style={{ flex: '7 7 auto' }}
													/>
												</div>
												<div>
													<label className="error-msg mb-3"> {formErrors.service_charge}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{userType === service.FREELANCER && (
								<div className="col-lg-7">
									<div className="control-form mb-0 ">
										<div className="d-lg-flex justify-content-between gap-4">
											<div className="w-100">
												<div className="d-flex justify-content-between flex-column" style={{ flex: 1 }}>
													<div className="con-title d-flex align-items-center gap-3 mb-1">
														<h2 style={{ fontSize: '20px' }}>Expected Income</h2>
													</div>

													<label className="error-msg mb-3 col-lg-6">{formErrors.expected_income}</label>
												</div>

												<div className="input-group mb-1">
													<input
														type="number"
														placeholder="Annual Salary "
														name="expected_income"
														className={formErrors.expected_income ? 'error form-control' : 'form-control'}
														value={form.expected_income}
														onChange={handleOnChange}
														min={1}
														disabled
														style={{ flex: '7 7 auto' }}
													/>
												</div>
												<div>
													<label className="error-msg mb-3"> {formErrors.expected_salary_currency}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="control-form d-lg-flex gap-3 " style={{ borderTop: '1px solid #ECECEC', padding: '20px 0' }}>
							<div className="mb-5 mb-lg-0">
								<Button
									text={
										<>
											<i className="fa-solid fa-arrow-left"></i>
											<h1>One Step back</h1>
										</>
									}
									primaryNull
									onClick={() => navigate(-1)}
								/>
							</div>

							<div className="mb-4 mb-lg-0">
								<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SectionI;

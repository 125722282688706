export const jobCategories = [
	{
		category: 'Administrative and Clerical',
		subcategories: ['Office Management', 'Data Entry', 'Reception', 'Secretarial', 'Clerical Support', 'Records Management', 'Mailroom Operations', 'Document Control', 'Calendar Management', 'Office Coordination'],
	},
	{
		category: 'Agriculture and Farming',
		subcategories: ['Crop Production', 'Livestock Management', 'Agribusiness', 'Sustainable Agriculture', 'Farm Management', 'Agri-Technology', 'Agricultural Research', 'Soil Science', 'Irrigation Systems', 'Agricultural Marketing'],
	},
	{
		category: 'Architecture and Design',
		subcategories: ['Architectural Design', 'Interior Design', 'Landscape Architecture', 'Urban Planning', 'Sustainable Design', 'Construction Design', '3D Rendering', 'Space Planning', 'Building Information Modeling (BIM)', 'Design Project Management'],
	},
	{
		category: 'Arts and Entertainment',
		subcategories: ['Performing Arts', 'Music Production', 'Film and Television', 'Fine Arts', 'Stage Production', 'Dance', 'Voice Acting', 'Set Design', 'Costume Design', 'Cinematography'],
	},
	{
		category: 'Construction and Skilled Trades',
		subcategories: ['Construction Management', 'Carpentry', 'Plumbing', 'Electrical Work', 'Masonry', 'Painting and Decorating', 'Welding', 'Heavy Equipment Operation', 'Roofing', 'Building Inspection'],
	},
	{
		category: 'Consulting',
		subcategories: [
			'Management Consulting',
			'Strategy Consulting',
			'Financial Consulting',
			'Technology Consulting',
			'Business Process Consulting',
			'Human Resources Consulting',
			'IT Consulting',
			'Sustainability Consulting',
			'Operations Consulting',
			'Healthcare Consulting',
		],
	},
	{
		category: 'Creative Arts',
		subcategories: ['Graphic Design', 'Illustration', 'Animation', 'Photography', 'Video Production', 'Game Design', 'Motion Graphics', '3D Modeling', 'Art Direction', 'Visual Effects (VFX)'],
	},
	{
		category: 'Customer Service',
		subcategories: ['Call Center', 'Help Desk', 'Customer Support', 'Client Relations', 'Technical Support', 'Customer Success', 'Account Management', 'Complaint Resolution', 'After-Sales Support', 'Customer Experience'],
	},
	{
		category: 'Education and Training',
		subcategories: ['Teaching', 'Training', 'Educational Administration', 'Curriculum Development', 'Instructional Design', 'E-learning', 'Special Education', 'Adult Education', 'Tutoring', 'Corporate Training'],
	},
	{
		category: 'Engineering and Technical',
		subcategories: [
			'Mechanical Engineering',
			'Civil Engineering',
			'Electrical Engineering',
			'Software Engineering',
			'Industrial Engineering',
			'Chemical Engineering',
			'Environmental Engineering',
			'Aerospace Engineering',
			'Manufacturing Engineering',
			'Systems Engineering',
		],
	},
	{
		category: 'Environmental Services',
		subcategories: [
			'Environmental Engineering',
			'Ecology',
			'Sustainability Management',
			'Waste Management',
			'Environmental Policy',
			'Conservation Biology',
			'Water Resources Management',
			'Environmental Impact Assessment',
			'Renewable Energy',
			'Climate Change Mitigation',
		],
	},
	{
		category: 'Finance and Accounting',
		subcategories: ['Accounting', 'Finance', 'Auditing', 'Taxation', 'Financial Analysis', 'Bookkeeping', 'Payroll Management', 'Risk Management', 'Financial Planning', 'Investment Banking'],
	},
	{
		category: 'Healthcare and Medical',
		subcategories: ['Medical Practitioners', 'Allied Health', 'Medical Technology', 'Healthcare Administration', 'Nursing', 'Pharmacy', 'Mental Health Services', 'Dentistry', 'Public Health', 'Medical Research'],
	},
	{
		category: 'Hospitality and Travel',
		subcategories: ['Hotel Management', 'Culinary Arts', 'Travel Services', 'Event Planning', 'Tourism Management', 'Guest Services', 'Resort Management', 'Restaurant Management', 'Food and Beverage Services', 'Cruise Line Services'],
	},
	{
		category: 'Human Resources',
		subcategories: ['Recruitment', 'HR Management', 'Payroll', 'Employee Relations', 'Compensation and Benefits', 'Training and Development', 'Talent Acquisition', 'Workforce Planning', 'Organizational Development', 'Labor Law Compliance'],
	},
	{
		category: 'Information Technology',
		subcategories: ['Software Development', 'Networking', 'Cybersecurity', 'IT Support', 'Database Administration', 'Cloud Computing', 'IT Project Management', 'Web Development', 'Artificial Intelligence', 'Blockchain Development'],
	},
	{
		category: 'Legal',
		subcategories: ['Legal Practice', 'Legal Support', 'Compliance', 'Judiciary', 'Contract Law', 'Corporate Law', 'Intellectual Property', 'Family Law', 'Litigation', 'Legal Research'],
	},
	{
		category: 'Logistics and Supply Chain',
		subcategories: [
			'Supply Chain Management',
			'Logistics Coordination',
			'Warehouse Management',
			'Inventory Control',
			'Distribution Planning',
			'Transportation Management',
			'Import/Export Operations',
			'Procurement',
			'Freight Forwarding',
			'Supply Chain Analytics',
		],
	},
	{
		category: 'Manufacturing and Production',
		subcategories: ['Production Management', 'Quality Assurance', 'Operations', 'Supply Chain Management', 'Lean Manufacturing', 'Process Engineering', 'Product Assembly', 'Maintenance and Repair', 'Industrial Safety', 'Logistics Coordination'],
	},
	{
		category: 'Marketing and Advertising',
		subcategories: ['Digital Marketing', 'Content Marketing', 'Advertising Management', 'Brand Management', 'Search Engine Optimization (SEO)', 'Social Media Marketing', 'Email Marketing', 'Influencer Marketing', 'Product Marketing', 'Marketing Analytics'],
	},
	{
		category: 'Media and Journalism',
		subcategories: ['Broadcast Journalism', 'News Reporting', 'Editorial Writing', 'Media Production', 'Video Editing', 'Public Broadcasting', 'Investigative Journalism', 'Photojournalism', 'Media Ethics', 'Journalism Research'],
	},
	{
		category: 'Public Relations',
		subcategories: ['Corporate Communications', 'Public Affairs', 'Media Relations', 'Crisis Management', 'Event Coordination', 'Brand Reputation Management', 'Internal Communications', 'Press Release Writing', 'Community Relations', 'Social Media Relations'],
	},
	{
		category: 'Real Estate',
		subcategories: [
			'Property Management',
			'Real Estate Sales',
			'Real Estate Development',
			'Valuation and Appraisal',
			'Commercial Real Estate',
			'Residential Real Estate',
			'Real Estate Investment',
			'Real Estate Law',
			'Leasing Management',
			'Real Estate Marketing',
		],
	},
	{
		category: 'Research and Development',
		subcategories: ['Scientific Research', 'Product Development', 'Market Research', 'Innovation Management', 'Data Analysis', 'Clinical Research', 'Laboratory Management', 'Research Operations', 'Research Project Management', 'Prototyping'],
	},
	{
		category: 'Retail and E-commerce',
		subcategories: ['Retail Management', 'Merchandising', 'E-commerce Operations', 'Customer Experience', 'Inventory Management', 'Online Marketplace Management', 'Product Categorization', 'Retail Analytics', 'Supply Chain Logistics', 'Visual Merchandising'],
	},
	{
		category: 'Sales and Business Development',
		subcategories: ['Sales Management', 'Business Development', 'Retail Sales', 'Channel Sales', 'Inside Sales', 'Outside Sales', 'Sales Operations', 'Lead Generation', 'Account Executive', 'Territory Sales'],
	},
	{
		category: 'Security and Defense',
		subcategories: ['Physical Security', 'Cybersecurity', 'Law Enforcement', 'Military Operations', 'Emergency Response', 'Homeland Security', 'Private Security', 'Intelligence Analysis', 'Security Consulting', 'Surveillance Operations'],
	},
	{
		category: 'Transportation',
		subcategories: ['Fleet Management', 'Public Transportation', 'Commercial Driving', 'Transportation Planning', 'Aviation', 'Rail Transport', 'Shipping and Maritime'],
	},
];

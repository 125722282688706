import React, { useState, useEffect, forwardRef } from 'react';
import './css/FileUploadComponent.style.sass';

export const TextUploadComponent = ({ onUpload, uploadedText }) => {
	const [text, setText] = useState('');
	const [isEditing, setIsEditing] = useState(!uploadedText);

	const handleTextChange = (event) => {
		setText(event.target.value);
	};

	const handleSave = () => {
		onUpload(text);
		setIsEditing(false);
	};

	const handleEdit = () => {
		setIsEditing(true);
		setText(uploadedText);
	};

	const handleDelete = () => {
		setText('');
		setIsEditing(true);
	};

	return (
		<div id="Text_Component">
			{isEditing ? (
				<div className="normal">
					<h3>Project Description</h3>
					<textarea value={text} onChange={handleTextChange} placeholder="What do you want to write about" className="w-100" rows={9} style={{ border: '2px solid black', borderRadius: '4px' }} />
					<div className="d-flex gap-4 mt-3">
						<button className="btn-transparent" onClick={handleDelete}>
							<img src="/img/svg/cancel.svg" alt="" style={{ width: '25px' }} /> Cancel
						</button>
						<button className="btn-solid" onClick={handleSave}>
							<img src="/img/svg/save.svg" alt="" style={{ width: '25px' }} /> Save
						</button>
					</div>
				</div>
			) : (
				<div className="editing">
					<h3>Saved Text</h3>
					<div style={{ width: '100%' }}>
						<p className="text-justify w-100">{uploadedText}</p>
					</div>
					<div className="d-flex gap-4 mt-3">
						<button className="btn-transparent" onClick={handleDelete}>
							<img src="/img/svg/delete.svg" alt="" style={{ width: '25px' }} /> Delete
						</button>
						<button className="btn-solid" onClick={handleEdit}>
							<img src="/img/svg/edit.svg" alt="" style={{ width: '25px' }} /> Edit
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
export const VideoUploadComponent = forwardRef(({ onUpload, videos, setVideos, MAX_VIDEO_SIZE_BYTES, MAX_VIDEO_SIZE_MB }, ref) => {
	// const [videoDescription, setVideoDescription] = useState('');

	const handleEditVideo = (index) => {
		const fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.accept = 'video/mp4';
		fileInput.addEventListener('change', (event) => {
			const newVideoFile = event.target.files[0];
			if (newVideoFile) {
				const updatedVideos = [...videos];
				updatedVideos[index] = newVideoFile;
				setVideos(updatedVideos);
			}
		});
		fileInput.click();
	};

	const handleDeleteVideo = (index) => {
		const updatedVideos = videos.filter((_, i) => i !== index);
		setVideos([...updatedVideos]);
		ref.current.value = null;
	};

	useEffect(() => {
		return () => {
			videos.forEach((video) => URL.revokeObjectURL(video));
		};
	}, [videos]);

	return (
		<div id="Video_Component">
			<div className="d-flex flex-column gap-1  mb-3">
				<p>Upload Video files</p>
				<p style={{ fontSize: '14px', color: 'darkred' }}>(Only 2 mp4 files are allowed)</p>
			</div>
			{videos.length <= 0 ? (
				<div className="d-flex justify-content-center align-items-center flex-column">
					<div style={{ width: '208px' }}>
						<img src="/img/svg/folder.svg" alt="noimage" className="w-full h-full" style={{ objectFit: 'cover' }} />
					</div>
					<p>No Video Uploaded Yet</p>
				</div>
			) : (
				<div>
					<div className="d-flex gap-3 overflow-auto mt-2 mb-3 imagescroll">
						{videos.map((video, index) => (
							<div key={index} className="uploadedVideo">
								<video controls style={{ width: '100%', height: '250px' }}>
									<source src={URL.createObjectURL(video)} type="video/mp4" />
								</video>
								<div className="d-flex gap-2  actionsOnUploaded">
									<button className="" onClick={() => handleEditVideo(index)}>
										<img src={'/img/svg/edit2.svg'} alt="Edit" style={{ width: '25px', height: '25px' }} />
									</button>
									<button className="btn-sm" onClick={() => handleDeleteVideo(index)}>
										<img src={'/img/svg/delete2.svg'} alt="Edit" style={{ width: '25px', height: '25px' }} />
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
});

export const ImageUploadComponent = forwardRef(({ onUpload, images, setImages }, ref) => {
	const handleEditImage = (index) => {
		const fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.accept = 'image/png, image/jpeg, image/jpg';
		fileInput.addEventListener('change', (event) => {
			const newImageFile = event.target.files[0];
			if (newImageFile) {
				const updatedImages = [...images];
				updatedImages[index] = newImageFile;
				setImages(updatedImages);
			}
		});
		fileInput.click();
	};

	const handleDeleteImage = (index) => {
		const updatedImages = images.filter((_, i) => i !== index);
		setImages(updatedImages);
		if (ref.current) {
			ref.current.value = null;
		}
	};

	useEffect(() => {
		return () => {
			images.forEach((image) => URL.revokeObjectURL(image));
		};
	}, [images]);

	return (
		<div id="Image_Component">
			<div className="d-flex flex-column gap-1 mb-3">
				<p>Upload Image files</p>
				<p style={{ fontSize: '14px', color: 'darkred' }}>(Only JPEGs, PNG images are allowed)</p>
			</div>
			{images.length <= 0 ? (
				<div className="d-flex justify-content-center align-items-center flex-column">
					<div style={{ width: '208px' }}>
						<img src="/img/svg/folder.svg" alt="noimage" className="w-full h-full" style={{ objectFit: 'cover' }} />
					</div>
					<p>No Image Uploaded Yet</p>
				</div>
			) : (
				<div className="d-flex gap-3 overflow-auto mt-2 mb-3 imagescroll">
					{images.map((image, index) => (
						<div key={index} className="uploaded_img">
							<img src={URL.createObjectURL(image)} alt={`${index + 1}`} style={{ width: '100%', height: '250px' }} />
							<div className="d-flex gap-2 actionsOnUploaded">
								<button className="btn-sm" onClick={() => handleEditImage(index)}>
									<img src={'/img/svg/edit2.svg'} alt="Edit" style={{ width: '25px', height: '25px' }} />
								</button>
								<button className="btn-sm" onClick={() => handleDeleteImage(index)}>
									<img src={'/img/svg/delete2.svg'} alt="Delete" style={{ width: '25px', height: '25px' }} />
								</button>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
});

import Loader from '../Loader';

function LoaderButton({ isLoading, text, arrowDirection, ...rest }) {
	const { textStyle, outerConStyle } = rest; // {TEXT STYLE} - GIVE STYLE TO THE TEXT; {OUTER CON STYLE} - GIVE STYLE TO THE PARENT DIV

	return (
		<div style={{ width: '100px', ...outerConStyle }} className="d-flex align-items-center justify-content-center gap-3">
			{isLoading ? (
				<Loader />
			) : (
				<>
					<h1 style={{ fontSize: '1.1rem', ...textStyle }}>{text}</h1>
					{arrowDirection ? arrowDirection : <i className="fa-solid fa-arrow-right"></i>}
				</>
			)}
		</div>
	);
}

export default LoaderButton;

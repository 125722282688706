export const slideOutSidebar = (evt) => {
  const sidebar = document
    .querySelector('#Navbar_Main_Container .con-sidebar .sidebar')
  if (!evt.target.closest('.sidebar')
    && !evt.target.closest('.con-menu ')
    && sidebar?.classList.contains('active')
  ) {
    sidebar.classList.add('slide-out');
    sidebar.classList.remove('active');
  }
}
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import './resetpassword.css';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { auth } from 'services/api/auth';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import Button from 'components/buttons/Button';
const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isPassword, setIsPassword] = useState(false);
    const [isconfirmPassword, setIsConfirmPassword] = useState(false);
    const [token, setToken] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenFromUrl = searchParams.get('token');
        // console.log(tokenFromUrl)
        setToken(tokenFromUrl);
    }, [location.search]);

    function viewPassword() {
        setIsPassword(!isPassword);
    }
    function viewConfirmPassword() {
        setIsConfirmPassword(!isconfirmPassword);
    }
    const initailValue = {
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required').min(8, 'Password must  be at least 8 characters long'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required('Please confirm your password'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        auth
            .resetPassword(token, { password: values.password })
            .then((res) => navigate('/login'))
            .catch((err) => console.log(err));
        setSubmitting(false);
    };

    return (
        <div>
            <Navbar
                bgColor={'white'}
                centerContent={
                    <ul className="d-flex align-items-center justify-content-between gap-4">
                        <li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
                            Discover Talents
                        </li>
                        <li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
                            Discover Opportunities
                        </li>
                        <li>
                            <Link to="/aboutus">About {service.brand.name}</Link>
                        </li>
                    </ul>
                }
                sidebarContent={
                    <>
                        <ul id="con-navbar-sidebar">
                            <li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
                                Discover Talents
                            </li>
                            <li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
                                Discover Opportunities
                            </li>
                            <Link to="">
                                <li>About {service.brand.name}s</li>
                            </Link>
                            <Link to="/register" className="active">
                                <li>Register</li>
                            </Link>
                        </ul>
                    </>
                }
            />

            <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Formik initialValues={initailValue} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting }) => (
                        <div style={{ position: 'relative' }}>
                            <div className="logo_symbol e_logo_symbol">
                                <div className="e_img-con">
                                    <img src="/favicon.ico.svg" alt="" />
                                </div>
                            </div>
                            <Form className="_forms">
                                <div className="mb-3 mt-5" style={{ textAlign: 'center' }}>
                                    <h4 className="my-3 ">Resest Password</h4>
                                    <p className="my-2">Enter your new password to continue</p>
                                </div>

                                <div className="_inputs">
                                    <div className="reset-password-input-con">
                                        <Field placeholder="Password" className="_input" type={isPassword ? 'text' : 'password'} name="password" />
                                        {isPassword ? <i className="fa-regular fa-eye-slash" onClick={viewPassword} style={{ cursor: 'pointer' }}></i> : <i className="fa-regular fa-eye" onClick={viewPassword} style={{ cursor: 'pointer' }}></i>}
                                    </div>

                                    <ErrorMessage name="password" component={'div'} className="error" />

                                    <div className="reset-password-input-con">
                                        <Field placeholder="Confirm Password" className="_input" type={isconfirmPassword ? 'text' : 'password'} name="confirmPassword" />
                                        {isconfirmPassword ? <i className="fa-regular fa-eye-slash" onClick={viewConfirmPassword} style={{ cursor: 'pointer' }}></i> : <i className="fa-regular fa-eye" onClick={viewConfirmPassword} style={{ cursor: 'pointer' }}></i>}
                                    </div>
                                    <ErrorMessage name="confirmPassword" component={'div'} className="error" />

                                    {/* <Button text='Reset'  /> */}
                                    {/* <button type="submit" disabled={isSubmitting} className='reset-button'>Reset</button> */}
                                    <Button text={<LoaderButton isLoading={isSubmitting} text="Reset" />} disabled={isSubmitting} buttonType={'submit'} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
            <Footer />
        </div>
    );
};

export default ResetPassword;

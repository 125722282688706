import ProgressBar from 'components/progress-bar/ProgressBar';
import React, { useEffect, useRef, useState } from 'react';
import service from 'services';

import api from 'services/api';
import Button from 'components/buttons/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import { useFormContext } from 'ContextApi/FormContext';

const SectionL = () => {
	const [query] = useSearchParams();
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);
	const navigate = useNavigate();
	const { formData, updateFormData } = useFormContext();

	const [professional_role, setYourProfessional_role] = useState(formData.sectionLData.professional_role || '');
	const [error, setError] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const userType = service.getUserType();

	function handleOnChange(e) {
		setYourProfessional_role(e.target.value);
		setError('');
	}

	function handleSubmit() {
		setIsLoading(true);
		if (professional_role === '') {
			setIsLoading(false);
			setError('Please enter your Professional Role');
			return;
		}
		setError('');
		const data = { title: professional_role };

		function successHandler() {
			setIsLoading(false);
			updateFormData('sectionLData', { professional_role });
			const lastPoint = query.get('section')
				localStorage.setItem('lastOnboardingPoint',lastPoint)
			if (userType === service.FREELANCER) {
				navigate({ search: `?section=12` });
			} else {
				navigate({ search: `?section=11` });
			}
		}

		function errorHandler(response) {
			setIsLoading(false);
			console.log(response);
		}
		if (userType === service.FREELANCER) {
			api.freelancers.profile.update(data).then(successHandler).catch(errorHandler);
		} else {
			api.talents.profile.update(data).then(successHandler).catch(errorHandler);
		}
	}
	return (
		<div id="brand-section bg-white" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-2" style={{ padding: 0 }}>
					<div className="header row justify-content-between py-5 align-items-center">
						<h2 className="display col-lg-6">Add a title that the world will know you by</h2>
						<div className="col-lg-5">
							<ProgressBar percent="82" />
						</div>
						<p className="my-3 col-lg-7 text_intro">Clients will see this rate on your profile and in search results once you publish your profile.</p>
					</div>

					<form>
						<div className="row">
							<div className=" col-lg-8  d-lg-flex justify-content-between gap-4">
								<div className="control-form ">
									<div className="d-flex flex-column  ">
										<label>Your Professional Role</label>

										{/* <label className="error-msg mb-3 col-lg-6">{formErrors.talent_bio}</label> */}
									</div>
									<input type="text" placeholder="Eg: Frontend Developer" value={professional_role} name="professional_role" onChange={handleOnChange} />
									<p style={{ color: '#E23636' }}>{error}</p>
								</div>
							</div>
						</div>

						<div className="control-form d-lg-flex gap-3 my-5" style={{ borderTop: '1px solid #ECECEC', padding: '20px 0' }}>
							<div className="mb-5 mb-lg-0">
								<Button
									text={
										<>
											<i className="fa-solid fa-arrow-left"></i>
											<h1>One Step back</h1>
										</>
									}
									primaryNull
									onClick={() => navigate(-1)}
								/>
							</div>

							<div className="mb-4 mb-lg-0">
								<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading || professional_role.length === ''} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SectionL;

import './css/SectionE.style.sass';


function SectionE() {
	return (
		<div id="SectionE_Main_Container">
			<div className="con-section">
				<div className="d-flex flex-column align-items-center justify-content-center">
					<div className="con-title d-flex flex-column align-items-center justify-content-center">
						<h2>Power Partner</h2>
						<p className="mt-4 mb-2">We provide the best services for our partners and ensure that our goals are aligned.</p>
					</div>

					<div className="con-body">
						<ul className="d-flex flex-column flex-lg-row text-center">

							<li>
								<a href='https://tybitx.com/' target='-_blank'>
								<img src="img/tybitx.png" alt="" className="w-50" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionE;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import service from 'services';
import api from 'services/api';

const accessToken = service?.getTokensLocalStorage()?.access?.token;

let socket;
if (accessToken != null) {
  socket = io('https://frontters-admin-dashboard.onrender.com/conversations', {
    auth: { token: accessToken },
  });
}

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  let ACTIVE_ROLE;
  if (accessToken != null) {
    ACTIVE_ROLE = service?.getUserType();
  }

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        let res;
        if (ACTIVE_ROLE === service.RECRUITER) {
          res = await api.recruiter.conversations.getConversations();
        } else if (ACTIVE_ROLE === service.TALENT) {
          res = await api.talents.conversations.getConversations();
        } else {
          res = await api.freelancers.conversations.getConversations();
        }

        const conversations = res.data.conversations;

        // Emit 'join' for each conversation
        conversations.forEach((conversation) => {
          let userId;
          if (ACTIVE_ROLE === service.RECRUITER) {
            userId = conversation.recruiter?._id;
          } else {
            userId = conversation?.talent?._id;
          }

          const data = {
            conversationId: conversation._id,
            userId: userId,
          };

          socket.emit('join', data);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchConversations();
  }, [ACTIVE_ROLE]);

  socket?.on('receive-message', (newMessage) => {
    setNotifications((prev) => [...prev, newMessage]);
  });

  return <NotificationContext.Provider value={{ notifications, socket, setNotifications }}>{children}</NotificationContext.Provider>;
};

export const useNotifications = () => useContext(NotificationContext);
import Button from 'components/buttons/Button';
import { useState } from 'react';
import style from './css/Modal.module.sass';

function Modal({ callback }) {
	const [form, setForm] = useState({
		school: '',
		degree: '',
		start_date: '',
		end_date: '',
	});

	const [formErrors, setFormErrors] = useState(form);

	const [isPresent, setIsPresent] = useState(false);
	// Function to handle the "Present" checkbox click
	function handlePresentCheckbox() {
		setIsPresent(!isPresent);
		if (!isPresent) {
			setForm({ ...form, end_date: 'present' });
		} else {
			setForm({ ...form, end_date: '' });
		}
	}

	function handleOnChange(e) {
		const attribute = e.target.getAttribute('name');
		const value = e.target.value;
		setForm({ ...form, [`${attribute}`]: value });
		setFormErrors({ ...formErrors, [`${attribute}`]: `` });
	}

	function configFields(form) {}

	function hasErrors() {
		for (const field in form) {
			if (!form[field]) {
				validationHandler(field);
				console.warn(`{${field}} field is required`);

				return true;
			} else if (validations()) {
				return true;
			}
		}

		return false;
	}

	function validationHandler(field, errorMsg = 'field is required') {
		const formElement = document.getElementById('con-form').elements;
		formElement[field]?.focus();
		formElement[field]?.scrollIntoView({ block: 'center' });
		setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
	}

	function validations() {
		if (!true) {
			return true;
		}
	}

	function handleOnSubmit() {
		if (!hasErrors()) {
			configFields(form);
			callback(form);
			setForm({ school: '', degree: '', start_date: '', end_date: '' });
		}
	}

	function exitModal() {
		return {
			doDismissModal: true,
		};
	}

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	return (
		<div>
			<form id="con-form">
				<div className={style['con-form']}>
					<div className='d-flex align-items-lg-center flex-lg-nowrap flex-wrap gap-lg-3'>
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>School Name *</label>
							</div>

							<input type="text" placeholder="Enter School Name" name="school" value={form.school} onChange={handleOnChange} className={formErrors.school ? 'error' : ''} />
							<label className="error-msg mb-3">{formErrors.school}</label>
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Degree *</label>
							</div>

							<select name="degree" value={form.degree} onChange={handleOnChange}>
								<option value="">Select Degree</option>
								<option value="master degree">Master's Degree</option>
								<option value="bachelor degree">Bachelor's Degree</option>
								<option value="first degree">First Degree</option>
								<option value="secondary school leaving certificate">Secondary School Leaving Certificate</option>
							</select>
							<label className="error-msg mb-3">{formErrors.degree}</label>
						</div>
					</div>

					<div className={`control-form ${style['con-date']}`}>
						<div className="d-flex flex-column flex-lg-row justify-content-start gap-3">
							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>Start Date*</label>
									<label className="error-msg mb-3">{formErrors.start_date}</label>
								</div>

								<input type="date" name="start_date" value={form.start_date} onChange={handleOnChange} className={formErrors.start_date ? 'error' : ''} max={getCurrentDate()} />
							</div>

							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>End Date*</label>
									<label className="error-msg mb-3">{formErrors.end_date}</label>
								</div>

								<input type="date" name="end_date" value={form.end_date} onChange={handleOnChange} className={formErrors.end_date ? 'error' : ''} disabled={isPresent} min={form.start_date} />
							</div>
						</div>
						<div className="d-flex gap-1 align-items-baseline">
							<input type="checkbox" checked={isPresent} onChange={handlePresentCheckbox} style={{ width: '30px' }} />
							<label className="fw-bold">Presently schooling here</label>
						</div>
					</div>

					<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
						<div data-bs-dismiss="modal">{<Button text={<h2>Back</h2>} primaryNull />}</div>

						{!form.school || !form.degree || !form.start_date || !form.end_date ? (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								onClick={handleOnSubmit}
							/>
						) : (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								{...exitModal()}
								onClick={handleOnSubmit}
							/>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}

export default Modal;

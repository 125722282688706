import Button from 'components/buttons/Button';
import JobCard from 'components/cards/job-card/JobCard';
import { handleModal as handlePreviewModal } from 'components/cards/job-card/modal/Modal';
import { handleModal } from 'components/cards/job-card/modal/PromptModal';
import PreviewModal from 'components/modals/Modal';
import PromptModal from 'components/modals/Modal';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import classNames from 'utils/conditional-css-classes/classNames';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import './css/Recruiter.style.sass';
import { navbarComp } from './navbar-components/navbar-comp';

function Recruiter() {
	service.setPageTitle('Recruiter Dashboard');

	const [isLoading, setIsLoading] = useState(true);
	const [draftedJobs, setDraftedJobs] = useState([]);
	const [publishedJobs, setPublishedJobs] = useState([]);
	const [archivedJobs, setArchivedJobs] = useState([]);
	const [profile, setProfile] = useState({});
	const [isActive, setIsActive] = useState(0);
	const [jobStats, setJobStats] = useState({});
	const [search, setSearch] = useState({ full_time_decision: 'full-time', search: '' });
	const [loadingImage, setLoadingImage] = useState(false);
	const itemsPerPage = 6;
	const [currentPage, setCurrentPage] = useState(1);
	const [spaces, setSpaces] = useState({});

	function fetchJobs() {
		function successHandler(response) {
			const result = response?.data?.result;
			setDraftedJobs(result.filter((job) => job?.status === 'drafted'));
			setPublishedJobs(result.filter((job) => job?.status === 'published'));
			setArchivedJobs(result.filter((job) => job?.status === 'archive'));
		}

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter.getMyJobs().then(successHandler).catch(errorHandler).finally(onFinal);
	}

	function getProfile() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response?.data?.result;
			setProfile(result);
		}

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter.profile.get().then(successHandler).catch(errorHandler).finally(onFinal);
	}

	function fetchJobStats() {
		function successHandler(response) {
			const result = response?.data?.result;
			setJobStats(result);
		}

		function errorHandler(response) {
			console.error(response);
		}

		api.recruiter.jobsStats().then(successHandler).catch(errorHandler);
	}

	function doPublish(jobId) {
		setIsLoading(true);
		function successHandler(response) {
			fetchJobs();
		}

		function errorHandler(response) {
			console.error(response);
		}

		api.recruiter.updateJob(jobId, { status: 'published' }).then(successHandler).catch(errorHandler);
	}

	function getAdverts() {
		function successHandler(response) {

			const recruiterRooms = response?.data.filter(room => room.name === "recruiter");

			recruiterRooms.forEach(room => {
				api.adverts.getSpaces(room._id)
					.then(spacesResponse => {
						const spacesData = spacesResponse.data;

						// Transform the array into an object keyed by space name
						const spacesByName = spacesData.reduce((acc, item) => {
							acc[item.name] = {
								_id: item._id,
								room: item.room,
								desktop: item?.imagePlaceholders?.desktop,
								mobile: item?.imagePlaceholders?.mobile,
								tablet: item?.imagePlaceholders?.tablet
							};
							return acc;
						}, {});

						// Save the transformed data into the state
						setSpaces(spacesByName);
					})
					.catch(error => {
						console.error(`Error fetching spaces for recruiter room ${room._id}:`, error);
					});
			});
		}

		function errorHandler(error) {
			console.error("Error fetching rooms:", error);
		}
		api.adverts.getRooms().then(successHandler).catch(errorHandler);
	}

	useEffect(fetchJobs, []);
	useEffect(getProfile, []);
	useEffect(fetchJobStats, []);
	useEffect(getAdverts, []);

	const navigate = useNavigate();

	function createJobHandler() {
		navigate('/dashboard/jobs/create');
	}

	const handleOnChange = (e) => {
		const attribute = e.target.getAttribute('name');
		const value = e.target.value;
		setSearch({ ...search, [`${attribute}`]: value });
	};

	const hasErrors = () => {
		for (const field in search) {
			if (!search[field]) {
				return true;
			}
		}
		return false;
	};

	const handleSubmit = (e) => {
		e?.preventDefault();
		if (!hasErrors()) {
			navigate({ pathname: '/dashboard/search', search: `type=${search.full_time_decision}&search=${search.search}&callback=find-talent` });
		}
	};

	function handleFileChange({ target: { files } }) {
		if (files) {
			setLoadingImage(true);
			doUpload(files[0]);
		}
	}
	function doUpload(file) {
		const formData = new FormData();
		formData.append('file', file);

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setLoadingImage(false);
		}

		api.recruiter.profile.uploadImage(formData).then(getProfile).catch(errorHandler).finally(onFinal);
	}
	// trying to customize the pagination

	const getPageCount = (jobs) => Math.ceil(jobs.length / itemsPerPage);
	// Handle page change
	const onPageChange = (newPage) => {
		const activeJobs = isActive === 0 ? publishedJobs : archivedJobs;
		const pageCount = getPageCount(activeJobs);

		if (newPage >= 1 && newPage <= pageCount) {
			setCurrentPage(newPage);
		}
	};

	const renderJobs = (jobs, isLoading) => {
		const startIdx = (currentPage - 1) * itemsPerPage;
		const paginatedJobs = jobs.slice(startIdx, startIdx + itemsPerPage);

		return (
			<div className="context d-flex justify-content-center align-items-center flex-wrap" style={{ minHeight: '300px' }}>
				{!isLoading &&
					(paginatedJobs.length > 0 ? (
						paginatedJobs.map((x, y) => (
							<div key={y} style={{ flex: '1 1 calc(33.33% - 20px)', margin: '10px' }}>
								<JobCard data={x} />
							</div>
						))
					) : (
						<div className="d-flex flex-column justify-content-center align-items-center text-center">
							<img src="img/svg/nojob.svg" alt="oops" className="img-fluid" style={{ width: '70px' }} />
							<h6 className="my-1">No Jobs</h6>
							<p>You currently have no Job created</p>
						</div>
					))}
				{isLoading && [1, 1, 1].map((x, y) => <div className="animated-bg" key={y}></div>)}
			</div>
		);
	};

	const renderPagination = (jobs) => {
		const pageCount = getPageCount(jobs);

		return (
			jobs.length > itemsPerPage && (
				<div className="footer d-flex justify-content-center">
					<IndexPagination pageCount={pageCount} currentPage={currentPage} onPageChange={onPageChange} />
				</div>
			)
		);
	};

	return (
		<div id="Recruiter_Dashboard_Main_Container">
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="container-01">
					<div className="con-section">
						<div className="section section-a">
							<div className="row">
								<div className="first-col col-12 col-lg-4 mb-5 mb-lg-0">
									<div className="outline-wrap">
										<div className="d-lg-flex align-items-center justify-content-between">
											<div className="title mb-3 mb-lg-0">
												<h2>Overview</h2>
												<p>Based on your profile</p>
											</div>

											<div>
												<Button small text={' Add New Job'} onClick={createJobHandler} />
											</div>
										</div>

										<div className="context d-flex align-items-center gap-4">
											<div className="left">
												<h1>{jobStats?.publishedCount ? jobStats?.publishedCount : 0}</h1>
												<Link to="/dashboard/jobs/posted-jobs">
													<h2>Job Posted</h2>
												</Link>
											</div>
											<div className="cutter"></div>
											<div className="right">
												<h1>{jobStats?.applicants ? jobStats?.applicants : 0}</h1>
												<Link to="/dashboard/jobs/applied">
													<h2>Application made</h2>
												</Link>
											</div>
										</div>
									</div>
								</div>

								<div className="second-col col-12 col-lg-8">
									<div className="profile-section outline-wrap">
										<div className="row">
											<div className="con-right col-12 col-lg-7">
												{!isLoading && profile && (
													<div className="right-a pb-4 pb-lg-0">
														<div className="d-flex flex-column flex-lg-row align-items-center gap-3">
															<div className="con-img">
																<label className="upload">
																	<div className="img">
																		<img src="/img/Frame 39812.png" alt="" />
																	</div>
																	<input type="file" accept={`image/png,image/jpeg`} onChange={handleFileChange} />
																</label>

																<div className="user-img">
																	{!loadingImage && <img src={profile?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="" />}

																	{loadingImage && <div className={'animated-bg'}></div>}
																</div>
															</div>

															<div className="data pe-4">
																<div style={{ display: 'flex', gap: '5px' }}>
																	<h2>
																		{profile?.first_name && service.titleCase(profile?.first_name)} {profile?.last_name && service.titleCase(profile?.last_name)}
																	</h2>
																	<img src={profile?.isProfileApproved ? '/img/svg/verified.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
																</div>
																<h3 title={profile?.email}>{service.truncateText(profile?.email, 20)}</h3>
																<p>+234{profile?.phone_number}</p>
															</div>
														</div>
													</div>
												)}

												{isLoading && <div className={'animated-bg'}></div>}
											</div>

											<div className="con-left col-12 col-lg-5">
												{!isLoading && (
													<div className="left d-flex align-items-start justify-content-between">
														<div className="context">
															<p>Company Name</p>
															<h2 title={profile?.company_name}>{profile?.company_name && service.titleCase(service.truncateText(profile?.company_name, 20))}</h2>

															<p>Position in Company</p>
															<h2 title={profile?.recruiter_position}>{profile?.recruiter_position && service.titleCase(service.truncateText(profile?.recruiter_position, 20))}</h2>
															<p>Staff Capacity</p>
															<h2>{profile?.staff_capacity}</h2>
														</div>

														<Link to={'/dashboard/profile'} className="cta">
															Edit
														</Link>
													</div>
												)}

												{isLoading && <div className={'animated-bg'}></div>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<form className="section-b" onSubmit={handleSubmit}>
							<div className="con-select">
								<select name="full_time_decision" onChange={handleOnChange}>
									<option value="full-time">Search for Job Seeker</option>
									<option value="freelancer">Search for Freelancer</option>
								</select>
							</div>
							<div className="con-input my-3 my-lg-0">
								<input type="text" placeholder="Search for talents by job title... eg. Designer" name="search" onChange={handleOnChange} />
							</div>
							<button className="con-submit d-flex d-lg-block" onClick={handleSubmit}>
								<div className="arrow-btn">
									<i className="fa-solid fa-arrow-right"></i>
								</div>
							</button>
						</form>

						{spaces['Top right']?.desktop?.url !== null &&<div href="/" className="small_advert">
							<img src={spaces['Top right']?.desktop?.url} alt="" />
						</div>}

						<div className="section section-c">
							<div className="outline-wrap">
								<div className="title d-flex align-items-center justify-content-between mb-4">
									<h2>Drafts</h2>

									{draftedJobs && draftedJobs.length > 10 && (
										<div className="link">
											<Link to="/dashboard/jobs/drafts">See More</Link>
										</div>
									)}
								</div>
								{!isLoading && draftedJobs.length < 1 && (
									<div className="d-flex flex-column justify-content-center align-items-center text-center m-auto" style={{ minHeight: '300px' }}>
										<img src="img/svg/nojob.svg" alt="oops" className="img-fluid" style={{ width: '70px' }} />
										<h6 className="my-1">No Draft</h6>
										<p>You currently have no drafts</p>
									</div>
								)}
								<div className="context">
									{!isLoading &&
										draftedJobs &&
										draftedJobs.length > 0 &&
										draftedJobs.map((job, idx) => {
											return (
												<div key={idx}>
													<div style={{ flex: '1 1 calc(33.33% - 20px)', margin: '10px' }}>
														<JobCard data={job} tag={idx} preview />
													</div>
													<PreviewModal {...handlePreviewModal({ ...job, tag: idx, callback: () => doPublish(job._id) })} />
												</div>
											);
										})}
									{isLoading && [1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}
								</div>
							</div>
						</div>

						{spaces['Top Left']?.desktop?.url !== null &&<div className="bg_advert" style={{ marginBottom: '30px' }}>
							<img src={spaces['Top Left']?.desktop?.url} alt="" />
						</div>}

						<div className="section section-d">
							<div className="outline-wrap">
								<div className="title mb-4">
									<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-4">
										<h2 className={classNames(isActive === 0 && 'active')} onClick={() => setIsActive(0)}>
											Posted Opportunity
										</h2>
										<h2 className={classNames(isActive === 1 && 'active')} onClick={() => setIsActive(1)}>
											Archived
										</h2>
									</div>
								</div>

								{isActive === 0 && renderJobs(publishedJobs, isLoading)}
								{isActive === 1 && renderJobs(archivedJobs, isLoading)}

								{isActive === 0 && renderPagination(publishedJobs)}
								{isActive === 1 && renderPagination(archivedJobs)}
							</div>
						</div>

						{spaces['Bottom right']?.tablet?.url !== null &&<div className="bg_advert">
							<img src={spaces['Bottom right']?.tablet?.url} alt="" />
						</div>}
						{spaces['Bottom Left']?.mobile?.url !== null &&<div href="/" className="small_advert">
							<img src={spaces['Bottom Left']?.mobile?.url} alt="" />
						</div>}
					</div>
				</div>
			</div>

			<PromptModal {...handleModal({})} />
		</div>
	);
}

export default Recruiter;

import React from 'react';
import './css/SectionF.css';
import Button from 'components/buttons/Button';
import SendUsMailModal from './SendUsMailModal';
import Modal from 'components/modals/Modal';

const SectionF = () => {
	function sendUsMail() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `advert-form-action-modal`,
			modalTitle: 'Advert Form',
			modalBody: <SendUsMailModal />,
		};
	}

	return (
		<div id="SectionF_Main_Container">
			<div className="con-section d-flex justify-content-center h-100">
				<div className="d-flex flex-column align-items-center justify-content-center">
					<div className="con-title d-flex flex-column align-items-center justify-content-center">
						<h2>Boost Your Initiative with Us!</h2>
						<p className="py-2 col-lg-7">Let us handle the advertising while you focus on creating meaningful impact. Together, we’ll reach a wider audience and make a difference.</p>
					</div>
					<div className="pt-3">
						<Button text={'Send us a mail'} {...sendUsMail()} />
					</div>
				</div>
			</div>
			<Modal modalWidth={'750px'} {...sendUsMail()} />
		</div>
	);
};

export default SectionF;

import React, { useState, useRef, useEffect } from 'react';
import './css/FileUploadComponent.style.sass';

export function ImageUploadComponent({ onUpload, fetchedImages, handleRemoveImage }) {
	const [images, setImages] = useState([]);

	const fileInputRef = useRef(null);

	useEffect(() => {
		setImages([...fetchedImages]);
	}, [fetchedImages]);

	const handleFileSelection = () => {
		fileInputRef.current.click();
	};

	const handleImageUpload = (event) => {
		const fileList = event.target.files;
		if (fileList) {
			const imageList = Array.from(fileList);
			const uniqueImages = imageList.filter((image) => 
				!images.some((existingImage) => existingImage.name === image.name)
			);
			if (uniqueImages.length > 0) {
				setImages((prevImages) => [...prevImages, ...uniqueImages]);
				onUpload(uniqueImages); // Notify parent with unique images
			}
		}
	};

	useEffect(() => {
		// Clean up URLs when the component unmounts or images change
		return () => {
			images.forEach((image) => {
				if (image instanceof File) {
					URL.revokeObjectURL(image);
				}
			});
		};
	}, [images]);

	const allImages = [...images].filter((image) => !!image && typeof image !== 'string');

	return (
		<div id="Image_Component">
			<div className="d-flex flex-column gap-1 mb-3">
				<p>Upload Image files</p>
				<p style={{ fontSize: '14px', color: 'darkred' }}>(Only jpegs, png images are allowed)</p>
			</div>
			{allImages.length > 0 && (
				<div className="d-flex gap-3 overflow-auto mt-2 mb-3 imagescroll">
					{allImages.map((image, index) => (
						<div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '400px' }}>
							<img src={image instanceof File ? URL.createObjectURL(image) : image.href_link} alt={`${index + 1}`} style={{ width: '100%', height: '250px' }} className="img" />
							<div className="d-flex gap-2 mt-2">
								<button className="btn-sm" onClick={() => handleRemoveImage(image._id)}>
									<img src={'/img/svg/delete2.svg'} alt="Delete" style={{ width: '25px', height: '25px' }} />
								</button>
							</div>
						</div>
					))}
				</div>
			)}
			<input type="file" accept="image/png, image/jpeg, image/jpg" multiple onChange={handleImageUpload} style={{ marginBottom: '1rem', display: 'none' }} ref={fileInputRef} />
			<button className="btn-solid my-2" style={{ fontWeight: 'bold' }} onClick={handleFileSelection}>
				Choose images
			</button>
		</div>
	);
}


export function TextUploadComponent({ onUpload, uploadedText }) {
	const [text, setText] = useState(uploadedText);
	const [isEditing, setIsEditing] = useState(uploadedText);

	const handleTextChange = (event) => {
		setText(event.target.value);
	};

	const handleSave = () => {
		onUpload(text);
		setIsEditing(false);
	};

	const handleEdit = () => {
		setIsEditing(true);
		setText(uploadedText);
	};

	const handleDelete = () => {
		setText('');
		setIsEditing(true);
	};

	return (
		<div id="Text_Component">
			{isEditing ? (
				<div className="normal">
					<h3>Start Editing!</h3>
					<textarea value={text} onChange={handleTextChange} placeholder="What do you want to write about" className="w-100" rows={9} />
					<div className="d-flex gap-4 mt-3">
						<button className="btn-transparent" onClick={handleDelete}>
							<img src="/img/svg/cancel.svg" alt="" style={{ width: '25px' }} /> Cancel
						</button>
						<button className="btn-solid" onClick={handleSave}>
							<img src="/img/svg/save.svg" alt="" style={{ width: '25px' }} /> Save
						</button>
					</div>
				</div>
			) : (
				<div className="editing">
					<h3>Edited Text</h3>
					<div style={{ width: '100%' }}>
						<p className="text-justify w-100">{uploadedText}</p>
					</div>
					<div className="d-flex gap-4 mt-3">
						<button className="btn-transparent" onClick={handleDelete}>
							<img src="/img/svg/delete.svg" alt="" style={{ width: '25px' }} /> Delete
						</button>
						<button className="btn-solid" onClick={handleEdit}>
							<img src="/img/svg/edit.svg" alt="" style={{ width: '25px' }} /> Edit
						</button>
					</div>
				</div>
			)}
		</div>
	);
}

export function VideoUploadComponent({ onUpload, fetchedVideos, handleRemoveVideo }) {
	const [videos, setVideos] = useState([]);

	useEffect(()=>{
		setVideos([...fetchedVideos])
	},[fetchedVideos])

	const fileInputRef = useRef(null);

	const handleFileSelection = () => {
		fileInputRef.current.click();
	};

	const handleVideoUpload = (event) => {
		const fileList = event.target.files;
		if (fileList) {
			const videoList = Array.from(fileList);
			setVideos((prevVideos) => [...prevVideos, ...videoList]);
			onUpload(videoList);
		}
	};

	const handleEditVideo = (index) => {
		const fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.accept = 'video/mp4';
		fileInput.addEventListener('change', (event) => {
			const newVideoFile = event.target.files[0];
			if (newVideoFile) {
				const updatedVideos = [...videos];
				updatedVideos[index] = newVideoFile;
				setVideos(updatedVideos);
			}
		});
		fileInput.click();
	};


	useEffect(() => {
		return () => {
			videos.forEach((video) => {
				if (video instanceof File) {
					URL.revokeObjectURL(video);
				}
			});
		};
	}, [videos]);

	const allVideos = [...videos].filter((video) => !!video && typeof video !== 'string');

	return (
		<div id="Video_Component">
			<div className="d-flex flex-column gap-1 mb-3">
				<p>Upload Video files</p>
				<p style={{ fontSize: '14px', color: 'darkred' }}>(Only 2 mp4 files are allowed)</p>
			</div>
			{allVideos.length > 0 && (
				<div>
					<div className="d-flex gap-3 overflow-auto mt-2 mb-3 imagescroll">
						{allVideos.map((video, index) => (
							<div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', maxWidth: '400px' }}>
								<video controls style={{ width: '100%', height: '250px' }} className="video">
									<source src={video instanceof File ? URL.createObjectURL(video) : video.href_link} type="video/mp4" />
								</video>
								<div className="d-flex gap-2 mt-2">
									<button className="" onClick={() => handleEditVideo(index)}>
										<img src={'/img/svg/edit2.svg'} alt="Edit" style={{ width: '25px', height: '25px' }} />
									</button>
									<button className="btn-sm" onClick={() => handleRemoveVideo(video._id)}>
										<img src={'/img/svg/delete2.svg'} alt="Edit" style={{ width: '25px', height: '25px' }} />
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			<input type="file" accept="video/mp4" ref={fileInputRef} multiple onChange={handleVideoUpload} style={{ marginBottom: '1rem', display: 'none' }} />
			<button className="btn-solid my-2" style={{ fontWeight: 'bold' }} onClick={handleFileSelection}>
				Choose videos
			</button>
		</div>
	);
}


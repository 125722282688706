import Button from 'components/buttons/Button';
import { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import service from 'services';

import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import './css/Homepage.style.sass';
import Ribbons from './ribbons/Ribbons';
import SectionA from './section-a/SectionA';
import SectionB from './section-b/SectionB';
import SectionC from './section-c/SectionC';
import SectionD from './section-d/SectionD';
import SectionE from './section-e/SectionE';
import SectionF from './section-f/SectionF';

function Homepage() {
	service.setPageTitle('Discover Talents');
	const navigate = useNavigate();

	const ref = useRef(undefined);

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	return (
		<div id="Homepage_Main_Container" ref={top}>
			<Navbar
				bgColor={'white'}
				centerContent={
					<ul className="d-flex align-items-center justify-content-between gap-4">
						<li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
							Discover Talents
						</li>
						<li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
							Discover Opportunities
						</li>
						<li>
							<Link to="/aboutus">About {service.brand.name}</Link>
						</li>
					</ul>
				}
				sidebarContent={
					<>
						<ul id="con-navbar-sidebar">
							<Link to="">
								<li>Discover Talents</li>
							</Link>
							<Link to="">
								<li>Discover Talents</li>
							</Link>
							<Link to="/aboutus">
								<li>About {service.brand.name}s</li>
							</Link>
							<Link to="/register" className="active">
								<li>Register</li>
							</Link>
							<Link to="/login" className="active">
								<li>Login</li>
							</Link>
						</ul>
					</>
				}
				rightContent={
					<div className="d-flex align-items-center justify-content-between">
						<Button small primaryEmptyFill text="Login" redirect="login" />
						<Button small text="Sign up" redirect="/register" />
					</div>
				}
			/>

			<SectionA callback={() => service.goTo(ref)} />
			<Ribbons positionRef={ref} />

			<SectionB />
			<SectionC />
			<SectionD
				content={
					<>
						<h2>We help build</h2>
						<h1>careers</h1>
						<p>By connecting the right talent to the right opportunities globally.</p>
					</>
				}
				backgroundColor={'#FFFBF2'}
			/>
			<SectionE />
			<SectionF />

			<Footer />
		</div>
	);
}

export default Homepage;

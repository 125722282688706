import { Link, useLocation } from 'react-router-dom';
import style from './css/ProjectCard.module.sass';

function ProjectCard({ width, title, cover, handleDelete, id }) {
	const location = useLocation();
	const isTalentsProfileRoute = location.pathname.includes('/dashboard/talents-profile');
	return (
		<div className={style.container}>
			<div className={`${style.header} d-flex align-items-center justify-content-between`}>
				<h2>{title}</h2>

				{!isTalentsProfileRoute && (
					<div className="d-flex align-items-center gap-3">
						<Link to={`/dashboard/projects/edit/${id}`}>
							<i className="fa-regular fa-pen-to-square"></i>
						</Link>
						<i className="fa-regular fa-trash-can" onClick={handleDelete}></i>
					</div>
				)}
			</div>

			<div className={style.body}>
				<div className={style.img} style={{ width: width || `260px` }}>
					<img src={cover} alt={title} />
				</div>

				<Link to={`/dashboard/projects/${id}`}>
					<div className={`${style['arrow-btn']} outline`}>
						<i className="fa-solid fa-arrow-right"></i>
					</div>
				</Link>
			</div>
		</div>
	);
}

export default ProjectCard;

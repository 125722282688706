import { Link } from 'react-router-dom';
import style from './css/MessageForm.module.sass';
import { useState } from 'react';

function MessageForm({ message }) {
	const [active, setActive] = useState(true);

	return (
		<>
			{active && (
				<div className={style.container}>
					<div className={`${style.msg} d-flex align-items-center justify-content-between`}>
						{typeof message === 'string' ? (
							<h2>{message}</h2>
						) : (
							<div className="d-flex align-items-center gap-4">
								<h2>{message?.error}</h2>
								<Link to={message?.url?.link}>{message?.url?.text}</Link>
							</div>
						)}
						<i className="fa-solid fa-xmark" onClick={() => setActive(false)}></i>
					</div>
				</div>
			)}
		</>
	);
}

export default MessageForm;

import { useEffect, useRef } from 'react';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import './css/Dashboard.style.sass';
import './css/Dashboard.css'
import Freelancer from './freelancer/Freelancer';
import Recruiter from './recruiter/Recruiter';
import Talent from './talent/Talent';

function Dashboard() {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const ACTIVE_ROLE = service.getUserType();

	return (
		<div id="Dashboard_Main_Container" ref={top}>
			{ACTIVE_ROLE === service.RECRUITER && <Recruiter />}

			{ACTIVE_ROLE === service.TALENT && <Talent />}

			{ACTIVE_ROLE === service.FREELANCER && <Freelancer />}

			<Footer />
		</div>
	);
}

export default Dashboard;

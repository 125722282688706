import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer.jsx';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import './css/JobEdit.style.sass';
import SectionA from './section-a/SectionA';
import SectionB from './section-b/SectionB';
import SectionC from './section-c/SectionC';

function JobEdit() {
	service.setPageTitle('Edit Job');
	const { jobId } = useParams();

	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [jobDetails, setJobDetails] = useState({});

	const [postBody, setPostBody] = useState({
		title: '',
		description: '',
		company_name: '',
		company_link: '',
		preferred_gender: 'undefined',
		required_skills: [{ name: '', competency: '', experience_years: '' }],
	});

	const prev = () => setCount(0);

	const doHandleA = (postBody) => {
		setIsLoading(true);

		function successHandler(response) {
			setIsLoading(false);
			const result = response?.data?.result;
			setJobDetails(result);
			setCount(1);
		}

		function errorHandler(response) {
			setIsLoading(false);
			console.error(response);
		}

		api.recruiter.updateJob(jobId, { title: postBody }).then(successHandler).catch(errorHandler);
	};

	const doHandleB = (postBody) => {
		setIsLoading(true);

		function successHandler(response) {
			const result = response?.data?.result;
			setJobDetails(result);
			setCount(2);
		}

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter.updateJob(jobId, postBody).then(successHandler).catch(errorHandler).finally(onFinal);
	};

	function getCompanyProfile() {
		function successHandler(response) {
			const { company_name, company_website } = response?.data?.result;
			setPostBody({ ...postBody, company_name, company_link: company_website });
		}

		function errorHandler(response) {
			console.error(response);
		}

		api.recruiter.profile.get().then(successHandler).catch(errorHandler);
	}

	function getJob() {
		function successHandler(response) {
			const result = response?.data?.result;
			setJobDetails(result);
		}

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter.getJob(jobId).then(successHandler).catch(errorHandler).finally(onFinal);
	}

	useEffect(getJob, [jobId]);
	// eslint-disable-next-line
	useEffect(getCompanyProfile, []);

	return (
		<div>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				{count === 0 && <SectionA callback={doHandleA} context={jobDetails} isLoading={isLoading} />}
				{count === 1 && <SectionB callback={doHandleB} prev={prev} context={jobDetails} isLoading={isLoading} />}
				{count === 2 && <SectionC postBody={postBody} context={jobDetails} />}
			</div>

			<Footer />
		</div>
	);
}

export default JobEdit;

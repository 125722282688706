import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import service from 'services';
// import api from 'services/api';
import './css/SectionC.style.sass';
import './css/horizontalcard.style.scss'

function SectionD({ hire, picked, job, onSubmit }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handleHire = () => {
		setIsLoading(true)
		onSubmit()
	}


	return (
		<div id="Onboarding_Talent_SectionC_Main_Component" ref={top} style={{ backgroundColor: '#FFFBF2', paddingBottom: '30px' }}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<div className="mb-3 d-flex align-items-center gap-1" style={{ color: '#CC5600', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate(-1)}>
								<i class="fa-solid fa-chevron-left"></i>
								<p>Back</p>
							</div>

							<h5>Welcome to Recruitment Wizard</h5>

							<div className='d-flex' style={{ gap: '20px' }}>
								<h1 className="mb-3 display">Let's get your Talent onboard!</h1>
								<img src="/img/svg/rocket.svg" alt="rocket" style={{ width: '60px' }} />
							</div>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="100" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01" style={{ backgroundColor: 'white', padding: '15px', borderRadius: '15px' }}>
				<div className="con-form">
					<div className="form" style={{ backgroundColor: 'white' }}>
						<form>

							<div className='d-lg-flex justify-content-between p-2'>
								<article>
									<h4>Checkout</h4>
									<p>Ensure you review Criteria before selecting candidates</p>
								</article>
								<Button text={'Review Criteria'} secondaryNull onClick={() => navigate(`/dashboard/jobs/criteria/${job?._id}`)} />
							</div>

							<div className="con-context">
								<h5 className="title">Candidate(s) ({picked.length})</h5>

								<div className='cards-d'>
									{
										picked.map((result, idx) => {
											return (
												<Link to={`/dashboard/talents-profile/${result?.id}`} key={idx} >
													<div className={'card'} style={{ height: '170px' }}>
														<div className={'header'}>
															<p>{`CAND${idx + 1}`}</p>
															<i className="fa-regular fa-user"></i>
														</div>
														<div className={'Cardbody'}>
															<article style={{ display: 'flex', flexDirection: 'ROW' }}>
																<div className={'img'} style={{ width: '100px', height: '100px' }}>
																	<img src={result.talent?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="head shot" style={{ objectFit: 'cover' }} />
																</div>
																<div className={'text'}>
																	<div style={{ display: 'flex', gap: '5px' }}>
																		<h1 className='truncate-e' style={{ fontSize: '16px' }}>
																			{result?.applicant_first_name} {" "} {result?.applicant_last_name}
																		</h1>
																		<img src={result?.isProfileApproved ? "/img/svg/verified.svg" : '/img/svg/pending.svg'} alt="" style={{ width: '15px' }} />
																	</div>
																	<h3 className="my-2 text-truncate">{result?.title}</h3>
																	<p className="d-flex align-items-center gap-2 truncate-e">
																		<i className="fa-solid fa-location-dot"></i>
																		{result.talent?.location?.resident_state || `Lagos`}, {result.talent?.location?.resident_country || `Nigeria`}
																	</p>
																</div>
															</article>
															<div className='vertical-border'></div>
															<article style={{ display: 'flex', flexDirection: 'column', gap: '10px', fontSize: '13px', fontWeight: 'bold' }}>
																<div className="d-flex align-items-center gap-2" >
																	<i class="fa-solid fa-user-tie"></i>
																	<p >Talent type:  {hire.talentType}</p>
																</div>
																<div className="d-flex align-items-center gap-2">
																	<i class="fa-solid fa-user-tie"></i>
																	<p >Duration: {hire.duration}</p>
																</div>
															</article>
															<div className='vertical-border'></div>
															<div className="">
																<div className="d-flex align-items-center gap-1" style={{ fontSize: '13px', fontWeight: 'bold' }}>
																	<i class="fa-solid fa-dollar-sign"></i>
																	<p>Expected Pay</p>
																</div>
																<div className="d-flex align-items-center gap-1 mt-3" style={{ color: '#CC5600' }}>
																	<p>{hire.salary}</p>
																</div>
															</div>
														</div>
													</div>
												</Link>
											);
										})}
								</div>
							</div>

							<div className='my-2' style={{ border: '1px solid #2E2E30' }}></div>

							<div className="">
								<h4 className='mb-3'>Amount to be paid</h4>
								<div className="control-form">
									<article className='d-flex justify-content-between my-2'>
										<p>Interview Cost:</p>
										<p>$0</p>
									</article>
									<article className='d-flex justify-content-between my-2'>
										<p>IQ Test Cost:</p>
										<p>$0</p>
									</article>
									<article className='d-flex justify-content-between my-2'>
										<p>Tax:</p>
										<p>$0</p>
									</article>
								</div>
							</div>

							<div className='my-4' style={{ border: '1px solid #2E2E30' }}></div>

							<div className="d-lg-flex justify-content-between gap-2">
								<div className="control-form">
									<div className="d-flex align-items-center gap-2">
										<p style={{ fontSize: '16px', fontWeight: 'bold' }}>Total</p>
									</div>
									<div className="d-flex align-items-center gap-1 mt-3" style={{ color: '#CC5600' }}>
										<h2>{job?.currency} {" "}{(hire.salary) * (picked?.length)}</h2>
									</div>
								</div>
							</div>

							<div className='my-4' style={{ border: '1px solid #2E2E30' }}></div>

							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3">
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<h1>Review Criteria</h1>
											</>
										}
										secondaryNull
										onClick={() => navigate(`/dashboard/jobs/criteria/${job?._id}`)}
									/>
								</div>
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>Back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text={<><p style={{ fontSize: '14px' }}>Make Payment</p></>} />} onClick={handleHire} disabled={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionD;

import Button from 'components/buttons/Button';

function DeleteJobModal({ callback }) {
	return (
		<div className={`d-flex flex-column align-items-center gap-4 p-4`}>
			<h2 className="title" style={{ fontWeight: 700, fontSize: '2rem' }}>
				Warning
			</h2>
			<div className="img">
				<img src="/img/Group 282.png" alt="" />
			</div>
			<div className="text-center">
				<p>
					You are about to <b>delete job opportunity</b> .
				</p>
				<p style={{ color: 'red', fontWeight: '600' }}>You can not reverse this action</p>
			</div>
			<div className="footer d-flex flex-column flex-lg-row align-items-center gap-3">
				<div data-bs-dismiss={`modal`}>
					<Button text="Cancel" primaryNull />
				</div>

				<div data-bs-dismiss={`modal`}>
					<Button text="Delete" onClick={callback} />
				</div>
			</div>
		</div>
	);
}

export default DeleteJobModal;

import s from './css/Modal.module.sass';

function Modal({ modalTarget, isStatic, modalTitle, modalBody, modalWidth }) {
	function handleDismiss() {
        const modalElement = document.getElementById(modalTarget);
        if (modalElement) {
            modalElement.classList.remove('show');
            modalElement.style.display = 'none';
        }
    }


	return (
		<div className={s.con_modal}>
			<div id={modalTarget} aria-hidden="true" className={`${s.modal} modal fade`} data-bs-backdrop={isStatic && `static`}>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: modalWidth || `500px` }}>
					<div className={`${s.modal_content} modal-content`}>
						<div className={`${s.header} modal-header`}>
							{/* <i className="fa-regular fa-user"></i> */}
							<h5 className={`modal-title`}>{modalTitle || `modal title`}</h5>
							<i className="fa-solid fa-xmark" data-bs-dismiss="modal" onClick={handleDismiss}></i>
						</div>

						<div className={`${s.body} modal-body`}>{modalBody || `modal context`}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Modal;

import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Button from 'components/buttons/Button';
import service from 'services';
import './resetsuccesspage.css'


const ResetSuccessPage = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { email } = location.state

    return (


        <div >
            <Navbar
                bgColor={'white'}
                centerContent={
                    <ul className="d-flex align-items-center justify-content-between gap-4">
                        <li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
                            Discover Talents
                        </li>
                        <li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
                            Discover Opportunities
                        </li>
                        <li>
                            <Link to="/aboutus">About {service.brand.name}</Link>
                        </li>
                    </ul>
                }
                sidebarContent={
                    <>
                        <ul id="con-navbar-sidebar">
                            <li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
                                Discover Talents
                            </li>
                            <li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
                                Discover Opportunities
                            </li>
                            <Link to="">
                                <li>About {service.brand.name}s</li>
                            </Link>
                            <Link to="/register" className="active">
                                <li>Register</li>
                            </Link>
                            <Link to="/login" className="active">
                                <li>Login</li>
                            </Link>
                        </ul>
                    </>
                }
                rightContent={
                    <div className="d-flex align-items-center justify-content-between">
                        <Button small text="Sign up" redirect="/register" />
                        <Button small primaryEmptyFill text="Login" redirect="/login" />
                    </div>
                }
            />
            <div className="container d-flex justify-content-center align-items-center my-3" style={{ height: '100vh' }} >
                <div style={{ position: 'relative' }}>
                    <div className="logo_symbol">
                        <div className='e_img-con'>
                            <img src="/favicon.ico.svg" alt="" />
                        </div>
                    </div>
                    <div className="success_card my-5">
                        <div>
                            <h4 className='mb-4'>Email Sent!</h4>

                            <p>
                                A link to reset your password has been sent to you on {email}

                            </p>
                        </div>

                    </div>



                </div>


            </div>
            <Footer />


        </div>
    )
}

export default ResetSuccessPage

import { useNavigate } from 'react-router-dom';
import s from './css/Previous.module.sass';

function Previous({ text, redirect }) {
	const navigate = useNavigate();
	return (
		<div className={`${s.previous} d-flex align-item-center gap-1 `} onClick={() => navigate(redirect ?? -1)}>
			<i className="fa-solid fa-chevron-left"></i>
			<h2 className='back'>{text ?? `Back`}</h2>
		</div>
	);
}

export default Previous;

import Button from 'components/buttons/Button';
import React, { useState } from 'react';


const AddSkills = ({ context, callback }) => {
	const [skills, setSkills] = useState({ name: '', experience_years: '', competency: '' });

	const [formErrors, setFormErrors] = useState(skills);

	function handleOnChangeDynamic(event) {
		const name = event.target.name;
		const value = event.target.value;
		setSkills({
			...skills,
			[name]: value,
		});
		setFormErrors({ ...skills, [name]: `` });
	}
	function hasErrors() {
		for (const field in skills) {
			if (!skills[field]) {
				validationHandler(field);
				console.warn(`{${field}} field is required`);

				return true;
			} else if (validations()) {
				return true;
			}
		}

		return false;
	}

    function validationHandler(field, errorMsg = 'field is required') {
		const formElement = document.getElementById('con-form').elements;
		formElement[field]?.focus();
		formElement[field]?.scrollIntoView({ block: 'center' });
		setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
	}

	function configFields(skills) {}

	function handleOnSubmit() {
		if (!hasErrors()) {
			configFields(skills);
			callback(skills);
			setSkills({ name: '', experience_years: '', competency: '' });
		}
	}

	function exitModal() {
		return {
			doDismissModal: true,
		};
	}

    function validations() {
		if (!true) {
			return true;
		}
	}
	return (
		<form>
			<div>
				<div className="con-skills mb-5">
					<div className="con-input">
						<div className="d-flex  flex-column px-3 justify-content-between gap-lg-3">
							<div className="control-form">
								<label>Skill</label>
								<input placeholder={`Add Skill `} name="name" onChange={handleOnChangeDynamic} value={skills?.name} />
							</div>

							<div className="control-form">
								<label>Years of Professional Experience</label>
								<input placeholder={'Years of Experience'} type={'number'} name="experience_years" onChange={handleOnChangeDynamic} min={1} value={skills?.experience_years} />
							</div>

							<div className="control-form">
								<label>Competency</label>
								<select name="competency" onChange={handleOnChangeDynamic} value={skills?.competency}>
									<option value="">Select Competency</option>
									<option value={1}>Beginner</option>
									<option value={2}>Intermediate</option>
									<option value={3}>Advanced</option>
									<option value={4}>Expert</option>
								</select>
							</div>

                            {!skills.competency || !skills.experience_years || !skills.name  ? (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								onClick={handleOnSubmit}
							/>
						) : (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								{...exitModal()}
								onClick={handleOnSubmit}
							/>
						)}
						</div>

						
					</div>
				</div>
			</div>
		</form>
	);
};

export default AddSkills;

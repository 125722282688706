import { useState, useRef, useEffect } from 'react';
import Navbar from 'views/_shared/navbar/Navbar';
import './css/CreateProject.style.sass';
import { navbarComp } from '../../dashboard/talent/navbar-components/navbar-comp';
import { ImageUploadComponent, TextUploadComponent, VideoUploadComponent } from './file-upload-component/FileUploadComponent';
// import FileTypeButtons from './file-type-buttons/FileTypeButtons';
import Footer from 'views/_shared/footer/Footer';
import api from 'services/api';
import service from 'services';
import { useNavigate } from 'react-router-dom';
import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';

function CreateProject() {
	const userType = service.getUserType();
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [currentStage, setCurrentStage] = useState(1);
	const [projectData, setProjectData] = useState({
		project_title: '',
		project_hash_tags: [],
		project_images: [],
		project_description: '',
		project_videos: [],
		project_skills: [],
		project_cover_image: '',
		project_source_file: [],
		project_link: '',
		project_status: '',
	});

	const [error, setError] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [publishing, setPublishing] = useState(false);
	const [drafting, setDrafting] = useState(false);
	const [isLoadingDraft, setIsLoadingDraft] = useState(false);
	const [isLoadingPublish, setIsLoadingPublish] = useState(false);
	const [images, setImages] = useState([]);
	const [videos, setVideos] = useState([]);

	useEffect(() => {
		if (projectData.project_images.length > 0 && projectData.project_cover_image === '') {
			setProjectData((prevData) => ({
				...prevData,
				project_cover_image: prevData.project_images[0],
			}));
		}
	}, [projectData]);

	const fileInputRef = useRef(null);
	const ImageInputRef = useRef(null);
	const VideoInputRef = useRef(null);
	const MAX_VIDEO_SIZE_MB = 20;
	const MAX_VIDEO_SIZE_BYTES = MAX_VIDEO_SIZE_MB * 1024 * 1024;
	const handleFileSelection = () => {
		fileInputRef.current.click();
	};

	const coverImageRef = useRef(null);

	const handleCoverSelection = () => {
		coverImageRef.current.click();
	};

	const navigate = useNavigate();

	// const handleTypeSelection = (type) => {
	// 	setSelectedFileType(type);
	// };

	const handleNextStage = () => {
		if (!projectData.project_description) {
			alert('Please provide a project description.');
			return;
		}

		if (projectData.project_images.length === 0) {
			alert('Please upload at least one project image.');
			return;
		}

		if (projectData.project_skills.length < 2) {
			alert('Please select at least two project skills.');
			return;
		}

		setCurrentStage(currentStage + 1);
		setIsModalOpen(true);
	};

	const handlePreviousStage = () => {
		setCurrentStage(currentStage - 1);
		setIsModalOpen(false);
	};

	const handleProjectTitleChange = (event) => {
		const value = event.target.value;
		setProjectData((prevState) => ({
			...prevState,
			project_title: value,
		}));
	};

	const handleProjectSourceFile = (event) => {
		const files = event.target.files;
		setProjectData((prevState) => ({
			...prevState,
			project_source_file: [...prevState.project_source_file, ...Array.from(files)],
		}));
	};

	function handleChangeCoverImage(event) {
		const newCoverImageFile = event.target.files[0];
		setProjectData((prevData) => ({
			...prevData,
			project_cover_image: newCoverImageFile,
		}));
	}

	const deleteSourceFile = (index) => {
		setProjectData((prevState) => {
			const updatedSourceFiles = [...prevState.project_source_file];
			updatedSourceFiles.splice(index, 1);
			return {
				...prevState,
				project_source_file: updatedSourceFiles,
			};
		});
	};

	const handleProjectLink = (event) => {
		const value = event.target.value;
		setProjectData((prevState) => ({
			...prevState,
			project_link: value,
		}));
	};

	const handleHashtagsChange = (event) => {
		const hashtag = event.target.value;
		setProjectData((prevState) => ({
			...prevState,
			project_hash_tags: hashtag.split(',').map((tag) => tag.trim()),
		}));
	};

	const handleImageUpload = (images) => {
		setProjectData((prevState) => ({
			...prevState,
			project_images: [...prevState.project_images, ...images],
		}));
	};

	const handleTextUpload = (text) => {
		setProjectData((prevState) => ({
			...prevState,
			project_description: text,
		}));
	};

	const handleVideoUpload = (videos) => {
		setProjectData((prevState) => ({
			...prevState,
			project_videos: [...prevState.project_videos, ...videos],
		}));
	};

	const handleSkillClick = (skill) => {
		setProjectData((prevState) => {
			const projectSkills = prevState.project_skills;

			if (projectSkills.includes(skill)) {
				return {
					...prevState,
					project_skills: projectSkills.filter((selected) => selected !== skill),
				};
			} else {
				return {
					...prevState,
					project_skills: [...projectSkills, skill],
				};
			}
		});
	};

	const renderModalContent = () => {
		return (
			<div id="modal_component" style={{ height: '90%', overflow: 'auto' }}>
				<section className="d-flex justify-content-between bg-dark p-1">
					<img src="/img/svg/profile.svg" alt="" style={{ width: '20px' }} />
					<p style={{ color: 'white' }}>PUBLISH</p>
					<img src="/img/svg/X.svg" alt="" style={{ width: '20px', cursor: 'pointer' }} onClick={handlePreviousStage} />
				</section>
				<section className="project_details row">
					{/* cover */}
					<div className="left col-lg-4">
						<h3 className="fw-semibold  mb-2" style={{ fontSize: '18px' }}>
							Project Cover
						</h3>
						{projectData.project_cover_image && (
							<img
								src={URL.createObjectURL(projectData.project_cover_image)}
								alt="cover_image"
								style={{ width: '100%', height: '235px', borderRadius: '4px', boxShadow: ' rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' }}
								className="cover"
							/>
						)}
						<button type="button" className="below_attach mt-5" style={{ margin: '10px 0' }} onClick={handleCoverSelection}>
							Attach Cover image <img src="/img/svg/attach.svg" alt="" style={{ width: '20px' }} />
						</button>
						<input type="file" ref={coverImageRef} accept="image/png, image/jpg, image/jpeg" onChange={handleChangeCoverImage} className="d-none" />
					</div>
					{/* details */}
					<div className="d-flex flex-column gap-4 right col-lg-7">
						<div className="d-flex flex-column gap-2">
							<label>Project Title</label>
							<input type="text" value={projectData.project_title} onChange={handleProjectTitleChange} placeholder="Your Title" className="p-3" />

							<p style={{ color: 'red', fontSize: '16px', fontStyle: 'italic' }}>{error}</p>
						</div>
						<div className="d-flex flex-column gap-2">
							<label>Hashtags</label>
							<textarea value={projectData.project_hash_tags} onChange={handleHashtagsChange} rows={8} style={{ width: '100%' }} placeholder="eg... #react,#figma" className="p-3" />
							<p style={{ color: 'red', fontSize: '16px', fontStyle: 'italic' }}>{error}</p>
						</div>
					</div>
				</section>

				<section className="buttons mt-5 mb-3 d-flex gap-2" style={{ marginLeft: 'auto', marginRight: '20px' }}>
					<button onClick={handlePreviousStage}>Cancel</button>
					<Button text={<LoaderButton isLoading={isLoadingDraft} text="Draft" />} onClick={handledraftProject} disabled={isLoadingDraft || isLoadingPublish} />

					<Button text={<LoaderButton isLoading={isLoadingPublish} text={error ? 'Retry' : 'Publish'} />} onClick={handlePublishProject} disabled={isLoadingDraft || isLoadingPublish} />
				</section>
			</div>
		);
	};

	const renderStage = () => {
		const handleImageFileSelection = () => {
			if (ImageInputRef.current) {
				ImageInputRef.current.click();
			}
		};

		const handleFileImageUpload = (event) => {
			const fileList = event.target.files;
			if (fileList) {
				const imageList = Array.from(fileList);
				setImages((prevImages) => [...prevImages, ...imageList]);
				handleImageUpload(imageList);
			}
		};

		const handleFileVideoSelection = () => {
			VideoInputRef.current.click();
		};

		const handleFileVideoUpload = (event) => {
			const fileList = event.target.files;
			if (fileList) {
				const videoList = Array.from(fileList);

				// Check the size of each video
				const validVideos = videoList.filter((video) => {
					if (video.size <= MAX_VIDEO_SIZE_BYTES) {
						return true;
					} else {
						alert(`${video.name} is too large. Max file size is ${MAX_VIDEO_SIZE_MB} MB.`);
						return false;
					}
				});

				if (validVideos.length > 0) {
					setVideos((prevVideos) => [...prevVideos, ...validVideos]);
					handleVideoUpload(validVideos);
				}
			}
		};

		return (
			<div className="d-flex flex-column gap-4 mb-4" style={{ width: '100%' }}>
				<TextUploadComponent onUpload={handleTextUpload} uploadedText={projectData.project_description} />
				<div className="d-flex gap-5 my-4">
					<div>
						<input type="file" accept="image/png, image/jpeg, image/jpg" multiple onChange={handleFileImageUpload} style={{ marginBottom: '1rem', display: 'none' }} ref={ImageInputRef} />
						<button className="my-2 upload_button" style={{ fontWeight: 'bold' }} onClick={handleImageFileSelection}>
							<img src="/img/image.png" alt="" />
						</button>
						<p className="upload_tag">Image</p>
					</div>
					<div>
						<input type="file" accept="video/mp4" ref={VideoInputRef} multiple onChange={handleFileVideoUpload} style={{ marginBottom: '1rem', display: 'none' }} />
						<button className="upload_button my-2" style={{ fontWeight: 'bold' }} onClick={handleFileVideoSelection}>
							<img src="/img/video.png" alt="" />
						</button>
						<p className="upload_tag">Video</p>
					</div>
				</div>
				<div className="">
					<ImageUploadComponent ref={ImageInputRef} setImages={setImages} images={images} />

					<VideoUploadComponent onUpload={handleVideoUpload} videos={videos} setVideos={setVideos} ref={VideoInputRef} MAX_VIDEO_SIZE_MB={MAX_VIDEO_SIZE_MB} MAX_VIDEO_SIZE_BYTES={MAX_VIDEO_SIZE_BYTES} />
				</div>
			</div>
		);
	};

	function publishProject(status) {
		const requiredFields = ['project_title', 'project_hash_tags', 'project_description', 'project_images', 'project_skills'];

		let hasError = false;

		for (const field of requiredFields) {
			if (field === 'project_images' && projectData[field].length === 0) {
				setError('Please upload at least one project image.');
				hasError = true;
			} else if (field === 'project_skills' && projectData[field].length < 2) {
				setError('Please select at least two project skills.');
				hasError = true;
			} else if (field === 'project_hash_tags' && projectData[field].length === 0) {
				setError('Please add at least one project hash tag.');
				hasError = true;
			} else if (!projectData[field]) {
				setError(`Please fill in the ${field.replace('_', ' ')} field.`);
				hasError = true;
			}

			if (hasError) {
				setTimeout(() => {
					setError('');
				}, 3000);
				if (status === 'published') {
					setIsLoadingPublish(false);
				} else {
					setIsLoadingDraft(false);
				}
				return;
			}
		}

		const handleSuccess = () => {
			setPublishing(true);
			navigate('/dashboard/projects');
		};

		const handleError = (response) => {
			const check = response.data.status;
			if (check === false) {
				console.log('error creating project', response);
			}
		};

		const formData = new FormData();
		formData.append('project_title', projectData.project_title);
		formData.append('project_description', projectData.project_description);
		formData.append('project_cover_image', projectData.project_cover_image);
		formData.append('project_link', projectData.project_link);
		formData.append('project_status', status);

		projectData.project_skills.forEach((skill) => {
			formData.append('project_skills', skill);
		});

		projectData.project_source_file.forEach((source) => {
			formData.append('project_source_files', source);
		});

		if (Array.isArray(projectData.project_hash_tags)) {
			projectData.project_hash_tags.forEach((hash) => {
				formData.append('project_hash_tags[]', hash);
			});
		}

		for (let i = 0; i < projectData.project_images.length; i++) {
			formData.append('project_images', projectData.project_images[i]);
		}
		for (let i = 0; i < projectData.project_videos.length; i++) {
			formData.append('project_videos', projectData.project_videos[i]);
		}

		if (userType === 'talent') {
			api.talents.projects
				.createProject(formData)
				.then(handleSuccess)
				.catch(handleError)
				.finally(() => {
					if (status === 'published') {
						setIsLoadingPublish(false);
					} else {
						setIsLoadingDraft(false);
					}
				});
		} else {
			api.freelancers.projects
				.createProject(formData)
				.then(handleSuccess)
				.catch(handleError)
				.finally(() => {
					if (status === 'published') {
						setIsLoadingPublish(false);
					} else {
						setIsLoadingDraft(false);
					}
				});
		}
	}

	const handlePublishProject = () => {
		setIsLoadingPublish(true);
		setPublishing(true);
		setDrafting(false);

		if (error) {
			setPublishing(false);
			setIsLoadingPublish(false);
			return;
		}

		publishProject('published');
	};

	const handledraftProject = () => {
		setIsLoadingDraft(true);
		setPublishing(publishing);
		setDrafting(!drafting);
		publishProject('drafted');
	};

	return (
		<main>
			<Navbar {...navbarComp} />

			<section className="container" id="CreateProject_Main_Container" ref={top}>
				<div className="row">
					<div className="">
						<h3>Get recruiters to know you more</h3>
						<p>Post updates on your progress!</p>
						<section className="Create_Project_Card">
							<h3 id="title" className="d-flex gap-1 align-items-center py-2">
								<img src="/img/svg/book.svg" alt="book" style={{ width: '20px' }} /> What would you love to post today
							</h3>
							<div className="render">{renderStage()}</div>
							<div className="below">
								<input type="file" multiple accept=".pdf,.doc,.docx" ref={fileInputRef} onChange={handleProjectSourceFile} style={{ display: 'none' }} />
								<button type="button" className="below_attach" onClick={handleFileSelection}>
									Attach Source File <img src="/img/svg/attach.svg" alt="" style={{ width: '20px' }} />
								</button>
								<div className="row gap-2 mx-2">
									{projectData.project_source_file.map((file, index) => (
										<div key={index} className="d-flex justify-content-between border border-3 border-dark p-2 col-6 rounded-3 mb-3" style={{ maxWidth: '280px' }}>
											<div style={{ width: '90%' }}>
												<p className="text-truncate" style={{ maxWidth: '95%' }}>
													{file.name}
												</p>
											</div>
											<button onClick={deleteSourceFile}>
												<img src={'/img/svg/delete2.svg'} alt="Edit" style={{ width: '25px', height: '25px' }} />
											</button>
										</div>
									))}
								</div>
								<p>Add files like fonts, illustrations, photos, or templates to this project</p>
								<h3>OR</h3>
								<input type="text" name="link" id="link" placeholder="Enter link to file" value={projectData.project_link} onChange={handleProjectLink} className="p-3" />
								{/* skills */}
								<h3 className="d-flex gap-1 align-items-center">
									<img src="/img/svg/toolkit.svg" alt="tools" style={{ width: '20px' }} /> What skill is related to this project
								</h3>
								<div id="related_skill">
									{['photoshop', 'UI/UX', 'Frontend dev', 'backend dev', 'cloud computing', 'copy writing', 'instructor', 'UX writing', 'DSA', 'computer engineering'].map((skill, index) => {
										const isSelected = projectData?.project_skills?.includes(skill);
										const skillStyle = {
											color: isSelected ? 'white' : '',
											backgroundColor: isSelected ? 'black' : 'transparent',
											borderColor: isSelected ? 'transparent' : '',
										};
										return (
											<div id={'skill'} key={index} style={skillStyle} onClick={() => handleSkillClick(skill)} className="position-relative">
												<p>{skill}</p>
												{isSelected && <i className="fa-solid fa-xmark"></i>}
											</div>
										);
									})}
								</div>
								<button type="submit" onClick={handleNextStage} className="btn-solid">
									Next ➜
								</button>
							</div>
						</section>
						{isModalOpen && (
							<section className="project_modal ">
								<>{renderModalContent()}</>
							</section>
						)}
					</div>
				</div>
			</section>
			<Footer />
		</main>
	);
}

export default CreateProject;

import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import style from './css/Modal.module.sass';

function ModalBody({ title, callback, _id: jobId }) {
	const [job, setJob] = useState({});
	const [logo, setLogo] = useState(null);

	function fetchJob() {
		function successHandler(response) {
			const result = response.data.result;
			setJob({ ...result });
		}

		function errorHandler(response) {
			console.error(response);
		}
		api.recruiter.profile.get().then((response)=>setLogo(response.data?.result?.company_logo?.href_link))
		api.recruiter.getJob(jobId).then(successHandler).catch(errorHandler);
	}

	// eslint-disable-next-line
	useEffect(fetchJob, []);

	return (
		<div className={`${style['modal-job-preview']}`}>
			<div className={`${style['section-a']} mb-4`}>
				<div className={`${style.header} d-lg-flex align-items-center justify-content-between`}>
					<div className="d-flex align-items-center gap-4 gap-lg-3 mb-3 mb-lg-0">
						<div className="img w-25">
							<img src={logo || "/img/avatar.png"} alt="" />
						</div>

						<div className={`${style.body}`}>
							<h2>{title || `C++ Developer`}</h2>
							<h3>{job?.company_name ? service.titleCase(job?.company_name) : `company name`}</h3>
							<p className="d-flex align-items-center gap-1">
								<i className="fa-solid fa-location-dot"></i>
								{(job?.location?.state && service.titleCase(job?.location?.state)) || `state`}, {(job?.location?.country && service.titleCase(job?.location?.country)) || `country`}
							</p>
							<p className="d-flex align-items-center gap-1">
								<i className="fa-solid fa-globe"></i>
								<Link to="/register?tab=recruiter&section=004" href="./">
									{job?.company_link || `company link`}
								</Link>
							</p>
						</div>
					</div>

					<div className={`${style['con-button']} d-flex align-items-center gap-2`}>
						<div className="button-1">
							<Button
								fadeFill
								text={
									<>
										<h1>Apply now</h1>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
							/>
						</div>

						<div className="button-2">
							<Button fadeFill text={<i className="fa-solid fa-xmark" data-bs-dismiss="modal"></i>} />
						</div>
					</div>
				</div>

				<div className={`${style.social} d-flex align-items-center gap-4`}>
					{/* <p>social links:</p>
					<Link>
						<i className="fa-brands fa-linkedin-in"></i>
					</Link>
					<Link>
						<i className="fa-brands fa-instagram"></i>
					</Link>
					<Link>
						<i className="fa-solid fa-basketball"></i>
					</Link>

					<Link>
						<i className="fa-brands fa-github"></i>
					</Link>
					<Link>
						<i className="fa-brands fa-gitlab"></i>
					</Link> */}
				</div>
			</div>

			<div className={`${style['section-b']} mb-3`}>
				<div className={`${style.header} d-flex align-items-center gap-3`}>
					<i className="fa-solid fa-screwdriver-wrench"></i>
					<h2>Job Description</h2>
				</div>

				<div className={`${style.body}`}>
					<p>{job?.description || `description`}</p>
				</div>
			</div>

			<div className={`${style['section-c']} mb-3`}>
				<div className={`${style.header} d-flex align-items-center gap-2 mb-3`}>
					<i className="fa-solid fa-dollar-sign"></i>
					<h2>Expected Pay</h2>
				</div>

				<div className={`${style.body}`}>
					<h2>{job?.currency}{job?.expected_pay?.toLocaleString() || `expected pay`} </h2>
				</div>
			</div>

			<div className={`${style.footer} d-flex flex-column flex-lg-row align-items-lg-center gap-3`}>
				<div data-bs-dismiss="modal">
					<Button secondaryNull small text={<h2>Cancel</h2>} />
				</div>

				<div data-bs-dismiss="modal">
					<Button primaryNull small text={<h1>Edit</h1>} redirect={`/dashboard/jobs/${job?._id}`} />
				</div>

				<div data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#success-prompt-modal">
					<Button
						small
						text={
							<>
								<h2>Post Opportunity</h2>
								<i className="fa-solid fa-arrow-right"></i>
							</>
						}
						onClick={callback}
					/>
				</div>
			</div>
		</div>
	);
}

export const handleModal = (props) => {
	return {
		modalWidth: '700px',
		modalTarget: `modal-job-preview-${props.tag}`,
		modalTitle: 'draft post preview',
		modalBody: <ModalBody {...props} />,
	};
};

import Button from 'components/buttons/Button';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
import './css/SectionC.style.sass';
import '../../jobs/applicants/css/ApplicantsCards.scss'
import Tab from 'components/buttons/tabs/Tabs';

function SectionC({ hire, picked, job }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const [isLoading] = useState(false);

	const formatNumberToLocale = (number, locale = 'en-US') => {
    return new Intl.NumberFormat(locale).format(number);
  };

	return (
		<div id="Onboarding_Talent_SectionC_Main_Component" ref={top} style={{ backgroundColor: '#FFFBF2', paddingBottom: '30px' }}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<div className="mb-3 d-flex align-items-center gap-1" style={{ color: '#CC5600', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate(-1)}>
								<i class="fa-solid fa-chevron-left"></i>
								<p>Back</p>
							</div>

							<h5>Welcome to Recruitment Wizard</h5>

							<div className='d-flex' style={{ gap: '20px' }}>
								<h1 className="mb-3 display">Let's get your Talent onboard!</h1>
								<img src="/img/svg/rocket.svg" alt="rocket" style={{ width: '60px' }} />
							</div>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="75" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01" style={{ backgroundColor: 'white', padding: '15px', borderRadius: '15px' }}>
				<div className="con-form">
					<div className="form">
						<form>

							<div className='d-lg-flex justify-content-between p-2'>
								<article>
									<h4>Preview</h4>
									<p>Ensure you review Criteria before selecting candidates</p>
								</article>
								<Button text={'Review Criteria'} secondaryNull onClick={()=>navigate(`/dashboard/jobs/criteria/${job?._id}`)}/>
							</div>

							<div className="con-context">
								<h5 className="title">Candidate(s)</h5>

								<div className='cards-b'>
									{
										picked.map((result, idx) => {
											const isChecked = hire.selectedTalents.includes(result.talent?.id);
											return (
												<article key={idx} >
													<div className={'card'} style={{ height: '270px', width: '200px' }}>
														<div className={'header'}>
															<p>{`CAND${idx + 1}`}</p>
															<i className="fa-regular fa-user"></i>
														</div>
														<div class="checkbox-container">
															<input type="checkbox" id={`checkbox-${idx}`} name={`checkbox-${idx}`} checked={isChecked} />
															<label htmlFor={`checkbox-${idx}`}></label>
														</div>
														<div className={'Cardbody'}>
															<div className={'img'}>
																<img src={result.talent?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="head shot" />
															</div>
															<div className={'text'}>
																<div style={{ display: 'flex', gap: '5px' }}>
																	<h1 className='truncate-e' style={{ fontSize: '16px' }}>
																		{result?.applicant_first_name} {result?.applicant_last_name}
																	</h1>
																	<img src={result?.isProfileApproved ? "/img/svg/verified.svg" : '/img/svg/pending.svg'} alt="" style={{ width: '15px' }} />
																</div>
																<h3 className="my-2 text-truncate">{result?.title}</h3>
																<p className="d-flex align-items-center gap-2 truncate-e">
																	<i className="fa-solid fa-location-dot"></i>
																	{result.talent?.location?.resident_state || `Lagos`}, {result.talent?.location?.resident_country || `Nigeria`}
																</p>
															</div>
														</div>
													</div>
												</article>
											);
										})}
								</div>
							</div>

							<div className='my-4' style={{ border: '1px solid #2E2E30' }}></div>

							<div className="d-lg-flex justify-content-between gap-2">
								<div className="control-form">
									<div className="d-flex align-items-center gap-2">
										<i class="fa-solid fa-user-tie"></i>
										<p style={{ fontSize: '16px', fontWeight: 'bold' }}>Talent type</p>
									</div>
									<div className="d-flex align-items-center gap-1 mt-3">
										<Tab primaryOutline context={hire?.talentType} key={'y'} callback={''} />
									</div>
								</div>
							</div>

							<div className='my-4' style={{ border: '1px solid #2E2E30' }}></div>

							<div className="d-lg-flex justify-content-between gap-2">
								<div className="control-form">
									<div className="d-flex align-items-center gap-2">
										<i class="fa-solid fa-dollar-sign"></i>
										<p style={{ fontSize: '16px', fontWeight: 'bold' }}>Expected Pay</p>
									</div>
									<div className="d-flex align-items-center gap-1 mt-3" style={{ color: '#CC5600' }}>
										<h2><span>{job?.currency}</span>{" "}{formatNumberToLocale(hire.salary)}</h2>
									</div>
								</div>
							</div>

							<div className='my-4' style={{ border: '1px solid #2E2E30' }}></div>

							<div className="d-lg-flex justify-content-between gap-2">
								<div className="control-form">
									<div className="d-flex align-items-center gap-2">
										<i class="fa-solid fa-user-tie"></i>
										<p style={{ fontSize: '16px', fontWeight: 'bold' }}>Duration</p>
									</div>
									<div className="d-flex align-items-center gap-1 mt-2" style={{ color: '#CC5600' }}>
										<h2>{hire.duration}</h2>
									</div>
								</div>
							</div>

							<div className='my-4' style={{ border: '1px solid #2E2E30' }}></div>




							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3">
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<h1>Review Criteria</h1>
											</>
										}
										secondaryNull
										onClick={()=>navigate(`/dashboard/jobs/criteria/${job?._id}`)}
									/>
								</div>
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>Back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button text={<>Proceed to Checkout<i className="fa-solid fa-arrow-right"></i> </>}  onClick={() => navigate({ search: `?section=5` })} disabled={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionC;

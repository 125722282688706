import Button from 'components/buttons/Button';
import style from './css/Modal.module.sass';

function Modal({ callback, context }) {
	return (
		<div className={`${style['con-main']} d-flex flex-column align-items-center gap-4`}>
			<h2 className="title">Warning</h2>
			<div className="img">
				<img src="/img/Group 282.png" alt="" />
			</div>
			<p>You are about to delete an item.</p>
			<div className="footer d-flex flex-column flex-lg-row align-items-center gap-3">
				<div data-bs-dismiss={`modal`}>
					<Button text="Cancel" primaryNull />
				</div>
				<div data-bs-dismiss={`modal`}>
					<Button text="Continue" onClick={() => callback(context)} />
				</div>
			</div>
		</div>
	);
}

export default Modal;

import { useEffect, useRef } from 'react';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import './css/Onboarding.style.sass';
import Recruiter from './recruiter/Recruiter';
import Talent from './talent/Talent';
import { FormProvider } from 'ContextApi/FormContext';
import Button from 'components/buttons/Button';
import { doHandleLogout } from 'views/dashboard/logout/Logout';


function Onboarding() {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const userType = service.getUserType();

	service.setPageTitle(`${service.titleCase(userType)} Onboarding`);

	return (
		<div id="Onboarding_Main_Component" ref={top}>
			<Navbar
				rightContent={
					<div className="d-flex align-items-center justify-content-between">
						<Button text={<h2>Logout</h2>} small redirect="/login" onClick={doHandleLogout} />
					</div>
				}
				sidebarContent={
					<ul className='p-2'>
						<Button text={<h2>Logout</h2>} small redirect="/login" onClick={doHandleLogout} />
					</ul>
				}
			/>
			<FormProvider>
				<div className="con-margin-height">
					{userType === service.RECRUITER && <Recruiter />}
					{userType === service.TALENT && <Talent />}
					{userType === service.FREELANCER && <Talent />}
				</div>
			</FormProvider>

			<Footer />
		</div>
	);
}

export default Onboarding;

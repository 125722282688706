import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api';

const ChatWindow = ({ chat, onBack, socket, loading }) => {
	const [message, setMessage] = useState('');
	const ACTIVE_ROLE = service.getUserType();
	const [sender, setSender] = useState('');
	const [senderId, setSenderId] = useState('');

	const [itemsPerPage, setItemsPerPage] = useState(100);

	const [showingSuggestions, setShowingSuggestions] = useState(false);

	const [messages, setMessages] = useState([]);
	const [customMessages, setCustomMessages] = useState([]);

	const [isEnded, setIsEnded] = useState(false);
	const [isEndedReason, setIsEndedReason] = useState('');
	const [olderMessagesLoading, setOldMessagesLoading] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
	const messagesEndRef = useRef(null);
	const chatWindowRef = useRef(null);

	const encodeMessage = (text) => btoa(text);
	const decodeMessage = (encodedText) => atob(encodedText);

	const fetchCustomMessages = () => {
		api
			.getCustomMessages()
			.then((res) => {
				setCustomMessages(res.data);
			})
			.catch(console.error);
	};

	useEffect(() => {
		fetchCustomMessages();
		if (ACTIVE_ROLE === service.RECRUITER) {
			setSender('RECRUITER');
			setSenderId(chat?.recruiter?._id);
		} else {
			setSender('TALENT');
			setSenderId(chat?.talent?._id);
		}
	}, [ACTIVE_ROLE, chat?.recruiter, chat?.talent]);

	// socket event for receiving messages
	useEffect(() => {
		const handleMessageReceive = (newMessage) => {
			setMessages((prevMessages) => [...prevMessages, newMessage]);
			scrollToBottom();
			const readData = {
				reader: sender,
				messageId: newMessage._id,
			};
			if (chat) {
				socket.emit('read-message', readData);
			}
		};
		socket.on('receive-message', handleMessageReceive);
		// Cleanup to remove the listener when the component unmounts or socket changes
		return () => {
			socket.off('receive-message', handleMessageReceive);
		};
	}, [chat, sender, socket]);

	// socket event for sending messages
	const sendAMessage = async () => {
		if (message !== '') {
			const messageData = {
				conversationId: chat?._id,
				text: message,
				sender: sender,
				senderId: senderId,
			};

			await socket.emit('send-message', messageData, (data) => {
				if (data === true) {
					const containsNumber = /\d/.test(message);
					const isPhoneNumber = /(\+?\d{1,4}[-.\s]?)?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}/.test(message);

					setMessages((prevMessages) => [
						...prevMessages,
						{
							...messageData,
							sender: ACTIVE_ROLE,
							isHidden: containsNumber || isPhoneNumber ? true : false,
						},
					]);
					setMessage('');
				}
			});
		}
	};

	// click event for suggestions click
	const handleSuggestionClick = async (suggestedMessage) => {
		setMessage(suggestedMessage);
		setShowingSuggestions(false);

		// Ensure the message is updated before sending
		await sendAMessage();
	};

	// listen for changes in the socket
	useEffect(() => {
		socket.on('conversation-ended', (data) => {
			if (data.isEnded === true) {
				setIsEnded(true)
				setIsEndedReason(data?.endReason)
			}
		});
		socket.on('conversation-resumed', (data) => {
			if (data.isEnded === false) {
				setIsEnded(false)
			}
		})
		socket.on('message-hidden', (data) => {
			setMessages((prevMessages) =>
				prevMessages.map((message) =>
					message._id === data._id
						? { ...message, isHidden: true }
						: message
				)
			);
		})
	}, [socket]);

	useEffect(() => {
		if (chat?.isEnded) {
			setIsEnded(true);
			setIsEndedReason(chat?.endReason)
		}
	}, [chat?.endReason, chat?.isEnded, socket]);

	// get all chat history messages
	useEffect(() => {
		setIsLoading(true);
		if (chat?._id !== undefined) {
			api
				.getMessages(chat?._id, sender, itemsPerPage)
				.then((res) => {
					const reversedArray = res.data.reverse();
					setMessages(reversedArray);
				})
				.catch(console.error)
				.finally(() => {
					setIsLoading(false);
					scrollToBottom();
					setShowLoadMoreButton(false)
				});
		}
	}, [chat?._id, itemsPerPage, sender]);

	const fetchOlderMessages = async () => {
		setOldMessagesLoading(true);
		setItemsPerPage((prev) => prev + itemsPerPage);
	};

	const handleScroll = () => {
		if (chatWindowRef.current.scrollTop === 0 && messages.length >= 100) {
			setShowLoadMoreButton(true);
		} else {
			setShowLoadMoreButton(false);
		}
	};

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const renderName = (chat) => {
		if (ACTIVE_ROLE === service.RECRUITER) {
			return <h5 style={{ color: '#D5D5D6' }}>{service.truncateText(`${chat.talent?.first_name} ${chat.talent?.last_name}`, 16)}</h5>;
		} else {
			return <h5 style={{ color: '#D5D5D6' }}>{service.truncateText(`${chat.recruiter?.first_name} ${chat.recruiter?.last_name}`, 16)}</h5>;
		}
	};

	if (!chat) {
		return null;
	}

	return (
		<section style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
			<div className="d-lg-none p-2">
				<button className="btn btn-link" onClick={onBack}>
					&larr; Back
				</button>
			</div>
			<div style={{ backgroundColor: '#2E2E30', padding: '15px 20px', borderRadius: '10px 10px 0 0', position: 'relative' }}>
				<div style={{ display: 'flex', gap: '20px' }}>
					{renderName(chat)}
					{/* <span style={{ fontSize: '13px', color: '#D5D5D6', backgroundColor: '#CC5600', borderRadius: '1000px', padding: '2px 6px' }}>{'1 unread message'}</span> */}
				</div>
			</div>
			<div className="flex-grow-1 overflow-auto p-3 shadow mb-1" style={{ minHeight: '500px', height: '100%', backgroundColor: 'white', position: 'relative' }}>
				<div style={{ display: 'flex', gap: '5px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: '20px' }}>
					<img src="/img/svg/ensure.svg" alt="" style={{ width: '40px' }} />
					<p>Ensure you don’t share personal information like email address, phone numbers and other sensitive information during your conversations</p>
				</div>
				{isEnded && (
					<div
						style={{
							backdropFilter: 'blur(5px)',
							position: 'absolute',
							top: '50%',
							left: '50%',
							width: '100%',
							height: '100%',
							zIndex: 10000,
							transform: 'translate(-50%, -50%)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<Grid
							open={isEnded}
							container
							display={'flex'}
							flexDirection={'column'}
							gap={'10px'}
							justifyContent="center"
							alignItems="center"
							sx={{
								width: 'auto',
								height: '200px',
								maxWidth: 400,
								bgcolor: 'background.paper',
								boxShadow: 24,
								p: 4,
								borderRadius: 2,
								zIndex: 1000,
							}}>
							<Typography variant="h6" component="h2">
								Conversation Ended
							</Typography>
							<p>{isEndedReason}</p>
						</Grid>
					</div>
				)}
				{showLoadMoreButton && (
					<button onClick={fetchOlderMessages} disabled={olderMessagesLoading}>
						{olderMessagesLoading ? 'Loading...' : 'Load Older Messages'}
					</button>
				)}
				<div
					ref={chatWindowRef}
					onScroll={handleScroll}
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						height: '400px',
						overflowY: 'auto',
					}}>
					{isLoading ? (
						<Box display="flex" justifyContent="center" width="100%">
							<CircularProgress sx={{ color: '#FFB700' }} />
						</Box>
					) : (
						messages.map((message, index) => {
							const isSenderActiveRole = message.sender.toLowerCase() === ACTIVE_ROLE.toLowerCase();
							const messageText = message.isHidden ? encodeMessage(message.text) : decodeMessage(encodeMessage(message.text));

							return (
								<div
									key={index}
									style={{
										display: 'flex',
										justifyContent: isSenderActiveRole ? 'flex-end' : 'flex-start',
									}}>
									<div
										style={{
											maxWidth: '60%',
											padding: '10px',
											borderRadius: '15px',
											backgroundColor: isSenderActiveRole ? '#F5DDCC' : '#FFF1CC',
											boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
											wordBreak: 'break-word',
											position: 'relative',
										}}>
										<div style={{ filter: message.isHidden ? 'blur(5px)' : 'none' }}>{message.isHidden ? 'Hidden Content' : messageText}</div>
									</div>
								</div>
							);
						})
					)}
					<div ref={messagesEndRef} />
				</div>
			</div>
			{showingSuggestions && (
				<Box backgroundColor="white" position={'absolute'} bottom={'90px'} width={'100%'} minHeight={'140px'} maxHeight={'40vh'} overflow="scroll" padding="10px">
					<article className="d-flex justify-content-between px-2">
						<div className="d-flex gap-1 align-items-center">
							<img src="/img/svg/suggest.svg" alt="" style={{ width: '15px' }} />
							<h6 style={{ color: '#FFB700' }}>Suggestions Kit</h6>
						</div>
						<img src="/img/xIcon.png" alt="" style={{ width: '10px', cursor: 'pointer' }} onClick={() => setShowingSuggestions(false)} />
					</article>
					<div className="d-flex flex-wrap gap-2 mt-3">
						{customMessages.map((x) => {
							return (
								<div
									key={x._id}
									onClick={() => handleSuggestionClick(x.text)}
									className="text-wrap rounded-2 px-3 py-2 d-flex justify-content-between align-items-center"
									style={{
										width: 'auto',
										color: '#CC5600',
										border: '1px solid #CC5600',
										cursor: 'pointer',
									}}>
									<p style={{ color: '#CC5600', fontWeight: 700 }} className="m-0">
										{x.text}
									</p>
								</div>
							);
						})}
					</div>
				</Box>
			)}
			{!isEnded && (
				<div className="d-flex gap-1" style={{ marginTop: 'auto', width: '100%', alignItems: 'center', backgroundColor: 'white', padding: '10px' }}>
					<article className="" style={{ width: '80%', backgroundColor: '#EBEBEB', borderRadius: '1000px', padding: '10px', display: 'flex', alignItems: 'center' }}>
						<input type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Keep the conversation going!" style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', flex: 1, padding: '5px' }} />
					</article>
					<article className="d-flex gap-1" style={{ width: '20%', alignItems: 'center' }}>
						<img src="/img/svg/suggest.svg" alt="" style={{ width: '40px', cursor: 'pointer' }} onClick={() => setShowingSuggestions(!showingSuggestions)} />
						<div style={{ border: '1px solid #CC5600', padding: '7px', borderRadius: '10px', cursor: 'pointer' }} onClick={sendAMessage}>
							<img src="/img/svg/send.svg" alt="" style={{ width: '30px' }} />
						</div>
					</article>
				</div>
			)}
		</section>
	);
};

export default ChatWindow;

import Button from 'components/buttons/Button';
import ProgressBar from 'components/progress-bar/ProgressBar';
import React, { useEffect, useRef, useState } from 'react';
import service from 'services';
import './css/SectionH.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import { useFormContext } from 'ContextApi/FormContext';

const SectionH = () => {
	const [query] = useSearchParams();
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);
	const [isLoading, setIsLoading] = useState(false);
	const [suggestedSkills, setSuggestedSkills] = useState([]);
	const { formData, updateFormData } = useFormContext();
	const [skills, setSkills] = useState(formData.sectionHData.skills || []);
	// const [errorText, setErrorText] = useState([]);
	const userType = service.getUserType();

	const [newSkills, setNewSkills] = useState({ name: '', experience_years: '', competency: '' });
	const navigate = useNavigate();
	function handleOnChangeDynamic(event) {
		const name = event.target.name;
		const value = event.target.value;
		setNewSkills({
			...newSkills,
			[name]: value,
		});
	}
	function addSkill() {
		if (newSkills.name && newSkills.experience_years && newSkills.competency) {
			setSkills([...skills, newSkills]);
			setNewSkills({ name: '', experience_years: '', competency: '' });
		} else {
			alert('Please fill out the fields before adding a skills');
		}
	}

	function removeSkill(index) {
		updateFormData('sectionHData', skills);

		const updatedSkills = skills.filter((_, idx) => idx !== index);
		setSkills(updatedSkills);
	}
	function hasErrors() {
		if (skills.length === 0) {
			alert('You must add at least one skill.');
			return true;
		}
	}
	function handleSubmit() {
		if (!hasErrors()) {
			setIsLoading(true);
			function successHandler() {
				updateFormData('sectionHData', data);
				const lastPoint = query.get('section')
				localStorage.setItem('lastOnboardingPoint',lastPoint)
				navigate({ search: `section=4` });
			}
			function errorHandler(response) {
				console.log(response);
			}
			function onFinal() {
				setIsLoading(false);
			}
			const data = { skills };

			if (userType === service.FREELANCER) {
				api.freelancers.profile.update(data).then(successHandler).catch(errorHandler).finally(onFinal);
			} else {
				api.talents.profile.update(data).then(successHandler).catch(errorHandler).finally(onFinal);
			}
		}
	}
	function handleSuggestion(value) {
		setNewSkills({ name: value });
	}

	useEffect(() => {
		api.admin.skills.getAllSkills().then(res => setSuggestedSkills(res.data.result)).catch(console.error)
	}, [])

	return (
		<div id="brand-section" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-2" style={{ padding: '0px' }}>
					<div className="header row justify-content-between py-3 align-items-center">
						<h2 className="display col-lg-7">Tell us more about your skills</h2>
						<div className="col-lg-4">
							<ProgressBar percent="28" />
						</div>
						<p className="my-3 col-12 text_intro">
							You are free to modify these selections later on
							<i className="img"></i>
						</p>
					</div>
				</div>
			</div>
			<div className="container-01" style={{ padding: '0px 0px' }}>
				<div className="form">
					<form>
						<div>
							<div className="con-skills mb-5">
								<div className="con-input">
									<div className="d-flex align-items-lg-center flex-column flex-lg-row justify-content-between gap-lg-3">
										<div className="control-form">
											<label>Skill</label>
											<input placeholder={`Add Skill `} name="name" onChange={handleOnChangeDynamic} value={newSkills?.name} />
										</div>

										<div className="control-form">
											<label>Years of Professional Experience</label>
											<input placeholder={'Years of Experience'} type={'number'} name="experience_years" onChange={handleOnChangeDynamic} min={1} value={newSkills?.experience_years} />
										</div>

										<div className="control-form">
											<label>Competency</label>
											<select name="competency" onChange={handleOnChangeDynamic} value={newSkills?.competency}>
												<option value="">Select Competency</option>
												<option value={1}>Beginner</option>
												<option value={2}>Intermediate</option>
												<option value={3}>Advanced</option>
												<option value={4}>Expert</option>
											</select>
										</div>
									</div>

									<Button
										text={
											<>
												<i className="fa-solid fa-plus"></i>
												<h2>Add</h2>
											</>
										}
										onClick={addSkill}
										disabled={skills.length >= 10}
									/>
								</div>

								<div className="your_skills my-4">
									<div className="d-flex justify-content-between align-items-center">
										<h2>Your Skills</h2>
										<h2>Max. 10 skills ({10 - skills.length} remaining)</h2>
									</div>
									<div className="p-4 skills_box my-3 d-flex align-items-center flex-wrap gap-3">
										{skills.length > 0 &&
											skills.map((skill, idx) => (
												<div className="skills_tag px-3 py-2 gap-3">
													<span>{skill?.name}</span> <i class="fa-solid fa-xmark " onClick={() => removeSkill(idx)}></i>
												</div>
											))}
									</div>
								</div>
								<div>
									<h2 className="suggested_skills_header">Suggested Skills</h2>

									<div className="d-flex flex-wrap  align-items-center  gap-3 mt-4">
										{suggestedSkills.length > 0 &&
											suggestedSkills.map((skill, idx) => (
												<div className="skills_tag px-3 py-2 gap-3 " style={{ borderColor: '#BD5A12', color: '#BD5A12', cursor: 'pointer' }} key={idx} onClick={() => handleSuggestion(skill.name)}>
													<span>{skill.name}</span>
												</div>
											))}
									</div>
								</div>
							</div>
						</div>
						{/* Footer Buttons */}
						<div className="control-form d-lg-flex gap-3">
							<div className="mb-5 mb-lg-0">
								<Button
									text={
										<>
											<i className="fa-solid fa-arrow-left"></i>
											<h1>One Step back</h1>
										</>
									}
									primaryNull
									onClick={() => navigate(-1)}
								/>
							</div>

							<div className="mb-4 mb-lg-0">
								<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading || skills.length <= 0} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SectionH;

import Button from 'components/buttons/Button';
import { useState } from 'react';
import s from './css/modal.module.sass';
import { useNavigate } from 'react-router-dom';

function OnboardingModal(props) {
	const { value } = props;
	const [resendEmail, setResendEmail] = useState(false);

	const navigate = useNavigate();

	function handleDismiss() {
		document.getElementById('freelancer-modal').classList.remove('show');
		document.getElementById('freelancer-modal').style.display = 'none';
	}

	return (
		<div className={s.modalBody}>
			<div className="d-flex flex-column align-items-center  ">
				<div className={`${s.header} mt-5`}>
					<h2>Verify it is you!</h2>
				</div>

				<div className="body d-flex flex-column align-items-center my-4">
					<div className={`${s.img} mb-4`}>
						<img src="img/Layer 1.png" alt="" />
					</div>
					<div className={`${s.text} d-flex flex-column align-items-center text-center`}>
						<p>We just sent an email to the address:</p>
						<p>
							<b>{value}</b>. Please check your email and click
						</p>
						<p>on the link provided to verify your address</p>
					</div>
				</div>

				<div className="footer">
					<div className="d-lg-flex align-items-center gap-4">
						<div className="mb-4 mb-lg-0">
							<Button
								primaryNull
								text={resendEmail ? `Successfully Resent Email` : `Resend Verification Email`}
								onClick={() => {
									setResendEmail(true);
									setTimeout(() => setResendEmail(false), 30000);
								}}
								disabled={resendEmail}
							/>
						</div>

						<div className="mb-4 mb-lg-0" data-bs-dismiss={`modal`}>
							<Button text="Continue" onClick={() => navigate('/login')} />
						</div>
					</div>

					<div className="text-center mt-4 mb-5" data-bs-dismiss={`modal`} onClick={handleDismiss}>
						<p className={s.reverse}>Change Email</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OnboardingModal;

import { useSearchParams } from "react-router-dom";
import service from "services";
import Footer from "views/_shared/footer/Footer";
import "./css/Auth.style.sass";

function Auth({ context }) {
  const [query] = useSearchParams();

  return (
    <div id="Auth_Main_Container">
      <div>
        <div className="con-section container-01">
          <div className="row">
            {query.get("tab") !== service.FREELANCER ? (
              <div className="col-12 col-lg-7">
                <div className="con-context">
                  <div className="con-title mb-4 mb-lg-0">
                    <h1>{service.brand.name} is all about you</h1>
                    <p className="mt-3">
                    Join our vibrant community today and let us help you find the perfect talents and talent seekers worldwide?
                    </p>
                  </div>
                  <div className="img d-none d-lg-block">
                    <img
                      src="img/WhatsApp Image 2022-10-13 at 12.49 1 (2).png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {context}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Auth;

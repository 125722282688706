import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';
import style from '../add-experience/css/Modal.module.sass';

function Modal({ callback, experience }) {
	const contractType = ['Full-Time', 'Part-time', 'Contract'];

	const [form, setForm] = useState({
		job_title: experience?.job_title || '',
		contract_type: experience?.contract_type ? contractType.indexOf(experience.contract_type) + 1 : 1,
		company: experience?.company || '',
		start_date: experience?.start_date || '',
		end_date: experience?.end_date || '',
		location: experience?.location || '',
	});


	const [isPresent, setIsPresent] = useState(experience?.end_date === 'present');
	// Function to handle the "Present" checkbox click
	function handlePresentCheckbox() {
		setIsPresent(!isPresent);
		setForm((prev) => ({
			...prev,
			end_date: !isPresent ? 'present' : '',
		}));
	}

	function handleOnChange(e) {
		const { name, value } = e.target;
		setForm({ ...form, [name]: value });
	}
	function hasErrors() {
		for (const field in form) {
			if (form[field] === '' || form[field] === null || form[field] === undefined) {
				return true;
			}
		}
		return false;
	}

	// function validationHandler(field, errorMsg = 'field is required') {
	// 	const formElement = document.forms[0].elements;
	// 	formElement[field]?.focus();
	// 	formElement[field]?.scrollIntoView({ block: 'center' });
	// 	setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
	// 	console.log('value of field >>>>>', formElement);
	// }

	// function validations() {
	// 	return false;
	// }

	function handleOnSubmit() {
		if (!hasErrors()) {
			const updatedForm = { ...form, contract_type: contractType[form.contract_type - 1]?.toLowerCase() };
			callback(updatedForm);
		}
	}

	function exitModal() {
		return {
			doDismissModal: true,
		};
	}

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};
	useEffect(() => {
		if (experience) {
			setForm({
				job_title: experience.job_title || '',
				contract_type: contractType.indexOf(experience.contract_type) + 1 || 1,
				company: experience.company || '',
				start_date: experience.start_date || '',
				end_date: experience.end_date || '',
				location: experience.location || '',
			});
			setIsPresent(experience.end_date === 'present');
		} else {
			console.warn('Experience is undefined in EditExperienceModalBody');
		}
		// eslint-disable-next-line
	}, [experience]);

	return (
		<div>
			<form>
				<div className={style['con-form']}>
					<div className="d-flex align-items-lg-center flex-lg-nowrap flex-wrap gap-lg-3">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Job Title*</label>
							</div>

							<input type="text" placeholder="Enter Job title" name="job_title" value={form?.job_title} onChange={handleOnChange} className={form.job_title ? '' : 'error'} />
							{!form.job_title && <label className="error-msg mb-3">Job title is required</label>}
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Company Name*</label>
							</div>

							<input type="text" placeholder="Enter Company Name" name="company" value={form.company} onChange={handleOnChange} className={form.company ? '' : 'error'} />
							{!form.company && <label className="error-msg mb-3">Company Name is required</label>}
						</div>
					</div>

					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label className="d-flex align-items-center gap-2">Contract type</label>

							{!form.contract_type && <label className="error-msg mb-3">Contract Type is required</label>}
						</div>

						<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
							{contractType.map((x, y) => (
								<Button small text={x} darkFill primaryNull={form.contract_type !== y + 1} onClick={() => setForm({ ...form, contract_type: y + 1 })} key={y} />
							))}
						</div>
					</div>

					<div className={`control-form ${style['con-date']}`}>
						<div className="d-flex flex-column flex-lg-row justify-content-start gap-3">
							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>Start Date*</label>
								</div>

								<input type="date" name="start_date" value={form.start_date} onChange={handleOnChange} className={form.start_date ? '' : 'error'} max={getCurrentDate()} />
								{!form.start_date && <label className="error-msg mb-3">Start Date is required</label>}
							</div>

							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>End Date*</label>
								</div>

								<input type="date" name="end_date" value={form.end_date} onChange={handleOnChange} className={form.end_date ? '' : 'error'} disabled={isPresent} min={form.start_date} />
								{!form.end_date && <label className="error-msg mb-3">End Date is required</label>}
							</div>
						</div>
						<div className="d-flex gap-1 align-items-baseline">
							<input type="checkbox" checked={isPresent} onChange={handlePresentCheckbox} style={{ width: '30px' }} />
							<label className="fw-bold">Presently working here</label>
						</div>
					</div>

					<div className="d-flex align-items-lg-center flex-column flex-lg-row gap-lg-3">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Location</label>
							</div>

							<input type="text" placeholder="Enter Location (e.g. Lagos, Nigeria)" name="location" value={form.location} onChange={handleOnChange} className={form.location ? '' : 'error'} />
							{!form.location && <label className="error-msg mb-3">Location is required</label>}
						</div>
					</div>

					<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
						<div data-bs-dismiss="modal">{<Button text={<h2>Back</h2>} primaryNull />}</div>

						{!form.job_title || !form.contract_type || !form.company || !form.location || !form.start_date || !form.end_date ? (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								onClick={handleOnSubmit}
							/>
						) : (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								{...exitModal()}
								onClick={handleOnSubmit}
							/>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}

export default Modal;

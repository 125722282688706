import service from 'services';
import './css/Footer.style.sass';

function Footer({ logo }) {
	return (
		<div id="Footer_Main_Container">
			<div className="con-footer">
				<div className="container-01 py-5">
					<div className="con-header">
						<div className="con-logo">
							<img id="logo" src={'/img/svg/frontterswhite.svg' || logo} alt="logo" style={{ width: '130px' }} />
						</div>
					</div>

					<div className="con-context d-lg-flex">
						<div className="col-12 col-lg-4">
							<div className="section-a">
								<div className="con-title">
									<h3>about Frontters</h3>
								</div>

								<div className="con-body">
									<p>Frontters is a global recruitment platform with the singular purpose of bridging work and talents. Your one place for opportunities that enables you to grow and scale at your pace without leaving your space.</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-4">
							<div className="section-b">
								<div className="con-title">
									<h3>quick links</h3>
								</div>
								<div className="con-body">
									<ul>
										<li>People</li>
										<li>Partnership</li>
										<li>Hire Contract</li>
										<li>Privacy Policy</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-4">
							<div className="section-c">
								<div className="con-title">
									<h3>Follow Us</h3>
								</div>

								<div className="con-body">
									<div>
										<div className="top pb-5">
											{/* <p>Address</p>
											<p>- {service.brand.location}</p> */}
											<div className='mt-2'>
												<a href="http://https://twitter.com/Frontters">Twitter</a>

											</div>
											<div className='mt-2'>
												<a href="https://www.linkedin.com/company/frontters/">Linkedin</a>

											</div>
											<div className='mt-2'>
												<a href="https://www.instagram.com/frontters/">Instagram</a>

											</div>

										</div>

										
									</div>

									<div></div>
								</div>
							</div>
						</div>
					</div>

					<div className="con-bottom">
						<div className="bottom">&copy; {new Date().getFullYear()}. All rights reserved</div>
					</div>
				</div>
			</div>
		</div>
	);
}


Footer.defaultProps = {
	logo: service.brand.name,
};

export default Footer;

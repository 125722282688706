import style from './css/ContractPreferenceModal.module.sass';
import Button from 'components/buttons/Button';

function ContractPreferenceModal({ index, callback }) {
	return (
		<div className={`${style['con-main']} d-flex flex-column align-items-center gap-4 py-4`}>
			<h2 className={`${style.title}`}>Warning</h2>
			<div className="img">
				<img src="/img/Group 282.png" alt="" />
			</div>
			<p>Change contract preference to {index === 0 ? `Full Time` : `Part Time`}?</p>
			<div className="footer d-flex flex-column flex-lg-row gap-3 align-items-center gap-3">
				<div data-bs-dismiss={`modal`}>
					<Button text="Cancel" primaryNull />
				</div>
				<div data-bs-dismiss={`modal`}>
					<Button text="Continue" onClick={() => callback(index)} />
				</div>
			</div>
		</div>
	);
}

ContractPreferenceModal.defaultProps = {
	callback: () => {},
};

export default ContractPreferenceModal;

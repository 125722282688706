import classNames from "utils/conditional-css-classes/classNames";
import "./css/Tabs.style.sass";

function Tab({
  context,
  callback,
  activeTab,
  outline,
  primaryOutline,
  outlineFade,
  darkBg,
  fade,
}) {
 

  return (
    <div id="Tab_Main_Container">
      <button
        type="button"
        className={classNames(
          "tab mb-3 mb-lg-0",
          outline && "outline-tab",
          primaryOutline && "primary-outline-tab",
          outlineFade && "outline-fade-tab",
          darkBg && "dark-tab",
          fade && "fade-tab",
          activeTab && "active-tab"
        )}
        onClick={callback}
        
      >
        <div>{context || `tab component`}</div>
      </button>
    </div>
  );
}

Tab.defaultProps = {
  callback: () => {},
};

export default Tab;

import { createContext, useContext, useState } from 'react';

const FormContext = createContext();
export const useFormContext = () => {
	return useContext(FormContext);
};

export const FormProvider = ({ children }) => {
	const [formData, setFormData] = useState(() => {
		// Load initial state from local storage
		const savedData = localStorage.getItem('formData');
		return savedData
			? JSON.parse(savedData)
			: {
					sectionAData: {},
					sectionFData: {},
					sectionHData: {},
					sectionJData: {}, 
					sectionKData: {},
					sectionGData: {},
					sectionIData: {},
					sectionCData: {},
					sectionLData: {},
					sectionEData: {},
			  };
	});

	const updateFormData = (sectionKey, data) => {
		setFormData((prev) => {
			const newFormData = {
				...prev,
				[sectionKey]: data,
			};

			// Save to local storage
			localStorage.setItem('formData', JSON.stringify(newFormData));
			return newFormData;
		});
	};

	return <FormContext.Provider value={{ formData, updateFormData }}>{children}</FormContext.Provider>;
};

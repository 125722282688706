const service = {
	brand: {
		name: 'Frontters',
		location: 'The Waterside, 5 Admiralty Road, off Admiralty Way, Lekki Phase 1, Lagos, Nigeria',
		phone: '(+234) 814 196 9381',
	},

	setPageTitle: (component) => (document.title = `${service.brand.name} - ${component}`),

	browserHeight: () => window.innerHeight - 100,

	page: 'tab=',
	section: 'section=',

	RECRUITER: 'recruiter',
	TALENT: 'talent',
	FREELANCER: 'freelancer',

	goTo: ({ current: { offsetTop: top } }) => window.scrollTo({ top, behavior: 'smooth' }),

	isMobile: navigator.userAgent.toLowerCase().match(/mobile/i),

	setTokensLocalStorage: (data) => localStorage.setItem('tokens', JSON.stringify(data)),

	getTokensLocalStorage: () => JSON.parse(localStorage.getItem('tokens')),

	getTokenData: () => JSON.parse(atob(service.getTokensLocalStorage()?.access?.token.split('.')[1])),

	getUserType: () => service.getTokenData()?.userType,

	getUserID: () => service.getTokenData()?.sub,

	removeTokensLocalStorage: () => localStorage.removeItem('tokens'),

	hasAppliedVariable: 'HasApplied',

	errorMilliSecond: 10000,

	reloadPage: () => window.location.reload(),

	truncateText: (text, limit = 16) => `${text?.length > limit ? text.slice(0, limit) + '...' : text}`,

	titleCase: (string) => `${string.slice(0, 1).toUpperCase()}${string.slice(1).toLowerCase()}`,

	transform: (text) => {
		return text.split('\n').map((item, index) => {
			return index === 0 ? item : [<br key={index} />, item];
		});
	},

	omit: (obj, ...props) => {
		const result = { ...obj };
		props.forEach((prop) => delete result[prop]);
		return result;
	},

	monthNames: () => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

export default service;

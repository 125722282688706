import "./css/Circle.style.sass";

function Circle({ callback }) {
  return (
    <div id="Circle_Main_Container">
      <div className="con-circle" onClick={callback}>
        <span className="circle"></span>
        <i className="fa-solid fa-chevron-down"></i>
      </div>
    </div>
  );
}

export default Circle;

import React from 'react';
import classNames from 'utils/conditional-css-classes/classNames';
import s from './css/DotPagination.module.sass';

function DotPagination({ totalItems, itemsPerPage, activeIndex, onChange }) {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	return (
		<div className={s.container}>
			{[...Array(totalPages)].map((_, pageIndex) => (
				<div className={`${s.circle} ${classNames(activeIndex === pageIndex && s.active)}`} key={pageIndex} onClick={() => onChange(pageIndex)}></div>
			))}
		</div>
	);
}

export default DotPagination;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import './VideoCarousel.css';
import '../carousel/Carousel.css';

const VideoCarousel = ({ slides, showButton = true, showIndicator = true, itemsToShowMobile = 1, itemsToShowTablet = 1, itemsToShowDesktop = 1 }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [itemsToShow, setItemsToShow] = useState(itemsToShowMobile);
	const videoRefs = useRef([]);

	const updateItemsToShow = useCallback(() => {
		if (window.innerWidth < 600) {
			setItemsToShow(itemsToShowMobile);
		} else if (window.innerWidth < 900) {
			setItemsToShow(itemsToShowTablet);
		} else {
			setItemsToShow(itemsToShowDesktop);
		}
	}, [itemsToShowMobile, itemsToShowTablet, itemsToShowDesktop]);

	useEffect(() => {
		updateItemsToShow();
		window.addEventListener('resize', updateItemsToShow);

		return () => {
			window.removeEventListener('resize', updateItemsToShow);
		};
	}, [updateItemsToShow]);

	const playVideo = useCallback((video) => {
		if (video && video.readyState >= 3) {
			video.play();
		} else {
			video.addEventListener('canplay', () => video.play(), { once: true });
		}
	}, []);

	useEffect(() => {
		videoRefs.current.forEach((video, index) => {
			if (video) {
				if (index === currentIndex) {
					playVideo(video);
				} else {
					video.pause();
					video.currentTime = 0; // Reset video to start
				}
			}
		});
	}, [currentIndex, playVideo]);

	const nextSlide = () => {
		if (currentIndex < slides.length - itemsToShow) {
			setCurrentIndex(currentIndex + 1);
		} else {
			setCurrentIndex(0); // Loop back to the first slide
		}
	};

	const prevSlide = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		}
	};

	const goToSlide = (index) => {
		setCurrentIndex(index);
	};

	const slideStyle = {
		width: `${100 / itemsToShow}%`,
	};

	const trackStyle = {
		width: `${(slides.length * 100) / itemsToShow}%`,
		transform: `translateX(-${(currentIndex * 100) / slides.length}%)`,
	};

	const showMoreIndicator = slides.length > itemsToShow;

	return (
		<div className="carousel">
			<div className="carousel-track-wrapper position-relative">
				{/* <div className="video_head_text">
					<p>Talent Discovery made</p>
					<h2>
						<span className="easy">Easy</span>
						<span className="yzae"> y zae</span>
					</h2>
				</div> */}

				<div className="carousel-slides gap-4" style={trackStyle}>
					{slides.map((slide, index) => (
						<div className="carousel-slide" style={slideStyle} key={index}>
							<div className="d-flex w-100 justify-content-between align-items-center step_con gap-5">
								<div className="video_steps">
									<div className="video_head_text">
										<p>Talent Discovery made</p>
										<h2>
											<span className="easy">Easy</span>
											<span className="yzae"> y zae</span>
										</h2>
									</div>
									<h2>{slide.step}</h2>
									<p>{slide.stepText}</p>
								</div>
								<div className="video_con">
									<video ref={(el) => (videoRefs.current[index] = el)} className="carousel-video" muted onEnded={nextSlide}>
										<source src={slide.src} type={slide.type} />
										Your browser does not support the video tag.
									</video>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="d-flex gap-3 carousel-buttons mt-lg-0 mt-5 justify-content-center">
					<button onClick={prevSlide} className={`carousel-button left ${showButton ? 'd-block' : 'd-none'}`} disabled={currentIndex === 0}>
						<svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.13201 13.5145L0.22478 7.76187C-0.0749256 7.47 -0.0749256 6.9968 0.22478 6.70494L6.132 0.9523C6.43171 0.660438 6.91763 0.660438 7.21733 0.9523C7.51704 1.24416 7.51704 1.71736 7.21733 2.00922L2.62021 6.48604L21.2326 6.48604C21.6564 6.48604 22 6.82064 22 7.2334C22 7.64615 21.6564 7.98076 21.2326 7.98076L2.62021 7.98076L7.21733 12.4576C7.51704 12.7494 7.51704 13.2226 7.21733 13.5145C6.91763 13.8064 6.43171 13.8064 6.13201 13.5145Z"
								fill="#172D2D"
							/>
						</svg>
					</button>
					<button onClick={nextSlide} className={`carousel-button right ${showButton ? 'd-block' : 'd-none'}`} disabled={currentIndex >= slides.length - itemsToShow}>
						<svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g id="Arrow">
								<path
									id="Arrow_2"
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.868 13.5145L21.7752 7.76187C22.0749 7.47 22.0749 6.9968 21.7752 6.70494L15.868 0.9523C15.5683 0.660438 15.0824 0.660438 14.7827 0.9523C14.483 1.24416 14.483 1.71736 14.7827 2.00922L19.3798 6.48604L0.767443 6.48604C0.343597 6.48604 1.20866e-06 6.82064 1.1365e-06 7.2334C1.06433e-06 7.64615 0.343596 7.98076 0.767443 7.98076L19.3798 7.98076L14.7827 12.4576C14.483 12.7494 14.483 13.2226 14.7827 13.5145C15.0824 13.8064 15.5683 13.8064 15.868 13.5145Z"
									fill="#fff"
								/>
							</g>
						</svg>
					</button>
				</div>
				<div className={`carousel_indicators ${showIndicator ? 'd-flex' : 'd-none'}`}>
					{showMoreIndicator && slides.map((_, index) => <span key={index} className={`carousel-indicator ${currentIndex === index ? 'active' : ''}`} onClick={() => goToSlide(index)}></span>)}
				</div>
			</div>
		</div>
	);
};

export default VideoCarousel;

import { useEffect, useRef } from 'react';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import './css/Onboarding.style.sass';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import ChoosePlan from './section-1/ChoosePlan';
import SectionB from './section-3/Receipt';
import { useSearchParams } from 'react-router-dom';



function PromoteJob() {
  const top = useRef(null);
  const [query] = useSearchParams();


  useEffect(() => {
    if (top.current) {
      top.current.scrollIntoView({ behavior: 'smooth' });
    }
    service.setPageTitle('Promote Job');
  }, []);




  return (
    <div id="Onboarding_Main_Component" ref={top}>
      <Navbar {...navbarComp} />

      <div className="con-margin-height">
        {query.get('section') === '1' && <ChoosePlan />}
        {query.get('section') === '3' && <SectionB />}
      </div>
      <Footer />
    </div>
  );
}

export default PromoteJob;

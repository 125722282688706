import { useEffect, useState } from 'react';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer.jsx';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import './css/JobFlow.style.sass';
import SectionA from './section-a/SectionA';
import SectionB from './section-b/SectionB';
import SectionC from './section-c/SectionC';
import SectionD from './section-d/SectionD';

function JobFlow() {
	service.setPageTitle('Upload Opportunity');

	const [postBody, setPostBody] = useState({
		title: '',
		description: '',
		company_name: '',
		company_link: '',
		// preferred_gender: '',
		required_skills: [{ name: '', competency: '', experience_years: '' }],
		specialty:[],
	});

	const [count, setCount] = useState(0);

	const handlePrevB = () => setCount(0);
	const handlePrevD = () => setCount(1);

	const doHandleA = (data) => {
		setPostBody({ ...postBody, title: data });
		setCount(1);
	};

	const doHandleB = ({ description, required_skills }) => {
		setPostBody({ ...postBody, description, required_skills });
		setCount(2);
	};
	const doHandleD = ({specialty}) => {
		setPostBody({ ...postBody, specialty})
		setCount(3);
	};

	function getCompanyProfile() {
		function successHandler(response) {
			const { company_name, company_website } = response?.data?.result;
			setPostBody({ ...postBody, company_name, company_link: company_website });
		}

		function errorHandler(response) {
			console.error(response);
		}

		api.recruiter.profile.get().then(successHandler).catch(errorHandler);
	}

	// eslint-disable-next-line
	useEffect(getCompanyProfile, []);

	return (
		<div>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				{count === 0 && <SectionA callback={doHandleA} />}
				{count === 1 && <SectionB callback={doHandleB} prev={handlePrevB} />}
				{count === 2 && <SectionD callback={doHandleD} prev={handlePrevD} />}
				{count === 3 && <SectionC prev={handlePrevD} postBody={postBody} />}
			</div>

			<Footer />
		</div>
	);
}

export default JobFlow;

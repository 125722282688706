import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api/index.js';
import './css/SectionA.style.sass';

function SectionA({ callback }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const errorRef = useRef(undefined);
	const page = 1;
	const limit = 10;

	const [talent, setTalent] = useState('');
	const [jobTitles, setJobTitles] = useState([]);
	const [selectedTalent] = useState([]);
	const [throwError, setThrowError] = useState(false);

	useEffect(() => {
		api.recruiter
			.getJobTitles(page, limit)
			.then((res) => setJobTitles(res.data?.result))
			.catch((res) => console.log('main', res));
	}, [page, limit]);

	const handleTalentValue = ({ target: { value } }) => {
		if (!selectedTalent.length) {
			setThrowError(false);
			setTalent(value);
		}
	};

	function handleSuggestion(value) {
		setTalent(value);
	}

	const doSubmit = () => {
		if (!selectedTalent.length && !talent) {
			setThrowError(true);
			service.goTo(errorRef);
		} else {
			let body = selectedTalent[0]?.title || talent;
			callback(body);
		}
	};

	return (
		<div id="JobFlow_SectionA_Main_Container" ref={top}>
			<div className="section">
				<div className="con-header" style={{ background: 'unset', padding: '55px 0' }}>
					<div className="container-01">
						<div className="header">
							<h2>Let's get you your Talent!</h2>
							<p className="mt-3">No need to hassle over the best for the job. We can get it for you. Right here, Right Now.</p>
						</div>
					</div>
				</div>

				<div className="con-context container-01 py-5" ref={errorRef}>
					<div>
						<div className="context">
							<h3 className="form_text">Get your Desired Talents come to you</h3>
							<div className="title mt-4">
								<h2 style={{ color: `${throwError ? 'red' : ''}` }}>Select Job Title</h2>
							</div>
							<div className="px-2 my-4">
								<div className="con-talent  row ">
									{jobTitles.map(({ title }, idx) => {
										return (
											<div className="title_tag px-2 py-2 gap-3 text-center " style={{ borderColor: '#BD5A12', color: '#BD5A12', cursor: 'pointer', fontWeight: 700 }} key={idx} onClick={() => handleSuggestion(title)}>
												<span>{title}</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="con-search">
							<p className="title">Skills not found, enter preference</p>
							<div className="con-input">
								<input type="text" placeholder="Add Skill" value={talent} onChange={handleTalentValue} disabled={!!selectedTalent.length} />
							</div>
						</div>

						<div>
							<Button
								text={
									<>
										<h1>Next</h1>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
								onClick={doSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionA;

import { useState } from "react";
import classNames from "utils/conditional-css-classes/classNames";
import s from "./css/Selector.module.sass";

function Selector({ context, isActive, callback }) {
  const [current, setCurrent] = useState(false);
  const runCallback = () => {
    if (!isActive) setCurrent(!current);
    callback();
  };
  return (
    <div
      className={`${s.selector} ${classNames(
        isActive && s.active,
        current && s.active
      )} mb-3 mb-lg-0`}
      onClick={() => runCallback()}
    >
      <h2>{context || `selector component`}</h2>
      {isActive && <i className="fa-solid fa-xmark"></i>}
      {current && <i className="fa-solid fa-xmark"></i>}
    </div>
  );
}

Selector.defaultProps = {
  callback: () => {},
};

export default Selector;

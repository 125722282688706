import React from 'react';
import classNames from 'utils/conditional-css-classes/classNames'; // Make sure to import the classNames utility
import style from './css/IndexPagination.module.sass'; // Make sure to adjust the import path

function IndexPagination({ pageCount, currentPage, onPageChange }) {
	const pageNumbers = Array.from({ length: pageCount }, (_, i) => i + 1);

	return (
		<div className={style.container}>
			<div className={`${style['arrow-btn']} ${style.prev} ${currentPage === 1 && style.disabled}`} onClick={() => onPageChange(currentPage - 1)}>
				<i className="fa-solid fa-arrow-left"></i>
			</div>

			<div className={style.index}>
				{pageNumbers.map((pageNumber) => (
					<span key={pageNumber} onClick={() => onPageChange(pageNumber)} className={classNames(pageNumber === currentPage && style.active)}>
						{pageNumber}
					</span>
				))}
			</div>

			<div className={`${style['arrow-btn']} ${style.next} ${currentPage === pageCount && style.disabled}`} onClick={() => onPageChange(currentPage + 1)}>
				<i className="fa-solid fa-arrow-right"></i>
			</div>
		</div>
	);
}

export default IndexPagination;

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'services/api';
// import service from 'services';
import style from './css/navbar-comp.module.sass';
const { default: Button } = require("components/buttons/Button");
const { doHandleLogout } = require("views/dashboard/logout/Logout");

function NavCenterContent() {

	return (
		<ul className={`${style['con-links']} d-flex align-items-center gap-4`}>
			<Link to="/dashboard">
				<li>Overview</li>
			</Link>

			<li className={style.dropdown}>
				<Link to="/dashboard/jobs/applied">
					Applied Jobs
				</Link>
			</li>

			<li className={style.dropdown}>
				<Link to="/dashboard/conversations">
					Conversations
				</Link>
			</li>

			<Link to="/dashboard/jobs/posted-jobs">
				<li>Posted Jobs</li>
			</Link>
		</ul>
	);
}

function NavRightContent() {
	const [profileImage, setProfileImage] = useState({});
	useEffect(() => {
		api.recruiter.profile.get().then(res => setProfileImage(res.data.result?.profile_picture)).catch(res => console.log(res))
	}, [])
	// const userType = service.getUserType();

	return (
		<div className="d-flex align-items-center gap-3">
			<div className="">
				<Link to="/dashboard/jobs/create">
					<p className='fw-bold' style={{ color: '#CC5600' }}>Create Jobs</p>
				</Link>
			</div>
			<div className="con-profile">
				<div className={style['con-user-img']}>
					<Link to="/dashboard/profile">
						<div className={style.bg}>
							<div className={style.img}>
								<img src={profileImage?.href_link || '/img/pic.jpg.png'} alt="" />
							</div>
						</div>
					</Link>
				</div>
			</div>

			{/* <div className={style['con-notify']}>
				<i className="fa-solid fa-bell"></i>
			</div> */}

			<div className="d-flex align-items-center gap-3">
				<Button text={<h2>Logout</h2>} small redirect="/login" onClick={doHandleLogout} />
			</div>
		</div>
	);
}

function NavSideContent() {
	const navigate = useNavigate();
	const logout = () => {
		doHandleLogout();
		navigate('/login');
	};
	return (
		<>
			<ul id="con-navbar-sidebar">
				<Link to="/dashboard">
					<li>Overview</li>
				</Link>

				<li className={style.dropdown}>
					<Link to="/dashboard/jobs/applied">
						Applied Jobs
					</Link>
				</li>
				<li className={style.dropdown}>
					<Link to="/dashboard/conversations">
						Conversations
					</Link>
				</li>

				<Link to="/dashboard/jobs/create">
					<li>Create Jobs</li>
				</Link>
				<Link to="/dashboard/jobs/posted-jobs">
					<li>Posted Jobs</li>
				</Link>
				<li onClick={logout}>Logout</li>
			</ul>
		</>
	);
}

export const navbarComp = {
	centerContent: <NavCenterContent />,
	rightContent: <NavRightContent />,
	sidebarContent: <NavSideContent />,
};

// export const navbarComp = {
//   rightContent: (
//     <div className="d-flex align-items-center gap-3">
//       <div className="">
// 				<Link to="/dashboard/projects/create">
// 					<p className='fw-bold'>Create Project</p>
// 				</Link>
// 			</div>
// 			<div className="con-profile">
// 				<div className={style['con-user-img']}>
// 					<Link to="/dashboard/profile">
// 						<div className={style.bg}>
// 							<div className={style.img}>
// 								<img src={profileImage?.href_link || '/img/pic.jpg.png'} alt="" />
// 							</div>
// 						</div>
// 					</Link>
// 				</div>
// 			</div>
//       <Button
//         text={<h2>Logout</h2>}
//         small
//         redirect="/login"
//         onClick={doHandleLogout}
//       />
//       <div className="con-notify" style={style["con-notify"]}>
//         <i className="fa-solid fa-bell" style={style.i}></i>
//       </div>
//     </div>
//   ),
//   centerContent:(
//     <ul className={`${style['con-links']} d-flex align-items-center gap-5`}>
// 			<Link to="/dashboard">
// 				<li>Overview</li>
// 			</Link>

// 			<li className={style.dropdown}>
// 				<Link to="/dashboard/jobs/saved-jobs">
// 					Applied Jobs
// 				</Link>
// 			</li>

// 			<Link to="">
// 				<li>Talents</li>
// 			</Link>
// 		</ul>
//   )
// };

import { useRef } from 'react';
import { Link } from 'react-router-dom';
import service from 'services';
import './css/Navbar.style.sass';
import './css/Navbar.css';

import LanguagePicker from 'LanguageChecker';
import Notification from 'components/Notification';

function Navbar({ logo, centerContent, rightContent, sidebarContent, bgColor }) {
	const navbar = useRef();
	const sidebar = useRef();
	const isOnboardingPage = window.location.href.split('/')[3].startsWith('onboarding');

	const slideIn = () => {
		sidebar.current?.classList.add('active');
		sidebar.current?.classList.remove('slide-out');
	};

	const fadeNavbar = () => {
		if (window.pageYOffset > 100) {
			navbar.current?.classList.add('fade');
		} else navbar.current?.classList.remove('fade');
	};

	window.addEventListener('scroll', fadeNavbar);

	return (
		<div id="Navbar_Main_Container">
			<div className="con-navbar" style={{ background: bgColor || `#FFFBF2` }} ref={navbar}>
				<div className="container-01">
					<div className="navbar d-flex align-items-center justify-content-between">
						<Link to={`${service.getTokensLocalStorage() !== null && isOnboardingPage ? `/onboarding?section=1` : service.getTokensLocalStorage() !== null ? `/dashboard` : `/`}`}>
							<div className="con-left">
								<img id="logo" src={'/img/svg/frontters.svg' || logo} alt="Talents" style={{ width: '150px' }} />
							</div>
						</Link>

						<div className="con-center d-none d-xl-block ">
							<div className="center">{centerContent || ``}</div>
						</div>

						<div className="con-menu " style={{ display: 'flex', gap: '10px' }}>
							<div className="d-flex gap-3 align-items-center">
								<div className="con-right d-none d-lg-block ">{rightContent || ``}</div>

								<LanguagePicker />
								{service.getTokensLocalStorage() !== null && <Notification />}
							</div>
							<div className="menu d-xl-none" onClick={slideIn}>
								<i className="fa-solid fa-bars"></i>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="con-sidebar d-xl-none">
				<div className="sidebar" ref={sidebar}>
					{sidebarContent || `define side bar component`}
				</div>
			</div>
		</div>
	);
}

export default Navbar;

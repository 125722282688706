import { Link, useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import '../homepage/css/Homepage.style.sass';
import './css/Aboutus.sass';
import Button from 'components/buttons/Button';
import { useState } from 'react';

function AboutUs() {
	service.setPageTitle('About Us');
	const navigate = useNavigate();
	const [contactus, setContactUs] = useState({
		fullname: '',
		subject: '',
		description: '',
		email: '',
		phone: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setContactUs((prevContactUs) => ({
			...prevContactUs,
			[name]: value,
		}));
	};

	const handleSubmit = () => {
		const isEmptyField = Object.values(contactus).some((value) => value.trim() === '');

		if (isEmptyField) {
			alert('Please fill out all fields.');
			return;
		}
		api.contactUs
			.contact(contactus)
			.then()
			.catch((err) => console.error(err));
	};

	return (
		<div>
			<Navbar
				bgColor={'white'}
				centerContent={
					<ul className="d-flex align-items-center justify-content-between gap-4">
						<li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
							Discover Talents
						</li>
						<li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
							Discover Opportunities
						</li>
						<li>
							<Link to="/aboutus">About {service.brand.name}</Link>
						</li>
					</ul>
				}
				sidebarContent={
					<>
						<ul id="con-navbar-sidebar">
							<li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
								Discover Talents
							</li>
							<li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
								Discover Opportunities
							</li>
							<Link to="">
								<li>About {service.brand.name}s</li>
							</Link>
							<Link to="/register" className="active">
								<li>Register</li>
							</Link>
							<Link to="/login" className="active">
								<li>Login</li>
							</Link>
						</ul>
					</>
				}
				rightContent={
					<div className="d-flex align-items-center justify-content-between">
						<Button small text="Sign up" redirect="/register" />
						<Button small primaryEmptyFill text="Login" redirect="/login" />
					</div>
				}
			/>

			<div className="container-01">
				<div className="d-flex gap-3 flex-column  justify-content-center align-items-center">
					<h1 className="title-header">Who we are</h1>
					<div className="aboutUsImg">
						<img src="img/bottom.png" alt="" className="w-full h-full" />
					</div>
				</div>
				<div className="aboutUs-info my-4">
					<p>Frontters is a global recruitment platform with the singular purpose of bridging work and talents. Your one place for opportunities that enables you to grow and scale at your pace without leaving your space.</p>
					<p>
						At Frontters, we believe in empowering both job seekers and recruiters to connect and have altered the traditional skill search process by introducing a bidding system in a safe space. If you are a freelancer, a creative professional or a business
						owner seeking the perfect match for your project, Frontters provides a user-friendly and transparent environment. We strive to foster collaboration, creativity, and mutual success for all our users.
					</p>
					<p>
						Join our vibrant community today and let us help you find the perfect talents and talent seekers worldwide. Together, we are reshaping the future of recruitment and unleashing the boundless possibilities of bidding to your satisfaction. Welcome to
						Frontters – where talent finds opportunity and opportunity finds talent.
					</p>
				</div>
				<div>
					<h2 className="subTitle">Vision</h2>
					<p>To enable talents access to borderless engagements.</p>
				</div>
			</div>

			<div className=" d-flex justify-content-center my-5 contact-us-con ">
				<form className="form-container2">
					<div className="text-center ">
						<h1 className="my-3">Contact Us</h1>
						<p>If you have any questions and concern, we are here to help. fill in the details on the form below and we are sure to reach out to you.</p>
					</div>

					<div className=" my-3">
						<div className="d-lg-flex justify-content-between  gap-4">
							<div className="control-form">
								<div className="d-flex justify-content-between">
									<label>Full Name</label>
								</div>
								<input type="text" name="fullname" placeholder="Enter Your Full Name" id="fullname" onChange={handleChange} value={contactus.fullname} />
							</div>
							<div className="control-form">
								<div className="d-flex justify-content-between">
									<label>Subject</label>
								</div>
								<input type="text" id="subject" name="subject" placeholder="Enter the subject here" onChange={handleChange} value={contactus.subject} />
							</div>
						</div>
						<div className="d-lg-flex justify-content-between  gap-4">
							<div className="control-form">
								<div className="d-flex justify-content-between">
									<label>Email</label>
								</div>
								<input type="email" name="email" placeholder="Enter Your email here" id="email" onChange={handleChange} value={contactus.email} />
							</div>
							<div className="control-form">
								<div className="d-flex justify-content-between">
									<label>Phone</label>
								</div>
								<input type="tel" id="phone" name="phone" placeholder="Enter phone number" onChange={handleChange} value={contactus.phone} />
							</div>
						</div>
						<div className="">
							<div className="d-flex justify-content-between">
								<label htmlFor="subject" className="pb-3">
									Description
								</label>
							</div>
							<textarea name="description" id="description" style={{ width: '100%' }} rows="9" value={contactus.description} onChange={handleChange} />
						</div>
						<div className="d-flex justify-content-center my-3">
							<Button
								onClick={handleSubmit}
								small
								text={
									<>
										Send
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
							/>
						</div>
					</div>
				</form>
			</div>

			<Footer />
		</div>
	);
}

export default AboutUs;

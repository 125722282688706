import ProgressBar from 'components/progress-bar/ProgressBar';
import React, { useEffect, useRef, useState } from 'react';
import service from 'services';

import './css/SectionG.css';
import api from 'services/api';
import Button from 'components/buttons/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import { useFormContext } from 'ContextApi/FormContext';

const SectionG = () => {
	const [query] = useSearchParams();
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);
	const navigate = useNavigate();
	const { formData, updateFormData } = useFormContext();
	const [talent_bio, setTalent_bio] = useState(formData.sectionGData.talent_bio || '');
	const [isLoading, setIsLoading] = useState(false);
	const userType = service.getUserType();

	function handleOnChange(e) {
		setTalent_bio(e.target.value);
	}
	function hasErrors() {
		console.log('error');
	}

	function handleSubmit() {
		console.log(service.getUserType());
		setIsLoading(true);
		if (!hasErrors()) {
			function successHandler() {
				updateFormData('sectionGData', { talent_bio });
				const lastPoint = query.get('section');
				localStorage.setItem('lastOnboardingPoint', lastPoint);
				navigate({ search: `?section=7` });
			}
			function errorHandler(response) {
				console.error(response);
			}
			function finalHandler() {
				setIsLoading(false);
			}

			const data = { talent_bio };

			if (userType === service.FREELANCER) {
				api.freelancers.profile.update(data).then(successHandler).catch(errorHandler).finally(finalHandler);
			} else {
				api.talents.profile.update(data).then(successHandler).catch(errorHandler).finally(finalHandler);
			}
		}
	}
	return (
		<div id="brand-section" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-5" style={{ padding: '0px' }}>
					<div className="header row justify-content-between py-3 align-items-center">
						<h2 className="display col-lg-6">Now, tell us about yourself</h2>
						<div className="col-lg-5">
							<ProgressBar percent="55" />
						</div>
						<p className="my-3 col-12 text_intro">
							Write a bio about yourself, let people know you at a glance
							<i className="img"></i>
						</p>
					</div>

					<form>
						<div className="d-lg-flex justify-content-between gap-4">
							<div className="control-form">
								<div className="d-flex flex-column ">
									<label>Bio</label>

									{/* <label className="error-msg mb-3 col-lg-6">{formErrors.talent_bio}</label> */}
								</div>

								<textarea
									style={{
										height: '200px',
										width: '100%',
									}}
									placeholder="Enter your bio"
									name="talent_bio"
									value={talent_bio}
									onChange={handleOnChange}></textarea>
								<div className="d-flex justify-content-end">{100 - talent_bio.length >= 0 && <p className="vital_info">{100 - talent_bio.length} characters left</p>}</div>
							</div>
						</div>
						<div className="control-form d-lg-flex gap-3">
							<div className="mb-5 mb-lg-0">
								<Button
									text={
										<>
											<i className="fa-solid fa-arrow-left"></i>
											<h1>One Step back</h1>
										</>
									}
									primaryNull
									onClick={() => navigate(-1)}
								/>
							</div>

							<div className="mb-4 mb-lg-0">
								<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading || talent_bio.length < 100} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SectionG;

import React from "react";
import service from "services";
import Talent from "../talent/Talent";

function Freelancer() {
  service.setPageTitle("Freelancer Dashboard");

  return (
    <div id="Dashboard_Freelancer_Main_Container">
      <Talent />
    </div>
  );
}

export default Freelancer;

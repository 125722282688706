import Button from 'components/buttons/Button';
import './css/GeneralModal.css'
import React from 'react';

const SuccessModal = ({ text, handleClose, modalTitle, modalWidth, modalMessage }) => {
	return (

		<div className="s_modal   modal d-block">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: modalWidth || `500px` }}>
				<div className="modal_content modal-content">
					<div className="modal-header" style={{ padding: '7px' }}>
						<i className="fa-regular fa-user"></i>
						<h5 className={`modal-title`} style={{ fontSize: '10px' }}>{modalTitle || `modal title`}</h5>
						<i className="fa-solid fa-xmark" data-bs-dismiss="modal" onClick={handleClose}></i>
					</div>

					<div className=" body py-2">
						<div className="con-main d-flex flex-column align-items-center gap-4 p">
							<h2 className="title">{text}</h2>
							<div className="img">
								<img src="/img/happy 1.png" alt="" />
							</div>
							<p className='text-center'>{modalMessage}</p>
							<div className="footer d-flex flex-column flex-lg-row gap-3 align-items-center justify-content-center gap-3">
								<div onClick={handleClose}>
									<Button text="Close" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default SuccessModal;

import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';
import service from 'services';
import style from './css/UpdateProfileModal.module.sass';
import { Country, State } from 'country-state-city';

function UpdateProfileModal({ context, callback }) {
	const [form, setForm] = useState({
		first_name: '',
		last_name: '',
		title: '',
		talent_bio: '',
		location: {
			resident_country: '',
			resident_state: '',
		},
		gender: ''
	});
	// eslint-disable-next-line no-unused-vars
	const [countries, setCountries] = useState(Country.getAllCountries());
	const [statesOfSelectedCountry, setStatesOfSelectedCountry] = useState([]);

	function handleOnChange(e) {
		const name = e.target.name;
		const value = e.target.value;
		setForm((prev) => ({ ...prev, [name]: value }));
	}

	// function handleOnChangeNest(e) {
	// 	const name = e.target.name;
	// 	const value = e.target.value;
	// 	setForm((prev) => ({ ...prev, [name.split('.')[0]]: { ...prev[name.split('.')[0]], [name.split('.')[1]]: value } }));
	// }

	function configFields({ first_name, last_name, title, location: loc, talent_bio, gender }) {
		const location = service.omit(loc, '_id');
		return { first_name, last_name, title, location, talent_bio, gender };
	}

	function getUser() {
		setForm({ ...form, ...context });
	}

	// eslint-disable-next-line
	useEffect(getUser, [context]);

	function doSubmit() {
		callback(configFields(form));
	}

	const handleCountryChange = (event) => {
		const selectedCountryName = event.target.value;
		const selectedCountry = countries.find((country) => country.name === selectedCountryName);

		if (selectedCountry) {
			setForm((prevForm) => ({
				...prevForm,
				location: {
					...prevForm.location,
					resident_country: selectedCountry.name,
					resident_state: '' // Reset the state when changing the country
				}
			}));

			// Update the states based on the selected country
			const states = State.getStatesOfCountry(selectedCountry.isoCode);
			setStatesOfSelectedCountry(states);
		} else {
			setForm((prevForm) => ({
				...prevForm,
				location: {
					resident_country: '',
					resident_state: '',
				}
			}));
			setStatesOfSelectedCountry([]);
		}
	};


	const handleStateChange = (event) => {
		const selectedStateName = event.target.value;
		const selectedState = statesOfSelectedCountry.find((state) => state.name === selectedStateName);

		if (selectedState) {
			setForm({
				...form, location: {
					...form.location,
					resident_state: selectedState.name
				}
			});
		} else {
			setForm({ ...form, state: 'No state' });
		}
	};

	return (
		<div className={`${style[`con-main`]}`}>
			<form>
				<div className="d-flex align-items-lg-center flex-column flex-lg-row gap-lg-3 mb-3">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>First Name</label>
						</div>

						<input type="text" placeholder="Enter first name" name="first_name" value={form?.first_name} onChange={handleOnChange} />
					</div>
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Last Name</label>
						</div>

						<input type="text" placeholder="Enter last name" name="last_name" value={form?.last_name} onChange={handleOnChange} />
					</div>
				</div>
				<div className="d-flex align-items-lg-center flex-column flex-lg-row gap-lg-3 mb-3">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Country of Residence</label>
						</div>

						<select name="resident_country" value={form?.location.resident_country} onChange={handleCountryChange}>
							<option value="">Enter country of residence</option>
							{countries.map((country) => {
								return (
									<option key={country.name} value={country.name}>
										{country.name}
									</option>
								);
							})}
						</select>
					</div>

					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>State</label>
						</div>

						<select name="resident_state" value={form?.location.resident_state} onChange={handleStateChange}>
							<option value="">Enter State</option>
							{statesOfSelectedCountry.length > 0 ? (statesOfSelectedCountry.map((state) => (
								<option key={state.isoCode} value={state.name}>
									{state.name}
								</option>
							))) : <option key={'no_state'} value="No State">No State</option>}
						</select>
					</div>
				</div>

				<div className="d-flex align-items-lg-start flex-column flex-lg-row gap-lg-3 mb-3">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Designation</label>
						</div>

						<input type="text" placeholder="Enter designation" name="title" value={form.title} onChange={handleOnChange} />
					</div>
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Gender</label>
						</div>

						<select name="gender" value={form?.gender} onChange={handleOnChange}>
							<option value="">Select Gender</option>
							{(['male', 'female', 'any'].map((x, idx) => (
								<option key={idx} value={x}>
									{x}
								</option>
							)))}
						</select>
					</div>
				</div>

				<div className="d-flex align-items-lg-start flex-column flex-lg-row gap-lg-3 mb-3">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Bio</label>
						</div>

						<textarea placeholder="Enter bio" name="talent_bio" value={form.talent_bio} onChange={handleOnChange} rows={5}></textarea>
					</div>
				</div>

				<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
					<div data-bs-dismiss="modal">{<Button text={<h2>Back</h2>} primaryNull />}</div>
					<div data-bs-dismiss="modal">
						<Button
							text={
								<>
									<i className="fa-regular fa-floppy-disk"></i>
									<h2>Save</h2>
								</>
							}
							onClick={doSubmit}
						/>
					</div>
				</div>
			</form>
		</div>
	);
}

export default UpdateProfileModal;

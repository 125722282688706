import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import { jobCategories } from 'constant/JobCategories';
import React, { useEffect, useRef, useState } from 'react';
import service from 'services';

const SectionD = ({prev,callback}) => {
	const top = useRef(undefined);

	useEffect(() => service.goTo(top), []);
	const [isLoading, setIsLoading] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [specialty, setSpecialty] = useState([]);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	const handleCheckboxChange = (subCategory) => {
		if (specialty.includes(subCategory)) {
			setSpecialty(specialty.filter((item) => item !== subCategory));
		} else if (specialty.length < 3) {
			setSpecialty([...specialty, subCategory]);
		}
	};
	const handleSubmit = () => {
		setIsLoading(true)
		callback({specialty:specialty})
	};

	return (
		<div ref={top}>
			<div className="section">
				<div className="container-01 py-4">
					<div className="con-title  ">
						<h2 className="head_text_specialization" >
							Select the required <br className="d-lg-block d-none" /> specialization
						</h2>
						<p className="title_text my-4"> Now select at most, 3 specializations that applies to the role. </p>
					</div>
				</div>
				<div className="con-form container-01  form_con_body">
					<div className="row px-4 py-4">
						<div className="col-lg-3">
							<h3 className="job_categories_header my-3">Select a category</h3>
							<div className=" px-3 py-2">
								{jobCategories.map((categories, index) => {
									return (
										<div key={index} className="my-3">
											<button className={` job_list  ${index === activeTab ? 'activeJob' : ''}`} onClick={() => handleTabClick(index)}>
												{categories.category}
											</button>
										</div>
									);
								})}
							</div>
						</div>
						<div className="col-lg-4">
							<h3 className="job_categories_header my-3">Pick at most, 3 of your specialities</h3>
							<div className="job_subCategories px-3 py-2">
								{jobCategories[activeTab].subcategories.map((subCategory, subIndex) => {
									return (
										<div key={subIndex} className="my-3">
											<label className="check_field-checkbox-label">
												<input type="checkbox" className="check_field-checkbox" checked={specialty.includes(subCategory)} onChange={() => handleCheckboxChange(subCategory)} />
												{subCategory}
											</label>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="control-form d-lg-flex gap-3 mt-5 px-4 py-4">
						<div className="mb-5 mb-lg-0">
							<Button
								text={
									<>
										<i className="fa-solid fa-arrow-left"></i>
										<h1>One Step back</h1>
									</>
								}
								primaryNull
								onClick={prev}
							/>
						</div>

						<div className="mb-4 mb-lg-0">
							<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionD;

import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import service from 'services';
import { auth } from 'services/api/auth';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import Previous from '../../onboarding/talent/previous/Previous';
import Context from '../context/Context';
import './css/VerifyAccount.style.sass';

function VerifyAccount() {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);
	service.setPageTitle('Verify Account');
	const { sidebarContext } = Context();

	const [query] = useSearchParams();

	const [successMsg, setSuccessMsg] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [emailSent, setEmailSent] = useState(false);

	const getTokenData = () => query.get('token') && JSON.parse(atob(query.get('token').split('.')[1]));

	function verifyAccount() {
		function successHandler(response) {
			const isSuccess = response?.data?.success;
			const statusCode = response?.status;
			setIsLoading(false);

			if (statusCode === 200 && isSuccess) {
				setSuccessMsg(true);
			} else {
				setSuccessMsg(false);
			}
		}

		function errorHandler(err) {
			setErrorMsg(true);
			setIsLoading(false);
		}

		if (query.get('token') !== null) {
			setIsLoading(true);
			auth
				.verifyAccount({ token: query.get('token') })
				.then(successHandler)
				.catch(errorHandler);
		}
	}

	useEffect(verifyAccount, [query]);

	function reverifyAccount() {
		if (query.get('recipient') !== null) {
			const payload = atob(query.get('recipient'));
			const postBody = { email: payload.split(',')[0], type: service.titleCase(payload.split(',')[1]) };
			setIsLoading(true);

			function successHandler(response) {
				console.log('success response >>', response);
				setEmailSent(true);
				setTimeout(() => {
					setEmailSent(false);
					setIsLoading(false);
				}, service.errorMilliSecond);
			}

			function errorHandler(response) {
				console.log('error response >>', response);
				setIsLoading(false);
				setEmailSent(false);
			}

			auth.reverifyAccount(postBody).then(successHandler).catch(errorHandler);
		}
	}

	return (
		<div id="Verify_Account_Main_Container">
			<div ref={top}>
				<Navbar
					rightContent={
						<div className="d-flex align-items-center justify-content-between">
							<Button small emptyFill text="Home" redirect="/" />
							<Button small text="Sign up" redirect="/register" />
							<Button small primaryEmptyFill text="Login" redirect="/login" />
						</div>
					}
					sidebarContent={sidebarContext}
				/>

				<div className="con-margin-height container-01">
					<div className="con-context">
						<div className="con-header">
							<h2>{service.brand.name} is all about you</h2>
						</div>

						<Previous redirect="/register" text={`Back to ${service.brand.name}`} />

						<div className="con-body outline-wrap my-5 bg-white">
							<div className="d-flex flex-column align-items-center gap-4">
								{query.get('token') === null && !emailSent && <h2>Verify Account</h2>}
								{!errorMsg && !successMsg && (query.get('token') !== null || emailSent) && <h2>Verification sent</h2>}
								{!errorMsg && successMsg && <h2>Email Verified</h2>}
								{errorMsg && !successMsg && <h2>Oops!</h2>}

								{!errorMsg && !successMsg && <p>You will be automatically redirected when you click on the link sent to your email</p>}
								{!errorMsg && successMsg && <p>Congratulations, you are now a member of {service.brand.name}</p>}
								{errorMsg && !successMsg && <p>The verification link has expired. Click on resend verification link to request for another trial.</p>}

								<div className="con-btn d-flex flex-column flex-lg-row align-items-center justify-content-center gap-3">
									{query.get('token') !== null && <div>{!successMsg && <Button primaryNull text="Change Email Address" redirect={errorMsg && `/register?tab=${getTokenData()?.userType?.toLowerCase()}`} disabled={isLoading} />}</div>}

									<div>
										<Button
											text={
												<>
													{!errorMsg && !successMsg && `Resend Verification Link`}
													{!errorMsg && successMsg && `Login to continue`}
													{errorMsg && !successMsg && `Resend Verification Link`}
												</>
											}
											redirect={!errorMsg && successMsg && `/login`}
											onClick={reverifyAccount}
											disabled={isLoading}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default VerifyAccount;

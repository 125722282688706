import Button from 'components/buttons/Button';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import service from 'services';
import './css/SectionE.style.sass';
import './css/SectionE.css';
import api from 'services/api';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';

function SectionE() {
	const navigate = useNavigate();
	const [query] = useSearchParams();
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [isAvailable, setIsAvailable] = useState(''); // Active job status
	const [full_time_decision, setFullTimeDecision] = useState(''); // Full-time/Part-time status
	const [notice_period, setNoticePeriod] = useState(0); // Notice period
	const [traffic_source, setTrafficSource] = useState(''); // How they heard about the company (checkbox group)
	const [errors, setErrors] = useState({}); // Form validation errors
	const [loading, setLoading] = useState(false); // Loading state

	const handleValueRadioChange = (value) => {
		const normalizeValue = value === 'ready' || value === 'open' ? true : false;
		setIsAvailable(normalizeValue);
		setErrors({ ...errors, isAvailable: '' });
	};
	const handlePeriodChange = (value) => {
		setFullTimeDecision(value);
		setErrors({ ...errors, full_time_decision: '' });
	};
	const handleNoticePeriodChange = (e) => {
		const value = parseInt(e.target.value, 10);

		if (isNaN(value) || value < 1) {
			setErrors({ ...errors, notice_period: 'Please provide a valid notice period.' });
		} else {
			setNoticePeriod(value);
			setErrors({ ...errors, notice_period: '' });
		}
	};

	const handleTrafficSourceChange = (value) => {
		setTrafficSource(value);
		setErrors({ ...errors, traffic_source: '' });
	};

	const validateForm = () => {
		let formErrors = {};
		if (!isAvailable) formErrors.isAvailable = 'Please select your job status';
		if (!full_time_decision) formErrors.full_time_decision = 'Please select your work availability';
		if (notice_period === null || notice_period < 0) {
			formErrors.notice_period = 'Please provide your notice period.';
		}
		if (!traffic_source) formErrors.traffic_source = 'Please select how you heard about us';
		return formErrors;
	};

	const handleSubmit = () => {
		const successHandler = () => {
			const lastPoint = query.get('section');
			localStorage.setItem('lastOnboardingPoint', lastPoint);
			navigate({ search: '?section=12' });
		};

		const errorHandler = () => {
			setErrors({ submit: 'An error occurred during submission. Please try again.' });
		};

		const finalHandler = () => {
			setLoading(false);
		};
		const formErrors = validateForm();
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
			return;
		}

		setLoading(true);

		const data = {
			isAvailable,
			full_time_decision,
			notice_period,
			traffic_source,
		};
		api.talents.profile.update(data).then(successHandler).catch(errorHandler).finally(finalHandler);
	};

	return (
		<div id="Onboarding_Talent_SectionE_Main_Component" ref={top}>
			<div className="con-header">
				<div className="container-01 " style={{ padding: 0 }}>
					<div className="header d-lg-flex align-items-center">
						<div className="col-12 col-lg-7">
							<h2 className="display">Let's get to know you better!</h2>

							<p>Let's dig a little deeper!</p>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="90" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01" style={{ padding: 0 }}>
				<div className="con-form" style={{ boxShadow: 'unset' }}>
					<div className="form">
						<form>
							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
									<img src="/img/svg/officebox.svg" alt="suitcase" className="svg d-none d-lg-block" />
									<h2>Are you actively looking looking for a job?</h2>
								</div>

								<div class="e_radio-input">
									<label class="label">
										<div>
											<input type="radio" id="value-4" name="job-offer" value="ready" checked={isAvailable === true && isAvailable !== 'unavailable'} onChange={() => handleValueRadioChange('ready')} />
										</div>
										<div className="context">
											<div className="d-flex align-items-center gap-3 mb-3">
												<div className="con-icon d-none d-lg-block">
													<img src="/img/svg/briefcasecut.svg" alt="Seeking Job" className="svg" style={{ width: '26px', height: '25px' }} />
													<div className="dot-bg valid"></div>
												</div>
												<h2>Ready to Interview</h2>
											</div>
											<div>
												<p>I'm available for a job and available for an interview</p>
											</div>
										</div>
									</label>
									<label class="label">
										<div>
											<input type="radio" id="value-2" name="job-offer" value="open" checked={isAvailable === true && isAvailable !== 'unavailable'} onChange={() => handleValueRadioChange('open')} />
										</div>
										<div className="context">
											<div className="d-flex align-items-center gap-3 mb-3">
												<div className="con-icon d-none d-lg-block">
													<img src="/img/svg/briefcasecut.svg" alt="Seeking Job" className="svg" style={{ width: '26px', height: '25px' }} />
													<div className="dot-bg warn"></div>
												</div>
												<h2>Open to Offers</h2>
											</div>
											<div>
												<p>I currently have a job and available for an interview</p>
											</div>
										</div>
									</label>
									<label class="label">
										<div>
											<input type="radio" id="value-3" name="job-offer" value="unavailable" checked={isAvailable === false} onChange={() => handleValueRadioChange('unavailable')} />
										</div>
										<div className="context">
											<div className="d-flex align-items-center gap-3 mb-3">
												<div className="con-icon d-none d-lg-block">
													<img src="/img/svg/briefcasecut.svg" alt="Seeking Job" className="svg" style={{ width: '26px', height: '25px' }} />
													<div className="dot-bg"></div>
												</div>
												<h2>Unavailable for Jobs</h2>
											</div>
											<div>
												<p>I'm Unavailable for a job </p>
											</div>
										</div>
									</label>
								</div>
								{errors.isAvailable && <p className="text-error mt-2">{errors.isAvailable}</p>}
							</div>

							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
									<i className="fa-solid fa-user-check"></i>
									<h2>Are you interested in working full time (8hrs/day, 40 hrs/week)?</h2>
								</div>

								<div class="e_radio-input period_input">
									<label class="label">
										<div>
											<input type="radio" id="value-4" name="contract" value="full-time" checked={full_time_decision === 'full-time'} onChange={() => handlePeriodChange('full-time')} />
										</div>
										<div className="context">
											<div>
												<p>Yes</p>
											</div>
										</div>
									</label>
									<label class="label">
										<div>
											<input type="radio" id="value-2" name="contract" value="part-time" checked={full_time_decision === 'part-time'} onChange={() => handlePeriodChange('part-time')} />
										</div>
										<div className="context">
											<div>
												<p>No, only part-time</p>
											</div>
										</div>
									</label>
									<label class="label">
										<div>
											<input type="radio" id="value-3" name="contract" value="part-time" checked={full_time_decision === 'contract'} onChange={() => handlePeriodChange('contract')} />
										</div>
										<div className="context">
											<div>
												<p>I can start part-time immediately and then switch to full-time within a month</p>
											</div>
										</div>
									</label>
								</div>
								{errors.full_time_decision && <p className="text-error mt-2">{errors.full_time_decision}</p>}
							</div>

							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
									<i className="fa-solid fa-user-check"></i>
									<h2>What is your notice period for resigning from your current job and starting your new job?</h2>
								</div>

								<div class="e_radio-input period_input">
									<label class="label">
										<div>
											<input type="radio" id="value-4" name="period" checked={notice_period === 0} onChange={() => setNoticePeriod(0)} />
										</div>
										<div className="context">
											<div>
												<p>Immediately</p>
											</div>
										</div>
									</label>
									<label class="label">
										<div>
											<input type="radio" id="value-2" name="period" value="weeks" checked={notice_period > 0} onChange={() => setNoticePeriod(1)} /* Default to 1 week if they choose this option */ />
										</div>
										<div className="context">
											<h2 className="d-flex align-items-center gap-2">
												In
												<input type="number" className="notice_input" placeholder="e.g.: 2" min={1} value={notice_period || ''} onChange={handleNoticePeriodChange} />
												week(s) after I get the offer
											</h2>
										</div>
									</label>
								</div>
								{errors.notice_period && <p className="text-error mt-2">{errors.notice_period}</p>}
							</div>

							<div className="mb-5">
								<div className="con-title d-flex align-items-center gap-3 mb-4">
									<i className="fa-solid fa-network-wired"></i>
									<h2>How did you hear about us?</h2>
								</div>
								<div className="e_radio-input period_input">
									<label className="label">
										<div>
											<input type="radio" id="source-1" name="traffic_source" value="Referral" checked={traffic_source === 'Referral'} onChange={() => handleTrafficSourceChange('Referral')} />
										</div>
										<div className="context">
											<div>
												<p>Referral</p>
											</div>
										</div>
									</label>
									<label className="label">
										<div>
											<input type="radio" id="source-2" name="traffic_source" value="Social Media" checked={traffic_source === 'Social Media'} onChange={() => handleTrafficSourceChange('Social Media')} />
										</div>
										<div className="context">
											<div>
												<p>Social Media</p>
											</div>
										</div>
									</label>
									<label className="label">
										<div>
											<input type="radio" id="source-3" name="traffic_source" value="Job Board" checked={traffic_source === 'Job Board'} onChange={() => handleTrafficSourceChange('Job Board')} />
										</div>
										<div className="context">
											<div>
												<p>Job Board</p>
											</div>
										</div>
									</label>
									<label className="label">
										<div>
											<input type="radio" id="source-4" name="traffic_source" value="Company Website" checked={traffic_source === 'Company Website'} onChange={() => handleTrafficSourceChange('Company Website')} />
										</div>
										<div className="context">
											<div>
												<p>Company Website</p>
											</div>
										</div>
									</label>
								</div>
								<p>{errors.traffic_source && <p className="text-error mt-2">{errors.traffic_source}</p>}</p>
							</div>

							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3" style={{ borderTop: '1px solid #ECECEC', padding: '20px 0' }}>
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>One Step back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button text={<LoaderButton isLoading={loading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionE;

import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import './css/SectionB.style.sass';
import api from 'services/api';

function SectionB({ context, isLoading, prev, callback }) {
	const top = useRef(undefined);
	const targetInputDiv = useRef(null);
	useEffect(() => service.goTo(top), []);

	const [description, setDescription] = useState('');
	const [descriptionError, setDescriptionError] = useState('');
	const [skills, setSkills] = useState([]);
	const [skillsError, setSkillsError] = useState('');
	const [newSkills, setNewSkills] = useState({ name: '', experience_years: '', competency: '' });
	const [suggestedSkills, setSuggestedSkills] = useState([]);

	const [isSubmitting, setIsSubmitting] = useState(false);

	function handleOnChangeDynamic(event) {
		const name = event?.target.name;
		const value = event?.target.value;

		setNewSkills({
			...newSkills,
			[name]: value,
		});
	}

	function handleOnChange(event) {
  const { value } = event.target;
  setDescription(value);
  setDescriptionError('');
}
	function handleSuggestion(value) {
		setNewSkills({ ...skills, name: value });
		targetInputDiv.current.scrollIntoView({ behavior: 'smooth' });
	}
	function addSkills() {
		// Check if all fields are filled
		if (newSkills.name && newSkills.experience_years && newSkills.competency) {
			// Ensure that `newSkills` object contains only relevant fields
			const validSkill = {
				name: newSkills.name,
				experience_years: newSkills.experience_years,
				competency: newSkills.competency,
			};
			// Add validSkill to the skills array
			setSkills([...skills, validSkill]);
			// Clear the input fields after adding
			setNewSkills({ name: '', experience_years: '', competency: '' });
		} else {
			alert('Please fill out the fields before adding a skill');
		}
	}

	function removeSkill(index) {
		const updatedSkills = skills.filter((_, idx) => idx !== index);
		setSkills(updatedSkills);
	}

	function doSubmit() {
  let hasError = false;

  // Validate description
  if (!description.trim()) {
    setDescriptionError('Description is required');
    hasError = true;

    document.getElementById('description')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  } else {
    setDescriptionError('');
  }

  // Validate skills
  if (skills.length === 0) {
    setSkillsError('At least one skill is required');
    hasError = true;

    document.getElementById('name')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  } else {
    setSkillsError('');
  }

  // If there are errors, stop the submission
  if (hasError) {
    setIsSubmitting(false);
    return;
  }

  // Remove competency_text and _id from each skill and proceed with submission
  const skillsWithoutCompetencyTextAndId = skills.map(({ competency_text, _id, ...rest }) => rest);

  const skillNames = skillsWithoutCompetencyTextAndId.map((skill) => skill.name);
  setIsSubmitting(true);

  // Submit the skills and description
  Promise.all(skillNames.map((skillName) => api.admin.skills.createSkill({ name: skillName })))
    .catch(console.warn)
    .finally(() => callback({ required_skills: skillsWithoutCompetencyTextAndId, description }));
}

	function fetchSkills() {
		function successHandler(res) {
			const result = res?.data?.result;
			setSuggestedSkills(result);
		}

		api.admin.skills.getAllSkills().then(successHandler).catch(console.error);
	}

	const uniqueSkills = suggestedSkills
		.map((skill) => ({
			...skill,
			name: skill.name.toLowerCase(),
		}))
		.filter((skill, index, self) => index === self.findIndex((s) => s.name === skill.name));

	useEffect(fetchSkills, [skills]);


	function getJob() {
		setDescription(context?.description);
		setSkills(context?.required_skills);
	}

	useEffect(getJob, [context]);

	return (
		<div id="JobFlow_SectionB_Main_Container" ref={top}>
			<div className="section">
				<div className="container-01">
					<div className="con-title">
						<h1>What do you require for this Opportunity</h1>

						<div className="d-lg-flex align-items-center gap-3">
							<p>It's great to know what is expected, wouldn't you agree</p>
							<div className="img d-none d-lg-block">
								<img src="/img/twemoji_beaming-face-with-smiling-eyes.png" alt="" />
							</div>
						</div>
					</div>

					<div className="con-form">
						<div className="form">
							<form>
								<div>
									<div className="con-title">
										<img src="/img/svg/officebox.svg" alt="case" className="svg" />
										<h2>About Job</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-lg-flex justify-content-between">
												<label>Job Description</label>
												<label className="error-msg col-12 col-lg-7">{descriptionError}</label>
											</div>

											<div className="d-flex">
												<div className="col-12 col-lg-9">
													<textarea value={description} placeholder="Enter Job Description" name="description" onChange={handleOnChange} />
													<p className="con-warn align-items-center gap-3">
														<i className="fa-solid fa-circle-exclamation"></i>
														Not more than 10000 words
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="mb-5" ref={targetInputDiv}>
									<div className="con-title">
										<img src="/img/svg/toolkit.svg" alt="case" className="svg" />
										<p>Add Skills the talent must possess</p>
									</div>

									<div>
										<div className="con-skills mb-5">
											<div className="con-input">
												<div className="d-flex align-items-lg-center flex-column flex-lg-row justify-content-between gap-lg-3">
													<div className="control-form">
														<label>Skill</label>
														<input placeholder={`Add Skill `} name="name" onChange={handleOnChangeDynamic} value={newSkills?.name} />
														{skillsError && <span style={{ color: 'red', fontSize: '12px' }}>{skillsError}</span>}
													</div>

													<div className="control-form">
														<label>Years of Professional Experience</label>
														<input placeholder={'Years of Experience'} type={'number'} name="experience_years" onChange={handleOnChangeDynamic} min={1} value={newSkills?.experience_years} />
													</div>

													<div className="control-form">
														<label>Competency</label>
														<select name="competency" onChange={handleOnChangeDynamic} value={newSkills?.competency}>
															<option value="">Select Competency</option>
															<option value={1}>Beginner</option>
															<option value={2}>Intermediate</option>
															<option value={3}>Advanced</option>
															<option value={4}>Expert</option>
														</select>
													</div>
												</div>

												<Button
													text={
														<>
															<i className="fa-solid fa-plus"></i>
															<h2>Add</h2>
														</>
													}
													onClick={addSkills}
													disabled={skills.length >= 10}
												/>
											</div>

											<div className="your_skills my-4">
												<div className="d-flex justify-content-between align-items-center">
													<h2>Your Skills</h2>
													<h2>Max. 10 skills ({10 - skills.length} remaining)</h2>
												</div>
												<div className="p-4 skills_box my-3 d-flex align-items-center flex-wrap gap-3">
													{skills.length > 0 &&
														skills.map((skill, idx) => (
															<div className="skills_tag px-3 py-2 gap-3">
																<span>{skill?.name}</span> <i class="fa-solid fa-xmark " onClick={() => removeSkill(idx)}></i>
															</div>
														))}
												</div>
											</div>
											<div>
												<h2 className="suggested_skills_header">Suggested Skills</h2>

												<div className="d-flex  align-items-center flex-wrap  gap-3 mt-4">
													{uniqueSkills?.length > 0 &&
														uniqueSkills.map((skill, idx) => (
															<div
																className="skills_tag px-3 py-2 gap-3"
																style={{ borderColor: '#BD5A12', color: '#BD5A12', cursor: 'pointer' }}
																key={skill.id || idx} // Use skill.id if available, otherwise fallback to idx
																onClick={() => handleSuggestion(skill.name)}>
																<span>{skill.name}</span>
															</div>
														))}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="d-flex flex-column flex-lg-row gap-3">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>Back (Your Info)</h1>
											</>
										}
										primaryNull
										onClick={prev}
										disabled={isSubmitting}
									/>

									<Button
										text={
											<>
												<h1>Next</h1>
												<i className="fa-solid fa-arrow-right"></i>
											</>
										}
										disabled={isSubmitting}
										onClick={doSubmit}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionB;

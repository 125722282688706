import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';
import style from '../add-education/css/Modal.module.sass';

function Modal({ callback, education }) {
	const [form, setForm] = useState({
		school: education?.school || '',
		degree: education?.degree || '',
		start_date: education?.start_date || '',
		end_date: education?.end_date || '',
	});

	const [isPresent, setIsPresent] = useState(education?.end_date === 'present');
	// Function to handle the "Present" checkbox click
	function handlePresentCheckbox() {
		setIsPresent(!isPresent);
		setForm((prev) => ({
			...prev,
			end_date: !isPresent ? 'present' : '',
		}));
	}

	function handleOnChange(e) {
		const { name, value } = e.target;
		setForm({ ...form, [name]: value });
	}

	function hasErrors() {
		for (const field in form) {
			if (form[field] === '' || form[field] === null || form[field] === undefined) {
				return true;
			}
		}
		return false;
	}

	// function validationHandler(field, errorMsg = 'field is required') {
	// 	const formElement = document.getElementById('con-form').elements;
	// 	formElement[field]?.focus();
	// 	formElement[field]?.scrollIntoView({ block: 'center' });
	// 	setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
	// }

	// function validations() {
	// 	if (!true) {
	// 		return true;
	// 	}
	// }

	function handleOnSubmit() {
		if (!hasErrors()) {
			const updateForm = { ...form };
			callback(updateForm);
		}
	}

	function exitModal() {
		return {
			doDismissModal: true,
		};
	}

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};
	useEffect(() => {
		if (education) {
			setForm({
				school: education?.school || '',
				degree: education?.degree || '',
				start_date: education?.start_date || '',
				end_date: education?.end_date || '',
			});
			setIsPresent(education.end_date === 'present');
		} else {
			console.warn('Education is undefined in EditExperienceModalBody');
		}
	}, [education]);
	return (
		<div>
			<form id="con-form">
				<div className={style['con-form']}>
					<div className="d-flex align-items-lg-center flex-lg-nowrap flex-wrap gap-lg-3">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>School Name *</label>
							</div>

							<input type="text" placeholder="Enter School Name" name="school" value={form.school} onChange={handleOnChange} className={form.school ? '' : 'error'} />
							{!form.school && <label className="error-msg mb-3">School Name is required</label>}
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Degree *</label>
							</div>

							<select name="degree" value={form.degree} onChange={handleOnChange}>
								<option value="">Select Degree</option>
								<option value="master degree">Master's Degree</option>
								<option value="bachelor degree">Bachelor's Degree</option>
								<option value="first degree">First Degree</option>
								<option value="secondary school leaving certificate">Secondary School Leaving Certificate</option>
							</select>
							{!form.degree && <label className="error-msg mb-3">Degree is required</label>}
						</div>
					</div>

					<div className={`control-form ${style['con-date']}`}>
						<div className="d-flex flex-column flex-lg-row justify-content-start gap-3">
							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>Start Date*</label>
									{!form.start_date && <label className="error-msg mb-3">Start Date is required</label>}
								</div>

								<input type="date" name="start_date" value={form.start_date} onChange={handleOnChange} className={form.start_date ? '' : 'error'} max={getCurrentDate()} />
							</div>

							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>End Date*</label>
									{!form.end_date && <label className="error-msg mb-3">End Date is required</label>}
								</div>

								<input type="date" name="end_date" value={form.end_date} onChange={handleOnChange} className={form.end_date ? '' : 'error'} disabled={isPresent} min={form.start_date} />
							</div>
						</div>
						<div className="d-flex gap-1 align-items-baseline">
							<input type="checkbox" checked={isPresent} onChange={handlePresentCheckbox} style={{ width: '30px' }} />
							<label className="fw-bold">Presently schooling here</label>
						</div>
					</div>

					<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
						<div data-bs-dismiss="modal">{<Button text={<h2>Back</h2>} primaryNull />}</div>

						{!form.school || !form.degree || !form.start_date || !form.end_date ? (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								onClick={handleOnSubmit}
							/>
						) : (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								{...exitModal()}
								onClick={handleOnSubmit}
							/>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}

export default Modal;

import style from "views/jobs/job-details/modals/css/Modal.module.sass";

function UploadViewer({filename, callback}) {
  const date = new Date();
  const formatDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  
  return (<div
      className={`${style.uploader} d-flex align-items-center justify-content-between`}
    >
      <div className={style.context}>
        <h2>{filename || `set file name`}</h2>
        <div className="d-flex align-items-center gap-1">
          <p>Uploaded:</p>
          <p>{formatDate}</p>
        </div>
      </div>
      <div className={style.close} onClick={callback}>
        <div className={style.btn}>
          <i className="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>);
}

export default UploadViewer;
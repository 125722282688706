import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';

const Socials = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const success = searchParams.get('success');
	const access = searchParams.get('access');
	const refresh = searchParams.get('refresh');

	function setTokenToLocalStorage(token) {
		return new Promise((resolve, reject) => {
			try {
				service.setTokensLocalStorage(token);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	async function handleSuccess() {
		if (success === 'true' && access) {
			const newToken = {
				access: {
					token: access,
				},
				refresh: {
					token: refresh,
				},
			};
			try {
				await setTokenToLocalStorage(newToken);
				const userType = service.getUserType();
				setTimeout(() => {
					if (userType === 'freelancer') {
						navigate('/dashboard');
						service.reloadPage();
					} else {
						// navigate(`/onboarding?${service.section}1`);
						service.reloadPage();
					}
				}, 30000);
			} catch (error) {
				console.log(error);
				setTimeout(() => {
					navigate('/login');
				}, 3000);
			}
		} else {
			navigate('/login');
		}
	}

	useEffect(() => {
		handleSuccess();
	});

	return (
		<main>
			<Navbar />
			<h1 className='my-4'>{service.brand.name} is all about you</h1>
			<div className='outline-wrap py-4 d-flex flex-column justify-content-center align-items-center gap-3'>
				<h3>Hold on a little longer!</h3>
				<p>You will be automatically redirected to start your Onboarding process</p>
			</div>
			<Footer />
		</main>
	);
};

export default Socials;

import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';

function EditBio({ context, callback }) {
	const [talent_bio, setTalent_bio] = useState('');

	useEffect(() => {
		// When context changes, update talent_bio
		if (context?.talent_bio) {
			setTalent_bio(context?.talent_bio);
		}
	}, [context]);

	function handleOnChange(e) {
		setTalent_bio(e.target.value);
	}

	function doSubmit() {
		callback({ talent_bio });
	}

	return (
		<div>
			<form className="p-4">
				<div className="d-flex align-items-lg-start flex-column flex-lg-row gap-lg-3 mb-3">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Bio</label>
						</div>

						<textarea placeholder="Enter bio" name="talent_bio" value={talent_bio} onChange={handleOnChange} rows={5}></textarea>
						<div className="d-flex justify-content-end">{100 - talent_bio?.length >= 0 && <p className="vital_info">At least {100 - talent_bio?.length} characters</p>}</div>
					</div>
				</div>

				<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
					<div data-bs-dismiss="modal">{<Button text={<h2>Back</h2>} primaryNull />}</div>
					<div data-bs-dismiss="modal">
						<Button
							text={
								<>
									<i className="fa-regular fa-floppy-disk"></i>
									<h2>Save</h2>
								</>
							}
							onClick={doSubmit}
							disabled={talent_bio?.length < 100}
						/>
					</div>
				</div>
			</form>
		</div>
	);
}

export default EditBio;

import axios from 'axios';
import routes from '../routes';

export const auth = {
	registerRecruiter: async (postBody) => {
		return await axios.post(routes.register('recruiter'), postBody);
	},
	registerTalent: async (postBody) => {
		return await axios.post(routes.register('talent', 'step1'), postBody);
	},
	registerFreelancer: async (postBody) => {
		return await axios.post(routes.register('freelancer'), postBody);
	},
	googleRegister: async (userType) => {
		return await axios.get(routes['google-register'](userType));
	},
	login: async (authBody, userType) => {
		return await axios.post(routes.login(userType), authBody);
	},
	googleLogin: async (authBody, userType) => {
		return await axios.get(routes['google-login'](userType), authBody);
	},
	refreshToken: async (token) => {
		return await axios.post(routes['refresh-token'](), null, { params: { token } });
	},
	resetPassword: async (token,postBody) => {
		return await axios.post(routes['reset-password'](),postBody , { params: { token } });
	},
	parseCv: async (postBody) => {
		return await axios.post(routes['parse-cv'](), postBody);
	},
	verifyAccount: async (postBody) => {
		return await axios.post(routes['verify-account'](), postBody);
	},
	reverifyAccount: async (postBody) => {
		return await axios.post(routes['re-verify-account'](), postBody);
	},

};

import * as Yup from 'yup';


const recruiterAndTalentValidationSchema = Yup.object().shape({
	email: Yup.string().email().required('email is required'),
	password: Yup.string().required('password is required'),
});

const freelancerValidationSchema = Yup.object().shape({
	firstName: Yup.string()
		.matches(/^[a-zA-Z]+$/, 'invalid first name - should not include numbers or spaces')
		.required('first name is required'),
	lastName: Yup.string()
		.matches(/^[a-zA-Z]+$/, 'invalid last name - should not include numbers or spaces')
		.required('last name is required'),
	email: Yup.string().email().required('email is required'),
	password: Yup.string()
		.required('Password is required')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must contain at least one uppercase letter, one lowercase letter, one special character, and be at least 8 characters long'),
	password2: Yup.string()
		.required('Confirm Password is required')
		.oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const sectionCSchema = Yup.object().shape({
	jobDescription: Yup.string().required('job description is required'),
});

const sectionDSchema = Yup.object().shape({
	country: Yup.string().required('country is required'),
	state: Yup.string().required('state is required'),
	yearsOfExperience: Yup.number().max(45, 'exceeds required years of experience').required('years of experience is required'),
	payRange: Yup.number().required('pay range is required'),
	receiveTalentsBy: Yup.string().required('mode to receive talents is required'),
	email: Yup.string().email().required('email is required'),
});

export { recruiterAndTalentValidationSchema, freelancerValidationSchema, sectionCSchema, sectionDSchema };

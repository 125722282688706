import Button from 'components/buttons/Button';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import style from 'views/jobs/job-details/modals/css/Modal.module.sass';

function ModalBodyB({ callback }) {
	const skillList = [];
	const formElement = document.forms[1]?.elements;
	const [skills, setSkills] = useState(skillList);
	const [remoteWork, setRemoteWork] = useState({ remote_work: '' });

	const { jobId } = useParams();
	const [jobDetails, setJobDetails] = useState({});

	function getJob() {
		function successHandler(response) {
			const result = response?.data?.result;
			setJobDetails(result);
			result.skill_names_list.forEach((skill) => appendList(skill, result));
		}

		service.getUserType() === service.TALENT && api.talents.jobs.job(jobId).then(successHandler).catch(console.error);
		service.getUserType() === service.FREELANCER && api.freelancers.jobs.job(jobId).then(successHandler).catch(console.error);
	}

	function appendList(skill, result) {
		const newField = { name: skill, experience_years: '' };
		skillList.length !== result.skill_names_list.length && skillList.push(newField);
	}

	// eslint-disable-next-line
	useEffect(getJob, [jobId]);

	function handleOnChangeDynamic(e, idx) {
		const name = e.target.name;
		const value = e.target.value;

		const data = [...skills];
		data[idx][name] = value;
		setSkills(data);

		if (skills.length === 1) {
			formElement[name]?.classList.remove('error');
		} else {
			formElement[name][idx]?.classList.remove('error');
		}
	}

	function handleOnChange(e) {
		const value = e.target.value;
		setRemoteWork({ remote_work: value });
		formElement['remoteWork']?.classList.remove('error');
	}

	function hasErrors() {
		if (skills.some(validateArray)) return true;
		else return validateField();
	}

	function validateField() {
		if (remoteWork.remote_work.trim() === '') {
			formElement['remoteWork']?.focus();
			formElement['remoteWork']?.scrollIntoView({ block: 'center' });
			formElement['remoteWork']?.classList.add('error');

			return true;
		}
	}

	function validateArray(object, idx) {
		for (const key in object) {
			if (!object[key]) {
				if (skills.length === 1) {
					formElement[key]?.focus();
					formElement[key]?.scrollIntoView({ block: 'center' });
					formElement[key]?.classList?.add('error');
				} else {
					formElement[key][idx]?.focus();
					formElement[key][idx]?.scrollIntoView({ block: 'center' });
					formElement[key][idx]?.classList.add('error');
				}

				return true;
			}
		}
	}

	function onSubmit() {
		if (!hasErrors()) {
			callback({ required_skills: skills, ...remoteWork, title: jobDetails?.title, location: jobDetails?.location });
		}
	}

	return (
		<div className={`${style.container} ${style['container-2']}`}>
			<div className={style.header}>
				<ProgressBar percent="75" />
			</div>

			<div className={style['con-form']}>
				<h2 className={style.title}>Questions</h2>

				<form>
					{jobDetails?.skill_names_list?.length > 0 &&
						jobDetails?.skill_names_list?.map((skill, index) => (
							<div className="d-lg-flex justify-content-between" key={index}>
								<div className="control-form">
									<div className="d-flex justify-content-between">
										<label>
											How many years of work experience do you have with <u>{skill}</u>?
										</label>
									</div>

									<input type="number" name="experience_years" placeholder="Enter number of years" min={1} onChange={(evt) => handleOnChangeDynamic(evt, index)} />
								</div>
							</div>
						))}

					<div className="d-lg-flex justify-content-between">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Are you willing to work remotely?</label>
							</div>

							<select name="remoteWork" onChange={handleOnChange}>
								<option value="">Select option</option>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</select>
						</div>

						<i className="control-form"></i>
					</div>
				</form>
			</div>

			<div className="footer d-lg-flex align-items-center gap-3">
				<div className="mb-4 mb-lg-0">
					<Button
						small
						text={<h2>One Step Back</h2>}
						primaryNull
						{...{
							modal: true,
							doDismissModal: true,
							modalTarget: 'job-details-modal-a',
						}}
					/>
				</div>
				<div className="mb-4 mb-lg-0">
					{skills.some((object) => {
						for (const key in object) {
							if (!object[key]) {
								return true;
							}
						}
						return false;
					}) || !remoteWork.remote_work.trim() ? (
						<Button
							small
							text={
								<>
									<h2>Next (Questions) </h2>
									<i className="fa-solid fa-arrow-right"></i>
								</>
							}
							onClick={onSubmit}
						/>
					) : (
						<Button
							small
							text={
								<>
									<h2>Next (Questions) </h2>
									<i className="fa-solid fa-arrow-right"></i>
								</>
							}
							onClick={onSubmit}
							{...{
								modal: true,
								doDismissModal: true,
								modalTarget: 'job-details-modal-c',
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default ModalBodyB;

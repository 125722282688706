import Button from 'components/buttons/Button';
import style from './css/Modal.module.sass';

function ModalBody({ callback }) {
	return (
		<div className={`${style['prompt-modal']} d-flex flex-column align-items-center gap-4`}>
			<div className={`${style.header} mt-3`}>
				<h1>Opportunity Posted!</h1>
			</div>

			<div className={`${style.body} d-flex flex-column align-items-center gap-4`}>
				<div className="img">
					<img src="/img/happy 1.png" alt="" />
				</div>

				<p>This Opportunity has been posted. Check your mails or Dashboard for updates.</p>
			</div>

			<div className="footer mb-4">
				<div data-bs-dismiss="modal">
					<Button
						small
						redirect="/dashboard/search"
						text={
							<>
								<h2>Find your Talents now</h2>
								<i className="fa-solid fa-arrow-right"></i>
							</>
						}
						onClick={callback}
					/>
				</div>
			</div>
		</div>
	);
}

ModalBody.defaultProps = {
	callback: () => {},
};

export const handleModal = (props) => {
	return {
		modalWidth: '500px',
		modalTarget: `success-prompt-modal`,
		modalTitle: 'draft post preview',
		modalBody: <ModalBody {...props} />,
	};
};

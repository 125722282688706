import Button from 'components/buttons/Button';
import Modal from 'components/modals/Modal';
import { useState } from 'react';
import AddExperienceModalBody from './modals/add-experience/Modal';
import EditExperienceModalBody from './modals/edit-experiemce/Modal';

import service from 'services';
import ProgressBar from 'components/progress-bar/ProgressBar';
import './css/SectionJ.css';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormContext } from 'ContextApi/FormContext';

function SectionD() {
	const [query] = useSearchParams();
	const { formData, updateFormData } = useFormContext();
	const [experience, setExperience] = useState(formData.sectionJData.experience || []);
	const [hasError, setHasError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const userType = service.getUserType();
	function doAddExperience(body) {
		setExperience((prevExperience) => [...prevExperience, body]);
		setHasError(false);
	}

	function addExperienceModal() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `add-experience-action-modal`,
			modalTitle: 'Add Work Experience',
			modalBody: <AddExperienceModalBody callback={doAddExperience} />,
		};
	}

	function doDeleteExperience(index) {
		const updatedExperience = experience.filter((_, i) => i !== index);
		setExperience(updatedExperience);
		updateFormData('sectionJData', { experience: updatedExperience });
	}

	function editExperienceModal(index) {
		const experienceToEdit = experience[index];
		return {
			modal: true,
			isStatic: true,
			modalTarget: `edit-experience-action-modal-${index}`,
			modalTitle: 'Edit Work Experience',
			modalBody: <EditExperienceModalBody experience={experienceToEdit} callback={(updatedExperience) => handleEditExperience(index, updatedExperience)} />,
		};
	}

	function handleEditExperience(index, updatedExperience) {
		const updatedExperiences = experience.map((exp, i) => (i === index ? updatedExperience : exp));
		setExperience(updatedExperiences);

		setHasError(false);
	}
	function handleSubmit() {
		if (!hasError) {
			updateFormData('sectionJData', { experience });
			if (userType === service.FREELANCER) {
				api.freelancers.profile
					.update({ experience })
					.then(() => {
						const lastPoint = query.get('section');
						localStorage.setItem('lastOnboardingPoint', lastPoint);
						navigate({ search: `?section=5` });
					})
					.catch((error) => console.log(error))
					.finally(() => setIsLoading(false));
			} else {
				api.talents.profile
					.update({ experience })
					.then(() => {
						const lastPoint = query.get('section');
						localStorage.setItem('lastOnboardingPoint', lastPoint);
						navigate({ search: `?section=5` });
					})
					.catch((error) => console.log(error))
					.finally(() => setIsLoading(false));
			}
		}
	}
	return (
		<div id="brand-section">
			<div className="con-header">
				<div className="container-01 mb-3" style={{ padding: '0px' }}>
					<div className="header row justify-content-between py-3 align-items-center">
						<h2 className="display col-lg-6">Work Experience</h2>
						<div className="col-lg-5">
							<ProgressBar percent="37" />
						</div>
						<p className="my-3 col-lg-6 text_intro">
							Adding your work experience give you a better chance of winning an offer.
							<i className="img"></i>
						</p>
					</div>
				</div>
			</div>
			<div className="container-01" style={{ padding: '0px' }}>
				<div className="form">
					<form className="mb-4">
						<div>
							{experience?.length > 0 && (
								<div>
									<div className="row gap-4 align-items-center">
										{experience?.map((x, y) => (
											<div key={y} className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4 col-lg-4">
												<div className="experience-block mb-4 mb-lg-0 w-100">
													<div className=" d-flex  justify-content-between flex-lg-nowrap flex-wrap">
														<div className="one d-flex align-items-center gap-2 flex-lg-nowrap flex-wrap ">
															<h2 className="job_title">{x?.job_title || 'Job Title'}</h2>
															<div>
																<div className="job_type">{x?.contract_type}</div>
															</div>
														</div>
														<div className="d-flex gap-2 actionButtons">
															<Button
																text={
																	<>
																		<img src="/img/svg/edit2.svg" alt="edit exp" />
																	</>
																}
																{...editExperienceModal(y)}
																fadeNull
																small
															/>
															<button onClick={() => doDeleteExperience(y)} className="border-0">
																<img src="/img/svg/delete2.svg" alt="delete exp" />
															</button>
														</div>
													</div>
													<p className="company_name">{x.company || 'Company'}</p>

													<p className="period">
														{service.monthNames()[new Date(x.start_date).getMonth()]}&nbsp;
														{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
														{x.end_date === 'present' ? 'Present' : `${service.monthNames()[new Date(x.end_date).getMonth()]} ${new Date(x.end_date).getFullYear()}`}
													</p>

													<div>
														<div className="d-flex align-items-center gap-3 my-2">
															<div>
																<img src="/img/svg/locationColor.svg" alt="location" className="location_icon" />
															</div>
															<p>{x?.location}</p>
														</div>
													</div>
												</div>
											</div>
										))}
										<div className="mt-4 add_experience_btn col-3">
											<Button
												text={
													<>
														<img src="/img/svg/plusCircle.svg" alt="box" className="svg" />
														Add
													</>
												}
												secondary
												{...addExperienceModal()}
											/>
										</div>
									</div>
								</div>
							)}
						</div>

						{experience?.length <= 0 && (
							<div className="mt-4">
								<div className="modal_svg">
									<Button
										text={
											<>
												<img src="/img/svg/fluent-emoji_briefcase.svg" alt="box" className="svg" />
											</>
										}
										{...addExperienceModal()}
									/>

									<p>Add experience</p>
								</div>
							</div>
						)}

						{/* Submit button */}
						<div className="control-form d-lg-flex gap-3 mt-5 border-top pt-3">
							<div className="mb-5 mb-lg-0">
								<Button
									text={
										<>
											<i className="fa-solid fa-arrow-left"></i>
											<h1>One Step back</h1>
										</>
									}
									primaryNull
									onClick={() => navigate(-1)}
								/>
							</div>

							<div className="mb-4 mb-lg-0">
								<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading} />
							</div>
						</div>
					</form>
				</div>
			</div>

			{/* Modal for adding experience */}
			<Modal modalWidth="750px" {...addExperienceModal()} />
			{experience.map((item, index) => (
				<Modal key={index} modalWidth="750px" {...editExperienceModal(index)} />
			))}
		</div>
	);
}

export default SectionD;

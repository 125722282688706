import BreadCrumb from 'components/bread-crumb/BreadCrumb';
import Button from 'components/buttons/Button';
import Modal from 'components/modals/Modal';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp as RecruiterNav } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import { navbarComp as TalentNav } from 'views/dashboard/talent/navbar-components/navbar-comp';
import ModalBodyA from 'views/jobs/job-details/modals/apply-application-modal/modal-a/Modal';
import ModalBodyB from 'views/jobs/job-details/modals/apply-application-modal/modal-b/Modal';
import ModalBodyC from 'views/jobs/job-details/modals/apply-application-modal/modal-c/Modal';
import Buttons from 'views/jobs/job-details/modals/buttons/Buttons';
import DeclineModal from 'views/jobs/job-details/modals/decline-application-modal/Modal';
import DeleteJobModal from 'views/jobs/job-details/modals/delete-job/Modal';
import './css/JobDetails.style.sass';
import SuccessModal from 'views/profile/talent/modals/SuccessModal';
import FeedBackModal from 'views/profile/talent/modals/FeedBackModal';

function JobDetails() {
	service.setPageTitle('Job Details');
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const { jobId } = useParams();
	const navigate = useNavigate();

	const [jobDetails, setJobDetails] = useState({});
	const [logo, setLogo] = useState(null);
	const [applyForm, setApplyForm] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isModalLoading, setModalIsLoading] = useState(false);

	const [recommendedTalents, setRecommendedTalents] = useState([]);

	const [showModal, setShowModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [report, setReport] = useState({
		reason: '',
		description: '',
	});

	const handleReport = (e) => {
		const { name, value } = e.target;
		setReport((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const userType = service.getUserType();

	const breadCrumbProp = [
		{
			title: 'Home',
			link: '/dashboard',
		},
		{
			title: 'Opportunity',
			link: '',
		},
	];

	function getRecommendedTalents() {
		function successHandler(response) {
			setRecommendedTalents(response?.data?.result?.talents);
		}
		function errorHandler(response) {
			console.log(response);
		}
		api.recruiter.getRecommendedTalents(jobId).then(successHandler).catch(errorHandler);
	}

	function getJob() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response?.data?.result;
			const logo = response?.data?.result?.recruiter?.company_logo?.href_link;
			setLogo(logo);
			setJobDetails(result);
		}

		function onFinal() {
			setIsLoading(false);
		}
		switch (service.getUserType()) {
			case service.TALENT:
				api.talents.jobs.job(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			case service.FREELANCER:
				api.freelancers.jobs.job(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			case service.RECRUITER:
				api.recruiter.getJob(jobId).then(successHandler).catch(console.error).finally(onFinal);
				return;
			default:
				break;
		}
	}

	useEffect(getJob, [jobId]);
	useEffect(getRecommendedTalents, [jobId]);

	function doDeleteJob() {
		function successHandler() {
			navigate('/dashboard');
		}

		api.recruiter.deleteJob(jobId).then(successHandler).catch(console.error);
	}

	function declineApplication() {
		return {
			modal: true,
			isStatic: true,
			modalTitle: 'warning',
			modalTarget: 'application-decline-modal',
			modalBody: <DeclineModal context={jobDetails} callback={getJob} />,
		};
	}

	function deleteJob(callback) {
		return {
			modal: true,
			isStatic: true,
			modalTitle: 'warning',
			modalTarget: `delete-job-action-modal`,
			modalBody: <DeleteJobModal callback={callback} />,
		};
	}
	const reportThisJob = () => {
		setModalIsLoading(true);
		api.talents.jobs
			.reportJob(jobId, report)
			.then((res) => {
				if (res.status === 200) {
					setShowModal(false);
					setShowSuccessModal(true);
					setReport(report);
				}
			})
			.catch((err) => console.log(err))
			.finally(() => setModalIsLoading(false));
	};

	const handleCloseSuccessModal = () => {
		setShowSuccessModal(false);
	};
	const handleCancel = () => {
		setShowModal(false);
	};

	function applyJob() {
		const props = {
			title: `apply to ${jobDetails?.company_name}`,
			modalATarget: 'job-details-modal-a',
			modalBTarget: 'job-details-modal-b',
			modalCTarget: 'job-details-modal-c',
			modalWidth: '900px',
		};

		function append(data) {
			setApplyForm({ ...applyForm, ...data });
		}

		return [
			{
				isStatic: true,
				modalWidth: '1000px',
				modalTitle: props.title,
				modalTarget: props.modalATarget,
				modalBody: <ModalBodyA callback={append} />,
			},
			{
				isStatic: true,
				modalTitle: props.title,
				modalWidth: props.modalWidth,
				modalTarget: props.modalBTarget,
				modalBody: <ModalBodyB callback={append} />,
			},
			{
				isStatic: true,
				modalTitle: props.title,
				modalWidth: props.modalWidth,
				modalTarget: props.modalCTarget,
				modalBody: <ModalBodyC data={applyForm} callback={getJob} />,
			},
		];
	}

	return (
		<div id="Job_Details_Main_Container" ref={top}>
			{userType === service.RECRUITER ? <Navbar {...RecruiterNav} /> : <Navbar {...TalentNav} />}

			<div className="con-margin-height container-01">
				<div className="outline-wrap">
					<BreadCrumb context={breadCrumbProp} />
					{!isLoading && jobDetails && (
						<div className="section-a mb-4">
							<div className="header d-lg-flex align-items-center justify-content-between">
								<div className="d-lg-flex align-items-center gap-4 gap-lg-3 mb-3 mb-lg-0">
									<div className="img mb-3 mb-lg-0">
										<img src={logo || '/img/avatar.png'} alt="" style={{ border: '4px solid #FFB700' }} />
									</div>

									<div className="body">
										<div style={{ display: 'flex', gap: '10px' }}>
											<h2>{jobDetails?.title}</h2>
											<img src={jobDetails?.isJobApproved ? '/img/svg/approved.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
										</div>
										<h3>{jobDetails?.company_name && service.titleCase(jobDetails?.company_name)}</h3>
										{jobDetails?.world_wide === true ? (
											<p className="d-flex align-items-center gap-1">
												<i className="fa-solid fa-location-dot"></i>
												{jobDetails?.world_wide && service.truncateText(service.titleCase('worldwide'))}
											</p>
										) : (
											<p className="d-flex align-items-center gap-1">
												<i className="fa-solid fa-location-dot"></i>
												{jobDetails?.location?.state && service.titleCase(jobDetails?.location?.state)}, {jobDetails?.location?.country && service.titleCase(jobDetails?.location?.country)}
											</p>
										)}
										<p className="d-flex align-items-center gap-1">
											<i className="fa-solid fa-globe"></i>
											<Link to="">{jobDetails?.company_link}</Link>
										</p>
									</div>
								</div>
								<Buttons job={jobDetails} hasApplied={jobDetails?.hasApplied} declineModal={declineApplication} deleteModal={deleteJob} id={jobId} />
							</div>

							{/* <div className="social d-flex flex-column flex-lg-row align-items-center gap-4">
								<p>social links:</p>
								<i className="fa-brands fa-linkedin-in"></i>
								<i className="fa-brands fa-instagram"></i>
								<i className="fa-solid fa-basketball"></i>
								<i className="fa-brands fa-github"></i>
								<i className="fa-brands fa-gitlab"></i>
							</div> */}
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-b mb-5">
							<div className="header d-flex align-items-center gap-3">
								<i className="fa-solid fa-screwdriver-wrench"></i>
								<h2>Job Description</h2>
							</div>

							<div className="body">
								<p className="mb-4">{jobDetails?.description && service.transform(jobDetails?.description)}</p>
							</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-c mb-5">
							<div className="header d-flex align-items-center gap-2 mb-3">
								<i className="fa-solid fa-dollar-sign"></i>
								<h2>Expected Pay</h2>
							</div>

							<div className="body mb-3">
								<h2>
									{jobDetails.currency}
									{jobDetails?.expected_pay?.toLocaleString()}
								</h2>
							</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="con-context">
							<h4 className="title">Recommended Talents ({recommendedTalents.length})</h4>

							<div className="cards-b d-flex flex-wrap justify-content-start">
								{!isLoading &&
									recommendedTalents?.length > 0 &&
									recommendedTalents.map((result, idx) => (
										<Link
											to={`/dashboard/talents-profile/${jobId}/${result?.talent?.id}`}
											state={{
												breadcrumb: [
													{ title: 'Home', link: '/dashboard' },
													{ title: 'Job Details', link: '', previous: true },
													{ title: 'Recommended Talent', link: '' },
												],
											}}
											key={idx}>
											<div className="card" style={{ flex: '1 1 calc(25% - 20px)', margin: '10px', height: '270px', width:'200px' }}>
												<div className="header">
													<p>{`CAND${idx + 1}`}</p>
													<i className="fa-regular fa-user"></i>
												</div>
												<div className="Cardbody">
													<div className="img">
														<img src={result?.talent?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="head shot" />
													</div>
													<div className="text">
														<div style={{ display: 'flex', gap: '5px' }}>
															<h1 className="truncate-e" style={{ fontSize: '16px' }}>
																{result?.first_name} {result?.last_name}
															</h1>
														</div>
														<h3 className="my-2 text-truncate">{result?.title}</h3>
														<p className="d-flex align-items-center gap-2 truncate-e">
															<i className="fa-solid fa-location-dot"></i>
															{result?.location?.resident_state || `Lagos`}, {result?.location?.resident_country || `Nigeria`}
														</p>
													</div>
												</div>
											</div>
										</Link>
									))}
							</div>

							{!isLoading && recommendedTalents?.length < 1 && (
								<div className="text-center d-flex flex-column gap-3 mx-auto py-3 align-items-center justify-content-center">
									<img src="/img/oops.png" alt="oops" className="img-fluid w-25" />
									<h3 className="my-2">Oops!</h3>
									<p>No talent fits this job yet</p>
								</div>
							)}
							<div className="con-animated-bg">{isLoading && [1, 1, 1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="section-d mb-4">
							<div className="header d-flex align-items-center gap-2 mb-3">
								<i className="fa-solid fa-dollar-sign"></i>
								<h2>Important Safety Tips</h2>
							</div>

							<div className="body mb-4">
								<ul className="mb-3">
									<li>Do not make any payment without confirming with the {service.brand.name} Customer Support Team.</li>
									<li>Do not make any payment without confirming with the {service.brand.name} Customer Support Team.</li>
								</ul>
								<div>
									{userType !== 'recruiter' && <Button small primaryNull text={<h2>Report Opportunity</h2>} onClick={() => setShowModal(true)} />}

									{showModal && (
										<FeedBackModal
											title="Enter Your Query Here"
											modalTitle={'REPORT OPPORTUNITY'}
											details={report}
											Subject={'Reasons for reporting'}
											subjects={[
												{ value: 'sub1', label: 'Sub 1' },
												{ value: 'sub2', label: 'Sub 2' },
												{ value: 'sub3', label: 'Sub 3' },
											]}
											placeHolderText={'Explain better'}
											handleChange={handleReport}
											handleSubmit={reportThisJob}
											onCancel={handleCancel}
											isLoading={isModalLoading}
										/>
									)}

									{showSuccessModal && <SuccessModal text="Report Sent!" handleClose={handleCloseSuccessModal} modalTitle={'Report'} modalMessage={'Thanks for informing us about this. Our team will review this problem as soon as possible'} />}
								</div>
							</div>
						</div>
					)}

					{!isLoading && jobDetails && (
						<div className="footer d-flex align-items-center gap-3">
							<Buttons hasApplied={jobDetails?.hasApplied} declineModal={declineApplication} deleteModal={deleteJob} id={jobId} />
						</div>
					)}

					<div className="con-animated-bg section-1 mb-4">{isLoading && <div className={'animated-bg'}></div>}</div>
					<div className="con-animated-bg section-2 mb-4">{isLoading && <div className={'animated-bg'}></div>}</div>
					<div className="con-animated-bg section-3 mb-4">{isLoading && <div className={'animated-bg'}></div>}</div>
				</div>
			</div>

			<Footer />

			<Modal {...applyJob()[0]} />
			<Modal {...applyJob()[1]} />
			<Modal {...applyJob()[2]} />
			<Modal {...declineApplication()} />
			<Modal {...deleteJob(doDeleteJob)} />
		</div>
	);
}

export default JobDetails;

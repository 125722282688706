import React, { useEffect, useRef, useState } from 'react';
import './css/ChoosePlan.css';
import service from 'services';
import Button from 'components/buttons/Button';
import { useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { Skeleton } from '@mui/material';
import Loader from 'components/loader/Loader';

const PlanDetails = () => {
	const top = useRef(undefined);
	// const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [paying, setPaying] = useState(false);
	const [plan, setPlan] = useState({});
	const [discount, setDiscount] = useState({});

	const [query] = useSearchParams();
	const planId = query.get('planId');

	useEffect(() => {
		setLoading(true);
		api.subscriptions
			.getSingleSubscription(planId)
			.then((res) => setPlan(res.data))
			.catch(console.error)
			.finally(() => setLoading(false));
	}, [planId]);

	const [promo, setPromo] = useState('');

	const estimatedPrice = discount?.discount ? (plan?.price - (discount?.discount / 100) * plan?.price) : plan?.price;

	useEffect(() => service.goTo(top), []);

	const applyPromoCode = () => {
		setLoading(true);
		if (promo === '') {
			alert('Please type a promo code');
			setLoading(false);
			return;
		}
		api.talents
			.applyCoupon(promo)
			.then((res) => {
				setDiscount(res.data);
				setPromo('');
			})
			.catch(err=>alert(err.data.result))
			.finally(() => setLoading(false));
	};

	const upgradeAndPay = () => {
		setPaying(true)
		api.talents.subscriptions
			.initiateSubscription({ planId, promoCode: discount.code })
			.then((res) => {
				const paymentUrl = res?.data.url;
				if (paymentUrl) {
					window.location.href = paymentUrl;
				}
			})
			.catch((err)=>alert(err.result)).finally(() => setPaying(false))
	};

	return (
		<div id="Choose_Plan_Container" ref={top}>
			<div className="con-margin-height">
				<div className=" d-flex flex-column align-items-center justify-content-center">
					<h2 style={{ fontWeight: '700' }}>Membership Upgrade</h2>
					<p className="my-3">To hire, a plan has to be chosen to proceed </p>
				</div>
				<div className="con-section container-01 p-4 mb-4" style={{ border: '1px solid gray', borderRadius: '20px' }}>
					<div className="con-body">
						<div className="row  justify-content-center">
							{/* top */}
							<div className="d-flex flex-column gap-4">
								<article className="d-flex flex-column gap-3">
									<h5 style={{ fontWeight: '700' }}>Current plan</h5>
									<p>Talent Regular (Free)</p>
								</article>

								<article className="d-flex flex-column gap-3">
									<h5 style={{ fontWeight: '700' }}>New plan</h5>
									{loading ? <Skeleton variant="text" width={'30%'} animation="wave" /> : <p>{plan.name}</p>}
								</article>

								<article>
									<div className="">
										<div className="d-flex justify-content-between">
											<label>Promo code</label>
										</div>
										<div className="d-lg-flex w-100" style={{gap:'10px'}}>
											<input
												type="text"
												id="promo"
												name="promo"
												placeholder="Enter the subject here"
												onChange={(e) => setPromo(e.target.value.toUpperCase())}
												value={promo}
												className="form-control w-100 p-3 rounded-3 shadow-sm"
												style={{
													border: '2px solid black',
													fontSize: '16px',
													width: '100%',
													maxWidth: '400px',
													height: '45px',
													boxSizing: 'border-box',
													marginBottom:'10px',
													textIndent:'10px',
													textTransform:'uppercase'
												}}
											/>

											<Button text={'Apply'} onClick={applyPromoCode} />
										</div>
									</div>
								</article>
							</div>
							<hr />
							{/* bottom */}
							<div>
								<ul className="d-flex flex-column gap-4">
									<li className="d-flex justify-content-between">
										<h6 style={{ fontWeight: '700' }}>Subtotal</h6>
										{loading ? <Skeleton variant="text" width={'30%'} animation="wave" /> : <p>₦{plan.price?.toLocaleString()}/ month</p>}
									</li>
									{discount?.discount && (
										<li className="d-flex justify-content-between">
											<h6 style={{ fontWeight: '700' }}>Discount</h6>
											{loading ? <Skeleton variant="text" width={'30%'} animation="wave" /> : <p>{discount.discount} %</p>}
										</li>
									)}
									<li className="d-flex justify-content-between">
										<h6 style={{ fontWeight: '700' }}>Estimated taxes</h6>
										<p>₦0.00 / month</p>
									</li>
									<li className="d-flex justify-content-between">
										<h6 style={{ fontWeight: '700' }}>Estimated total</h6>
										{loading ? <Skeleton variant="text" width={'30%'} animation="wave" /> : <p>₦{estimatedPrice?.toLocaleString()} / month</p>}
									</li>
								</ul>
								<hr />
								<article className="w-100">
									<Button text={paying ? <Loader /> : 'Upgrade and pay'} /*onClick={() => navigate('/dashboard/to-premium?section=3')} */ onClick={upgradeAndPay} />
									<p className="w-100 py-3" style={{maxWidth:'800px'}}>You're authorizing Frontters to charge {loading ? <Skeleton variant="text" width={'10%'} animation="wave" /> : `₦${estimatedPrice}`} + tax to your Frontters account or primary billing method at the beginning of each billing cycle. This is subject to any applicable promotion</p>
								</article>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlanDetails;

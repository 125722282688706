import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'components/buttons/Button';
import service from 'services';
import './forgetpassword.css'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup';
import api from 'services/api';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';


const Forgetpassword = () => {
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        userType: Yup.string().required('Please select your role in frontters'),
    });


    const initialValue = {
        email: '',
        userType: ''
    }
    const handleSubmit = (value, { setSubmitting }) => {
        const { email, userType } = value;
    
        // console.log(email)
        api.resetPasswordRequest.resetpasswordrequest({
            email: email,
            userType: userType.toLowerCase()
        })
        .then(response => {
            // Handle the response here
            // console.log(response.data)
            setSubmitting(false);
            navigate('/resetpasswordsuccess', { state: { email: value.email } });
        })
        .catch(error => {
            // Handle errors here
            alert('There seems to be an issue with sending the email; kindly attempt the process again.');
            navigate('/login')
        });
    };
    return (
        <div>
            <Navbar
                bgColor={'white'}
                centerContent={
                    <ul className="d-flex align-items-center justify-content-between gap-4">
                        <li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
                            Discover Talents
                        </li>
                        <li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
                            Discover Opportunities
                        </li>
                        <li>
                            <Link to="/aboutus">About {service.brand.name}</Link>
                        </li>
                    </ul>
                }
                sidebarContent={
                    <>
                        <ul id="con-navbar-sidebar">
                            <li onClick={() => navigate('/register?tab=recruiter')} style={{ cursor: 'pointer' }}>
                                Discover Talents
                            </li>
                            <li onClick={() => navigate('/register?tab=talent')} style={{ cursor: 'pointer' }}>
                                Discover Opportunities
                            </li>
                            <Link to="">
                                <li>About {service.brand.name}s</li>
                            </Link>
                            <Link to="/register" className="active">
                                <li>Register</li>
                            </Link>
                            <Link to="/login" className="active">
                                <li>Login</li>
                            </Link>
                        </ul>
                    </>
                }
                rightContent={
                    <div className="d-flex align-items-center justify-content-between">
                        <Button small text="Sign up" redirect="/register" />
                        <Button small primaryEmptyFill text="Login" redirect="/login" />
                    </div>
                }
            />
            <div className="container d-flex justify-content-center align-items-center my-3" style={{ height: '100vh' }} >
                <Formik
                    initialValues={initialValue}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}

                >

                    {({ isSubmitting }) => (


                        <div style={{ position: 'relative' }}>


                            <div className="logo_symbol">
                                <div className='e_img-con'>
                                    <img src="/favicon.ico.svg" alt="" />

                                </div>
                            </div>
                            <Form className="e_form"
                            >
                                <h4 className="e_form-title">Trouble signing in?</h4>
                                <p className="calm_text">
                                    No need to fret; we're here to assist! Simply provide your email address, and we'll send you a link to reset your password.

                                </p>
                                <div className="e_input-container my-2">
                                    <label htmlFor="email">Email:</label>
                                    <Field type="email" placeholder="Enter email" name="email" />
                                    <span>
                                    </span>
                                    <ErrorMessage name="email" component="div" className="error-message" />
                                </div>


                                <p className="question_text my-3">
                                    Who are you to Frontters
                                </p>


                                <div className="f_radio-input-wrapper">
                                    <label className="f_label">
                                        <Field value="Talent" name="userType" id="value-2" className="f_radio-input" type="radio" />
                                        <div className="f_radio-design"></div>
                                        <div className="f_label-text">Talent</div>
                                    </label>
                                    <label className="f_label">
                                        <Field value="Recruiter" name="userType" id="value-3" className="f_radio-input" type="radio" />
                                        <div className="f_radio-design"></div>
                                        <div className="f_label-text">Recruiter
                                        </div>
                                    </label>

                                </div>
                                <ErrorMessage name="userType" component="div" className="error-message" />

                                {/* <Button text="Submit" type='submit' /> */}
                                {/* <button type='submit' disabled={isSubmitting} className='e_button' >Submit</button> */}
                                <Button text={<LoaderButton isLoading={isSubmitting} text="Submit" />} disabled={isSubmitting} buttonType={'submit'} />


                            </Form>
                        </div>

                    )}


                </Formik>

            </div>


            <Footer />


        </div>
    )
}

export default Forgetpassword

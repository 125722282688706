import BreadCrumb from 'components/bread-crumb/BreadCrumb.jsx';
import JobCard from 'components/cards/job-card/JobCard';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination.jsx';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer.jsx';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/talent/navbar-components/navbar-comp.jsx';
import './css/SavedJobs.style.sass';
import Button from 'components/buttons/Button';
import { useNavigate } from 'react-router-dom';

function SavedJobs() {
	service.setPageTitle('My Saved Jobs');
	const userType = service.getUserType();
	const navigate = useNavigate();

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [jobs, setJobs] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const itemsPerPage = 10;
	const [currentPage, setCurrentPage] = useState(1);

	function fetchFavorites() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response.data.result;
			setJobs(result);
		}
		function draftSuccessHandler(response) {
			const result = response?.data?.result;
			setJobs(result.filter((job) => job?.status === 'drafted'));
		}

		function onFinal() {
			setIsLoading(false);
		}

		if (userType === 'talent') {
			api.talents.jobs.getAllBookmarked().then(successHandler).catch(console.error).finally(onFinal);
		} else if (userType === 'freelancer') {
			api.freelancers.jobs.getAllBookmarked().then(successHandler).catch(console.error).finally(onFinal);
		} else {
			api.recruiter.getMyJobs().then(draftSuccessHandler).catch(console.error).finally(onFinal);
		}
	}

	useEffect(fetchFavorites, [userType]);
	// console.log(jobs);

	const breadCrumbProp = [
		{
			title: 'Back',
			link: '',
			previous: true,
		},
		{
			title: 'Saved Jobs',
			link: '',
		},
	];

	const pageCount = Math.ceil(jobs.length / itemsPerPage);
	const startIdx = (currentPage - 1) * itemsPerPage;
	const paginatedJobs = jobs.slice(startIdx, startIdx + itemsPerPage);

	const onPageChange = (newPage) => {
		if (newPage >= 1 && newPage <= pageCount) {
			setCurrentPage(newPage);
		}
	};

	return (
		<div id="Saved_Jobs_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="outline-wrap">
						<div className="con-header mx-4 mt-4 py-3">
							<BreadCrumb context={breadCrumbProp} />
						</div>

						<div className="con-context">
							<h2 className="title">Saved Jobs</h2>

							<div className="body">
								{!isLoading &&
									paginatedJobs?.length > 0 &&
									paginatedJobs?.map((job, idx) => {
										if (userType === 'recruiter') {
											console.log(job);
											return <JobCard key={idx} data={job} callback={fetchFavorites} />;
										} else {
											return <JobCard key={idx} data={job.job} callback={fetchFavorites} />;
										}
									})}
							</div>
							{!isLoading && paginatedJobs?.length < 1 && (
								<div className="text-center d-flex flex-column gap-3 mx-auto py-3 align-items-center justify-content-center">
									<img src="/img/svg/nojob.svg" alt="no experience" style={{ width: '70px' }} />
									<h5 className="fw-bold">No Saved Jobs</h5>
									<p className="fw-normal">Add bookmarked job comes here</p>
									<Button primaryNull text={'Bookmark A Job'} onClick={() => navigate('/dashboard/jobs/recommended-jobs')} />
								</div>
							)}
							<div className="con-animated-bg">{isLoading && [1, 1, 1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
						</div>

						{paginatedJobs?.length > 9 && (
							<div className="footer">
								<IndexPagination pageCount={pageCount} currentPage={currentPage} onPageChange={onPageChange}/>
							</div>
						)}
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default SavedJobs;

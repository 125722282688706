import style from "./css/ProfileLevel.module.sass";

function ProfileLevel({ level }) {
  const deg = -(180 * level) / 100;

  return (
    <div className={style.section}>
      <h2 className={style.top}>Profile Level</h2>

      <div className={style.center}>
        <div className={style["con-circle"]}>
          <div className={style.circle}>
            <div
              className={`${style.mask} ${style.full}`}
              style={{ transform: `rotate(${deg}deg)` }}
            >
              <div
                className={style.fill}
                style={{ transform: `"rotate(${deg}deg)"` }}
              ></div>
            </div>
            <div className={`${style.mask}`}>
              <div
                className={style.fill}
                style={{ transform: `"rotate(${deg}deg)"` }}
              ></div>
            </div>
            <div className={style["inside-circle"]}> {level || `0`}% </div>
          </div>
        </div>
      </div>

      <div className={style.bottom}>
        <span></span>
        <p>:&nbsp;Level of completion</p>
      </div>
    </div>
  );
}

export default ProfileLevel;

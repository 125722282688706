import Button from 'components/buttons/Button';
import Tab from 'components/buttons/tabs/Tabs';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import Navbar from 'views/_shared/navbar/Navbar';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp';
import Previous from 'views/onboarding/talent/previous/Previous';
import UploadViewer from '../../../components/upload-viewer/UploadViewer';
import './css/Profile.style.sass';

function RecruiterProfile() {
	const navigate = useNavigate();

	const [isActive, setIsActive] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);

	const staffCapacity = ['1-10', '11-50', '51-250', '251-10k', '10k+'];

	const [form, setForm] = useState({
		company_name: '',
		company_website: '',
		phone_number: '',
		recruiter_position: '',
		staff_capacity: '',
	});

	const [avatar, setAvatar] = useState('');

	function handleOnChange(e) {
		const attribute = e.target.getAttribute('name');
		const value = e.target.value;
		setForm({ ...form, [`${attribute}`]: value });
	}

	function uploadAvatar() {
		const postBody = new FormData();
		postBody.append('file', avatar);

		api.recruiter.profile.uploadCompanyImage(postBody).then().catch(console.error);
	}

	function doSubmit() {
		setIsLoading(true);
		form.staff_capacity = staffCapacity[isActive - 1];

		function successHandler(response) {
			setIsLoading(false);
			setHasLoaded(true);

			avatar && uploadAvatar();
		}

		function errorHandler(response) {
			setIsLoading(false);
			setHasLoaded(true);
			console.error(response);
		}

		api.recruiter.profile.update(form).then(successHandler).catch(errorHandler).finally(()=>navigate(-1));
	}

	function getProfile() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response.data.result;
			setForm({ ...form, company_name: result.company_name, company_website: result.company_website, phone_number: result.phone_number, recruiter_position: result.recruiter_position });

			setIsActive(staffCapacity.indexOf(result.staff_capacity) + 1);
			setIsLoading(false);
		}

		function errorHandler(response) {
			setIsLoading(false);
			console.error(response);
		}

		api.recruiter.profile.get().then(successHandler).catch(errorHandler);
	}

	// eslint-disable-next-line
	useEffect(getProfile, []);

	const handleFileChange = (e) => {
		const files = e.target.files;
		if (files) {
			if (files[0]?.size) {
				setAvatar(files[0]);
			}
		}
	};

	return (
		<div id="Recruiter_Profile_Main_Container">
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="container-01">
					<div className="con-header">
						<div className="previous pb-4 pt-5">
							<Previous />
						</div>

						<div className="header">
							<h2 className="pb-3">Edit Profile</h2>
							<p className="pb-5">Let's get your profile updated</p>
						</div>
					</div>

					<div className="con-form">
						<div className="form">
							<form>
								<div>
									<div className="con-title">
										<i className="fa-solid fa-user-check"></i>
										<h2>Staff Capacity</h2>
									</div>

									<div className="d-lg-flex gap-2 mb-4">
										{staffCapacity.map((x, y) => (
											<Tab context={x} primaryOutline callback={() => setIsActive(y + 1)} activeTab={isActive === y + 1} key={y} />
										))}
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-phone"></i>
										<h2>Company Info</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Company Name</label>
											</div>
											<input type="text" placeholder="Company Name" name="company_name" value={form.company_name} onChange={handleOnChange} disabled={(!form.company_name && hasLoaded) || isLoading} />
										</div>

										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Position in Company</label>
											</div>
											<input type="text" placeholder="Recruiter Position" name="recruiter_position" value={form.recruiter_position} onChange={handleOnChange} disabled={(!form.recruiter_position && hasLoaded) || isLoading} />
										</div>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Company Website</label>
											</div>
											<input type="text" placeholder="Company Website Link" name="company_website" value={form.company_website} onChange={handleOnChange} disabled={(!form.company_website && hasLoaded) || isLoading} />
										</div>

										<i className="control-form"></i>
									</div>

									<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-4">
										<div className="con-upload">
											<label className="upload">
												<div className="d-flex align-items-center gap-2">
													<p>Attach Company Logo</p>
													<i className="fa-solid fa-paperclip"></i>
												</div>
												<input type="file" accept={`image/png,image/jpeg`} onChange={handleFileChange} />
											</label>
										</div>

										{avatar && <UploadViewer filename={avatar?.name} callback={() => setAvatar('')} />}
									</div>
								</div>

								<div>
									<div className="con-title">
										<i className="fa-solid fa-phone"></i>
										<h2>Contact Details</h2>
									</div>

									<div className="d-lg-flex justify-content-between gap-4">
										<div className="control-form">
											<div className="d-flex justify-content-between">
												<label>Phone Number</label>
											</div>
											<input type="text" placeholder="Phone Number" name="phone_number" value={form.phone_number} onChange={handleOnChange} disabled={(!form.phone_number && hasLoaded) || isLoading} />
										</div>

										<i className="control-form"></i>
									</div>
								</div>

								<div className="control-form d-flex flex-column flex-lg-row align-items-lg-center gap-3">
									<div>
										<Button text={<h1>Back</h1>} primaryNull disabled={isLoading} onClick={() => navigate(-1)} />
									</div>

									<div>
										<Button text={<LoaderButton isLoading={isLoading} text="Update" outerConStyle={{ width: '100px' }} />} onClick={doSubmit} disabled={isLoading} />
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RecruiterProfile;

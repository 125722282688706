import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import service from 'services';

const SocialsLogin = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const success = searchParams.get('success');
	const access = searchParams.get('access');
	const refresh = searchParams.get('refresh');
	const isOnboardingComplete = searchParams.get('isOnboardingComplete');
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('Verifying you have an account with us...');

	function setTokenToLocalStorage(token) {
		return new Promise((resolve, reject) => {
			try {
				service.setTokensLocalStorage(token);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	async function handleSuccess() {
		if (success === 'true' && access) {
			const newToken = {
				access: {
					token: access,
				},
				refresh: {
					token: refresh,
				},
			};

			try {
				await setTokenToLocalStorage(newToken);
				setLoading(false);
				await new Promise((resolve) => setTimeout(resolve, 500));
				const userType = service.getUserType();
				if (isOnboardingComplete === 'false' && userType !== 'freelancer') {
					setMessage('Complete your onboarding process');
					setTimeout(() => {
						navigate(`/onboarding?${service.section}1`);
						service.reloadPage();
					}, 3000);
				} else {
					setMessage('Taking you to your dashboard...');
					setTimeout(() => {
						navigate('/dashboard');
						service.reloadPage();
					}, 3000);
				}
			} catch (error) {
				console.log('catch', error);
				setLoading(false);
				setMessage('Try to login again');
				setTimeout(() => {
					navigate('/login');
					service.reloadPage();
				}, 3000);
			}
		} else if (success === 'false' && !access) {
			setMessage('You need to Sign Up with us');
			setTimeout(() => {
				navigate('/register');
			}, 3000);
		}
		setLoading(false);
	}

	useEffect(() => {
		handleSuccess();
	});

	if (loading) {
		return <div>Loading...</div>;
	}

	return <div>{message}</div>;
};

export default SocialsLogin;

import Button from 'components/buttons/Button';
import Modal from 'components/modals/Modal';
import { useState } from 'react';
import AddEducationModalBody from './modal/add-education/Modal';
import EditEducationModalBody from './modal/edit-education/Modal';
import service from 'services';
import ProgressBar from 'components/progress-bar/ProgressBar';
import '../section-j/css/SectionJ.css';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormContext } from 'ContextApi/FormContext';

function SectionK() {
	const { formData, updateFormData } = useFormContext();
	const [query] = useSearchParams();
	const [education, setEducation] = useState(formData.sectionKData.education || []);
	const [hasError, setHasError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const userType = service.getUserType();

	function doAddEducation(body) {
		setEducation((prevEducation) => [...prevEducation, body]);
		setHasError(false);
	}

	function addEducationModal() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `add-education-action-modal`,
			modalTitle: 'education',
			modalBody: <AddEducationModalBody callback={doAddEducation} />,
		};
	}

	function doDeleteEducation(idx) {
		setEducation((prevEducation) => prevEducation.filter((_, i) => i !== idx));
		updateFormData('sectionKData', { education });
	}
	function editEducationModal(index) {
		const educationToEdit = education[index];
		return {
			modal: true,
			isStatic: true,
			modalTarget: `edit-education-action-modal-${index}`,
			modalTitle: 'Edit Work Education',
			modalBody: <EditEducationModalBody education={educationToEdit} callback={(updatedExperience) => handleEditEducation(index, updatedExperience)} />,
		};
	}
	function handleEditEducation(index, updatedExperience) {
		const updatedEducations = education.map((exp, i) => (i === index ? updatedExperience : exp));
		setEducation(updatedEducations);

		setHasError(false);
	}
	function handleSubmit() {
		if (!hasError) {
			setIsLoading(true);
			function successHandler() {
				updateFormData('sectionKData', { education });
				const lastPoint = query.get('section')
				localStorage.setItem('lastOnboardingPoint',lastPoint)
				navigate({ search: `?section=6` });
			}
			function errorHandler(response) {
				console.log(response);
			}
			function finalHandler() {
				setIsLoading(false);
			}
			if (userType === service.FREELANCER) {
				api.freelancers.profile.update({ education }).then(successHandler).catch(errorHandler).finally(finalHandler);
			} else {
				api.talents.profile.update({ education }).then(successHandler).catch(errorHandler).finally(finalHandler);
			}
		}
	}
	return (
		<div id="brand-section">
			<div className="con-header">
				<div className="container-01 mb-3" style={{ padding: '0px' }}>
					<div className="header row justify-content-between py-2 align-items-center">
						<h2 className="display col-lg-6">Education</h2>
						<div className="col-lg-5">
							<ProgressBar percent="46" />
						</div>
						<p className="my-2 col-lg-6 text_intro">
							Adding your work educational background give you a better chance of winning an offer.
							<i className="img"></i>
						</p>
					</div>
				</div>
			</div>
			<div className="container-01" style={{ padding: '0px' }}>
				<div className="form">
					<form className="mb-4">
						<div>
							{education?.length > 0 && (
								<div>
									<div className="row gap-4 align-items-center">
										{education?.map((x, y) => (
											<div key={y} className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4 col-lg-4">
												<div className="experience-block mb-4 mb-lg-0 w-100">
													<div className=" d-flex  justify-content-between">
														<div className="one d-flex align-items-center gap-2 ">
															<h2 className="job_title">{x?.school}</h2>
															<div></div>
														</div>
														<div className="d-flex gap-2 actionButtons">
															<Button
																text={
																	<>
																		<img src="/img/svg/edit2.svg" alt="edit exp" />
																	</>
																}
																{...editEducationModal(y)}
																fadeNull
																small
															/>
															<button onClick={() => doDeleteEducation(y)} className="border-0">
																<img src="/img/svg/delete2.svg" alt="delete exp" />
															</button>
														</div>
													</div>
													<h3 className="school_degree">{x?.degree}</h3>

													<p className="period">
														{service.monthNames()[new Date(x.start_date).getMonth()]}&nbsp;
														{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
														{x.end_date === 'present' ? 'Present' : `${service.monthNames()[new Date(x.end_date).getMonth()]} ${new Date(x.end_date).getFullYear()}`}
													</p>

													{/* <div>
														<div className="d-flex align-items-center gap-3 my-2">
															<div>
																<img src="/img/svg/locationColor.svg" alt="location" className="location_icon" />
															</div>
															<p>{x?.location}</p>
														</div>
													</div> */}
												</div>
											</div>
										))}
										<div className="mt-4 add_experience_btn col-3">
											<Button
												text={
													<>
														<img src="/img/svg/plusCircle.svg" alt="box" className="svg" />
														Add
													</>
												}
												secondary
												{...addEducationModal()}
											/>
										</div>
									</div>
								</div>
							)}
						</div>
						{education?.length <= 0 && (
							<div className="mt-4">
								<div className="modal_svg">
									<Button
										text={
											<>
												<img src="/img/svg/education_2.svg" alt="box" className="svg" />
											</>
										}
										{...addEducationModal()}
									/>

									<p>Add education</p>
								</div>
							</div>
						)}

						{/* Submit button */}
						<div className="control-form d-lg-flex gap-3 mt-5 border-top pt-3">
							<div className="mb-5 mb-lg-0">
								<Button
									text={
										<>
											<i className="fa-solid fa-arrow-left"></i>
											<h1>One Step back</h1>
										</>
									}
									primaryNull
									onClick={() => navigate(-1)}
								/>
							</div>

							<div className="mb-4 mb-lg-0">
								<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading} />
							</div>
						</div>
					</form>
				</div>
			</div>

			{/* Modal for adding experience */}
			<Modal modalWidth={'750px'} {...addEducationModal()} />
			{education.map((item, index) => (
				<Modal key={index} modalWidth="750px" {...editEducationModal(index)} />
			))}
		</div>
	);
}

export default SectionK;

import React from 'react';
import BreadCrumb from 'components/bread-crumb/BreadCrumb.jsx';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination.jsx';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import api from 'services/api';
import Footer from 'views/_shared/footer/Footer.jsx';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import { navbarComp } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import './css/Applicants.style.sass';
import { Link, useParams } from 'react-router-dom';
import './css/ApplicantsCards.scss';

const Applicants = () => {
	service.setPageTitle('Applicants');
	const userType = service.getUserType();
	//  const navigate = useNavigate();
	const { jobId } = useParams();

	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const [isLoading, setIsLoading] = useState(false);
	const [applicants, setApplicants] = useState([]);
	const itemsPerPage = 10;
	const [currentPage, setCurrentPage] = useState(1);

	function fetchApplicants() {
		setIsLoading(true);

		function onSuccess(response) {
			const result = response.data?.result;
			setApplicants(result);
		}

		function onFinal() {
			setIsLoading(false);
		}

		api.recruiter
			.getJobApplicants(jobId)
			.then(onSuccess)
			.catch((res) => console.log(res))
			.finally(onFinal);
	}

	useEffect(fetchApplicants, [userType, jobId]);
	// console.log(jobs);

	const breadCrumbProp = [
		{
			title: 'Home',
			link: '',
		},
		{
			title: 'Applied Jobs',
			link: '',
			previous: true,
		},
		{
			title: 'Applicants',
			link: '',
		},
	];

	const pageCount = Math.ceil(applicants.length / itemsPerPage);
	const startIdx = (currentPage - 1) * itemsPerPage;
	const paginatedApplicants = applicants.slice(startIdx, startIdx + itemsPerPage);

	const onPageChange = (newPage) => {
		if (newPage >= 1 && newPage <= pageCount) {
			setCurrentPage(newPage);
		}
	};

	return (
		<div id="Applied_Jobs_Main_Container" ref={top}>
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="outline-wrap" style={{ border: '3px solid black' }}>
						<div className="con-header mx-4 py-4">
							<BreadCrumb context={breadCrumbProp} />
						</div>

						<div className="con-context">
							<h2 className="title">Applicants ({applicants.length})</h2>

							<div className="cards-b d-flex flex-wrap justify-content-start">
								{!isLoading &&
									paginatedApplicants?.length > 0 &&
									paginatedApplicants.map((result, idx) => (
										<Link
											to={`/dashboard/talents-profile/${jobId}/${result?.talent?.id}`}
											state={{
												breadcrumb: [
													{ title: 'Home', link: '/dashboard' },
													{ title: 'Applied Jobs', link: '/dashboard/jobs/applied' },
													{ title: 'Applicants', link: '', previous: true },
													{ title: 'View Applicant', link: '' },
												],
											}}
											key={idx}>
											<div className="card" style={{ flex: '1 1 calc(25% - 20px)', margin: '10px', height: '270px' }}>
												<div className="header">
													<p>{`CAND${idx + 1}`}</p>
													<i className="fa-regular fa-user"></i>
												</div>
												<div className="Cardbody">
													<div className="img">
														<img src={result?.talent?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="head shot" />
													</div>
													<div className="text">
														<div style={{ display: 'flex', gap: '5px' }}>
															<h1 className="truncate-e" style={{ fontSize: '16px' }}>
																{result?.applicant_first_name} {result?.applicant_last_name}
															</h1>
														</div>
														<h3 className="my-2 text-truncate">{result?.title}</h3>
														<p className="d-flex align-items-center gap-2 truncate-e">
															<i className="fa-solid fa-location-dot"></i>
															{result?.location?.resident_state || `Lagos`}, {result?.location?.resident_country || `Nigeria`}
														</p>
													</div>
												</div>
											</div>
										</Link>
									))}
							</div>

							{!isLoading && applicants?.length < 1 && (
								<div className="text-center d-flex flex-column gap-3 mx-auto py-3 align-items-center justify-content-center">
									<img src="/img/oops.png" alt="oops" className="img-fluid w-25" />
									<h3 className="my-2">Oops!</h3>
									<p>No applied jobs yet</p>
								</div>
							)}
							<div className="con-animated-bg">{isLoading && [1, 1, 1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
						</div>

						{paginatedApplicants?.length > itemsPerPage && (
							<div className="footer">
								<IndexPagination pageCount={pageCount} currentPage={currentPage} onPageChange={onPageChange} />
							</div>
						)}
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default Applicants;

import Circle from 'components/circle/Circle';
import './css/SectionA.style.sass';
import { Link } from 'react-router-dom';

function SectionA({ callback }) {
	return (
		<div id="SectionA_Main_Container" className="con-margin-height">
			<div className="con-section">
				<div className="container-01">
					<div className="d-lg-flex align-items-center mt-5">
						<div className="section-a col-12 col-lg-6">
							<div className="con-top">
								<div className="con-title">
									<h1>Take Your Career</h1>
									<span className="d-flex align-items-baseline">
										<h1>to Greater Heights</h1>
									</span>
								</div>

								<div className="con-form">
									<form className="form d-flex align-items-md-center flex-column-reverse flex-md-row">
										<div className="select mb-md-0">
											<select name="">
												<option value="">Search for Talents</option>
												<option value="">Search for Jobs</option>
											</select>
										</div>
										<div className="d-flex align-items-center w-100 gap-3">
											<div className="search w-100">
												<input type="text" placeholder="Enter Keyword" />
											</div>
											<Link to="/register" >
											<button type="submit">
												<i className="fa-solid fa-arrow-right"></i>
											</button></Link>
											
										</div>
									</form>
								</div>

								<div className="con-cta d-flex align-items-center gap-2">
									<p>Need a job?</p>
									<Link to="/register" >Find Opportunities</Link>
								</div>
							</div>
							<div className="con-bottom d-lg-flex d-none justify-content-center justify-content-lg-end">
								<Circle callback={callback} />
							</div>
						</div>

						<div className="section-b col-12 col-lg-6">
							<div className="img d-flex px-lg-2">
								<img src={'img/svg/flyingman.svg'} alt="top" />
							</div>
						</div>

						<div className="section-c col-12 d-lg-none">
							<Circle callback={callback} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionA;

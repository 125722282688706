import style from "./css/Toggle.module.sass";

function Toggle({ label, status, callback }) {
  return (<div className="d-flex align-items-center gap-3">
    <h2 className={style.label}>{label || `define label`}</h2>
    
    <div className={style.switch}>
      <input type="checkbox" onChange={callback} checked={status}/>
      <span className={style.slider}></span>
    </div>
  </div>);
}

Toggle.defaultProps = {
  status: true, callback: () => {
  },
};

export default Toggle;

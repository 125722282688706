import { Link, useNavigate } from "react-router-dom";
import s from "./css/BreadCrumb.module.sass";

function BreadCrumb(prop) {
  const { context } = prop;
  const navigate = useNavigate();

  const callback = (hasPrevious) => {
    if (hasPrevious) navigate(-1);
  };

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb d-flex align-items-center gap-1">
        <i className="fa-solid fa-chevron-left"></i>
        {context.length > 0 &&
          context.map((x, y) => (
            <li
              className={`${
                y === context.length - 1
                  ? `breadcrumb-item active ${s.active}`
                  : `breadcrumb-item`
              } `}
              aria-current="page"
              key={y}
              onClick={() => callback(x.previous)}
            >
              <Link to={x.previous ? `` : x.link}>{x.title}</Link>
            </li>
          ))}
      </ol>
    </nav>
  );
}

export default BreadCrumb;

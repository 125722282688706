import Button from 'components/buttons/Button';
import api from 'services/api';
import style from './css/Modal.module.sass';

function DeclineModal({ context: { applicationId }, callback }) {
	function declineApplication() {
		api.talents.jobs.declineApplyToJob(applicationId).then(callback).catch(console.error);
	}

	return (
		<div className={`${style['con-main']} d-flex flex-column align-items-center gap-4 py-4`}>
			<h2 className={`${style.title}`}>Warning</h2>
			<div className={`${style.img}`}>
				<img src="/img/Group 282.png" alt="" />
			</div>

			<p className={`${style['body']}`}>Decline application to this job?</p>

			<div className="footer d-flex flex-column flex-lg-row align-items-center gap-3">
				<div data-bs-dismiss={`modal`}>
					<Button text="Cancel" primaryNull />
				</div>
				<div data-bs-dismiss={`modal`}>
					<Button text="Continue" onClick={declineApplication} />
				</div>
			</div>
		</div>
	);
}

export default DeclineModal;

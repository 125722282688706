import style from './css/Message.module.sass';

function MessageAPI({ messages }) {
	return (
		<div className={`${style['con-message']}`}>
			{messages &&
				messages.length > 0 &&
				messages.map((messageObj, idx) => (
					<div key={idx}>
						{messageObj.text && (
							<div className={`${style.message} d-none d-lg-block mb-3`}>
								<div className={`d-flex align-items-center justify-content-between gap-5`}>
									<h1 style={{ ...messageObj?.textStyle }}>{messageObj?.text}</h1>
								</div>
							</div>
						)}
					</div>
				))}
		</div>
	);
}

MessageAPI.defaultProps = {
	messages: [
		{
			text: 'message api text',
			textStyle: '',
		},
	],
};

export default MessageAPI;

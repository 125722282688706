import ProgressBar from 'components/progress-bar/ProgressBar';
import React, { useEffect, useRef, useState } from 'react';
import service from 'services';
import './css/SectionF.css';
import { jobCategories } from 'constant/JobCategories';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import api from 'services/api';
import { useFormContext } from 'ContextApi/FormContext';

const SectionF = () => {
	const [query] = useSearchParams();
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);
	const navigate = useNavigate();
	const userType = service.getUserType();
	const { formData, updateFormData } = useFormContext();
	const [activeTab, setActiveTab] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};
	const [specialty, setSpecialty] = useState(formData.sectionFData.specialty || []);
	const [categoryColors, setCategoryColors] = useState({});

	const handleCheckboxChange = (subCategory, category) => {
		// If the specialty is already selected, unselect it
		if (specialty.includes(subCategory)) {
			setSpecialty(specialty.filter((item) => item !== subCategory));
			// If no specialties remain in the category, reset the color
			if (specialty.filter((item) => jobCategories[activeTab].subcategories.includes(item)).length === 0) {
				setCategoryColors({ ...categoryColors, [category]: '' });
			}
		} else if (specialty.length < 3) {
			// Add the specialty to the list
			setSpecialty([...specialty, subCategory]);
			// Highlight the category by changing its color
			setCategoryColors({ ...categoryColors, [category]: 'selectedCategory' });
		}
	};

	const specialtyCount = specialty.length;

	function handleSubmit() {
		setIsLoading(true);
		function successHandler() {
			updateFormData('sectionFData', { specialty });
			const lastPoint = query.get('section');
			localStorage.setItem('lastOnboardingPoint', lastPoint);
			navigate({ search: `?section=3` });
		}
		function errorHandler(response) {
			console.log(response);
		}
		function finalHandler() {
			setIsLoading(false);
		}
		const data = { specialty };
		if (userType === service.FREELANCER) {
			api.freelancers.profile.update(data).then(successHandler).catch(errorHandler).finally(finalHandler);
		} else {
			api.talents.profile.update(data).then(successHandler).catch(errorHandler).finally(finalHandler);
		}
	}

	return (
		<div id="Onboarding_Talent_SectionF_Main_Component" ref={top}>
			<div className="con-header">
				<div className="container-01 mb-3">
					<div className="header row justify-content-between py-2 align-items-center">
						<h2 className="display col-lg-6">What are your specialties?</h2>
						<div className="col-lg-5">
							<ProgressBar percent="18" />
						</div>
						<p className="my-3 col-12 text_intro">
							You are free to modify these selections later on
							<i className="img"></i>
						</p>
					</div>
				</div>
			</div>

			<div className="con-form container-01 select_method_con">
				<div className="row">
					<div className="col-lg-3">
						<h3 className="job_categories_header my-3">Select a category</h3>
						<div className="px-3 py-2">
							{jobCategories.map((categories, index) => {
								// Check if any subcategory within the category is selected
								const categoryIsSelected = specialty.some((subCategory) => categories.subcategories.includes(subCategory));
								return (
									<div key={index} className="my-3">
										<button
											className={`job_list  
												${activeTab === index ? 'activeJob' : ''}`}
											onClick={() => handleTabClick(index)}
											style={{
												backgroundColor: categoryColors[categories.category] ? '#D8E8F4' : '',
												border: categoryIsSelected ? '2px solid #4CAF50' : '',
											}}>
											{categories.category}
										</button>
									</div>
								);
							})}
						</div>
					</div>
					<div className="col-lg-4">
						<h3 className="job_categories_header my-3">Pick at most, 3 of your specialties</h3>
						{/* Display the count of selected specialties */}
						<div className="specialty-count">
							<p>Specialties Selected: {specialtyCount} / 3</p>
						</div>
						<div className="job_subCategories px-3 py-2">
							{jobCategories[activeTab].subcategories.map((subCategory, subIndex) => (
								<div key={subIndex} className="my-3">
									<label className="check_field-checkbox-label">
										<input type="checkbox" className="check_field-checkbox" checked={specialty.includes(subCategory)} onChange={() => handleCheckboxChange(subCategory, jobCategories[activeTab].category)} />
										{subCategory}
									</label>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="control-form d-lg-flex gap-3 mt-5 border-top pt-3">
					<div className="mb-5 mb-lg-0">
						<Button
							text={
								<>
									<i className="fa-solid fa-arrow-left"></i>
									<h1>One Step back</h1>
								</>
							}
							primaryNull
							onClick={() => navigate(-1)}
						/>
					</div>

					<div className="mb-4 mb-lg-0">
						<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading || specialtyCount === 0} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionF;

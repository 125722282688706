import ProgressBar from 'components/progress-bar/ProgressBar';
import React, { useEffect, useState } from 'react';
import service from 'services';
import Modal from 'components/modals/Modal';

import api from 'services/api';
import Button from 'components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
// import UpdateProfileModal from 'views/profile/talent/modals/UpdateProfileModal';
import './css/Profile.style.sass';
import EditBasicInfo from './modals/EditBasicInfo';
import EditBio from './modals/EditBio';
import AddSkills from './modals/AddSkills';

import AddExperienceModalBody from '../section-j/modals/add-experience/Modal';
import AddEducationModalBody from '../section-k/modal/add-education/Modal';

const ProfilePreview = () => {
	const navigate = useNavigate();
	const userType = service.getUserType();

	const [isLoading, setIsLoading] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);

	const [user, setUser] = useState({});

	function getUser() {
		setIsLoading(true);

		function successHandler(response) {
			const result = response.data.result;
			setUser(result);
		}

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setIsLoading(false);
		}

		if (userType === service.TALENT) {
			api.talents.profile.get().then(successHandler).catch(errorHandler).finally(onFinal);
		} else {
			api.freelancers.profile.get().then(successHandler).catch(errorHandler).finally(onFinal);
		}
	}

	function doAddExperience(body) {
		setUser({ ...user, experience: [...user.experience, body] });
	}

	function doAddEducation(body) {
		setUser({ ...user, education: [...user.education, body] });
	}

	function doDeleteExperience(index) {
		setUser({
			...user,
			experience: user.experience.filter((_, i) => i !== index),
		});
	}
	function doDeleteEducation(idx) {
		setUser({ ...user, education: user?.education?.filter((x, y) => y !== idx) });
	}
	function doAddSkills(body) {
		setUser({ ...user, skills: [...user.skills, body] });
	}

	function doUpdateProfile(postBody) {
		function errorHandler(response) {
			console.error(response);
		}
		if (userType === 'talent') {
			api.talents.profile.update(postBody).then(getUser).catch(errorHandler);
		} else {
			api.freelancers.profile.update(postBody).then(getUser).catch(errorHandler);
		}
	}
	function updateProfileModal() {
		return {
			modal: true,
			isStatic: true,
			modalWidth: '1000px',
			modalTitle: 'candidate bio-data',
			modalTarget: `update-basic-info`,
			modalBody: <EditBasicInfo context={user} callback={doUpdateProfile} />,
		};
	}
	function updateBio() {
		return {
			modal: true,
			isStatic: true,
			modalWidth: '1000px',
			modalTitle: 'candidate bio-data',
			modalTarget: `update-bio`,
			modalBody: <EditBio context={user} callback={doUpdateProfile} />,
		};
	}

	function addExperienceModal() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `add-experience-action-modal`,
			modalTitle: 'Add Work Experience',
			modalBody: <AddExperienceModalBody callback={doAddExperience} />,
		};
	}
	function addEducationModal() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `add-education-action-modal`,
			modalTitle: 'education',
			modalBody: <AddEducationModalBody callback={doAddEducation} />,
		};
	}

	function addSkills() {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `add-skills`,
			modalTitle: 'skills',
			modalBody: <AddSkills context={user} callback={doAddSkills} />,
		};
	}
	function doUpload(file) {
		const formData = new FormData();
		formData.append('file', file);

		function errorHandler(response) {
			console.error(response);
		}

		function onFinal() {
			setLoadingImage(false);
		}

		if (userType === service.TALENT) {
			api.talents.profile.uploadImage(formData).then(getUser).catch(errorHandler).finally(onFinal);
		} else {
			api.freelancers.profile.uploadImage(formData).then(getUser).catch(errorHandler).finally(onFinal);
		}
	}
	function handleFileChange({ target: { files } }) {
		if (files) {
			setLoadingImage(true);
			doUpload(files[0]);
		}
	}

	function handleSubmit() {
		setIsLoading(true);
		const cleanUserData = (user) => {
			const { _id, ...rest } = user;
			const cleanedEducation = rest.education?.map(({ _id, ...eduRest }) => eduRest) || [];
			const cleanedSkills = rest.skills?.map(({ _id, competency_text, ...skillRest }) => skillRest) || [];
			const cleanedExperience = rest.experience?.map(({ _id, ...expRest }) => expRest) || [];

			const { _id: locationId, ...cleanedLocation } = rest.location || {};

			return {
				first_name: rest.first_name,
				last_name: rest.last_name,
				email: rest.email,
				location: cleanedLocation,
				skills: cleanedSkills,
				education: cleanedEducation,
				experience: cleanedExperience,
				title: rest.title,
				talent_bio: rest.talent_bio,
				isOnboardingComplete:true
			};
		};

		const userData = cleanUserData(user);

		function successHandler() {
			localStorage.removeItem('lastOnboardingPoint')
			localStorage.removeItem('formData')
			navigate('/dashboard');
		}
		function errorHandler(response) {
			console.log(response);
		}
		function finalHandler() {
			setIsLoading(false);
		}
		if (userType === service.TALENT) {
			api.talents.profile.update(userData).then(successHandler).catch(errorHandler).finally(finalHandler);
		} else {
			api.freelancers.profile.update(userData).then(successHandler).catch(errorHandler).finally(finalHandler);
		}
	}
	useEffect(() => {
		getUser();
		// eslint-disable-next-line
	}, [userType]);
	return (
		<div id="brand-section">
			<div className="con-header">
				<div className="container-01 py-2">
					<div className="header row justify-content-between py-2 align-items-center">
						<h2 className="display col-lg-6">Preview</h2>
						<div className="col-lg-5">
							<ProgressBar percent="100" />
						</div>
						<p className="mt-3 col-lg-7 text_intro">Clients will see this rate on your profile and in search results once you publish your profile.</p>
					</div>
				</div>

				<div className="container-01 py-2 ">
					<div className="first-col">
						<div className="section section-a py-4 ">
							{!isLoading && (
								<div className={`section-a d-lg-flex align-items-center justify-content-between`}>
									<div className="d-flex flex-column flex-lg-row align-items-center gap-3 mb-4 mb-lg-0">
										<div className="con-img">
											<label className="upload">
												<div className="img">
													<img src="/img/Frame 39812.png" alt="" />
												</div>
												<input type="file" accept={`image/png,image/jpeg`} onChange={handleFileChange} />
											</label>

											<div className="user-img">
												{!loadingImage && <img src={user?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="" />}

												{loadingImage && <div className={'animated-bg'}></div>}
											</div>
										</div>

										<div className="user-context d-flex flex-column align-items-center align-items-lg-start text-center gap-2">
											<div className="d-flex align-items-center gap-1 ">
												<h1>
													{user?.first_name && service.titleCase(user?.first_name)} {user?.last_name && service.titleCase(user?.last_name)}
												</h1>
											</div>
											<h2 title={user?.title} className={!user?.title ? 'text-muted font-italic' : ''} style={{ fontSize: '16px', color: '#2E2E30', fontWeight: '600' }}>
												{user?.title ? service.truncateText(user?.title, 26) : 'Edit profile to set Designation'}
											</h2>
											<h2 title={user?.email} style={{ fontSize: '14px' }}>
												{service.truncateText(user?.email, 26)}
											</h2>
											<p className="d-flex align-items-center gap-2" style={{ fontSize: '12px' }}>
												<i className="fa-solid fa-location-dot"></i> {user?.location?.resident_state ? service.titleCase(user?.location?.resident_state) : 'State Not Provided'},{' '}
												{user?.location?.resident_country ? service.titleCase(user?.location?.resident_country) : 'Country Not Provided'}
											</p>
										</div>
									</div>

									<div>
										<Button
											small
											primaryNull
											text={
												<>
													<img src="/img/svg/edit2.svg" alt="" />
													Edit
												</>
											}
											{...updateProfileModal()}
										/>
									</div>
								</div>
							)}

							<div className="con-animated-bg">{isLoading && [1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
						</div>
					</div>
					<div className="section section-b py-4">
						{!isLoading && user?.talent_bio === '' ? (
							''
						) : (
							<div className="d-flex justify-content-between">
								<div>
									<h2 className="title mb-3">About {user?.first_name && service.titleCase(user?.first_name)}</h2>
									<p>{user?.talent_bio && service.transform(user?.talent_bio)}</p>
								</div>

								<div>
									<Button
										small
										primaryNull
										text={
											<>
												<img src="/img/svg/edit2.svg" alt="" />
												Edit
											</>
										}
										{...updateBio()}
									/>
								</div>
							</div>
						)}

						<div className="your_skills my-3">
							<div className="d-flex justify-content-between align-items-center">
								<h2>Your Skills</h2>
								<h2>Max. 10 skills ({10 - user?.skills?.length} remaining)</h2>
							</div>
							<div className="p-2 skills_box my-3 d-flex align-items-center flex-wrap gap-3">
								{user?.skills?.map((skill, idx) => (
									<div className="skills_tag px-3 py-1 gap-3" key={idx}>
										<span>{skill?.name}</span> <i class="fa-solid fa-xmark "></i>
									</div>
								))}
							</div>
							<div>
								<Button
									small
									primaryNull
									text={
										<>
											<img src="/img/svg/plusCircle.svg" alt="box" className="svg" />
											Add
										</>
									}
									{...addSkills()}
								/>
							</div>
						</div>
					</div>

					<div className=" my-4 ">
						<div>
							<div className="section section-b py-4">
								<div className="heading d-flex align-items-center gap-2">
									<img src="/img/svg/experience.svg" alt="experience" style={{ width: '25px' }} />
									<h2 className="title">Experience ({user?.experience?.length})</h2>
								</div>
								<div className="row gap-4 align-items-center mt-5">
									{user?.experience?.length > 0 ? (
										user?.experience?.map((x, y) => (
											<div key={y} className="con-experience-block d-lg-flex align-items-start  gap-1 col-lg-3  col-md-4">
												<div className="experience-block mb-4 mb-lg-0 w-100">
													<div className=" d-flex  justify-content-between">
														<div className="one d-flex align-items-center gap-2 ">
															<h2 className="job_title">{x?.job_title || 'Job Title'}</h2>
															<div>
																<div className="job_type">{x?.contract_type}</div>
															</div>
														</div>
														<div>
															<button onClick={() => doDeleteExperience(y)} className="border-0">
																<img src="/img/svg/delete2.svg" alt="delete exp" />
															</button>
														</div>
													</div>
													<p className="company_name">{x.company || 'Company'}</p>

													<p className="period">
														{service.monthNames()[new Date(x.start_date).getMonth()]}&nbsp;
														{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
														{x.end_date === 'present' ? 'Present' : `${service.monthNames()[new Date(x.end_date).getMonth()]} ${new Date(x.end_date).getFullYear()}`}
													</p>

													<div>
														<div className="d-flex align-items-center gap-3 my-2">
															<div>
																<img src="/img/svg/locationColor.svg" alt="location" className="location_icon" />
															</div>
															<p>{x?.location}</p>
														</div>
													</div>
												</div>
											</div>
										))
									) : (
										<div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center ">
											<img src={'/img/svg/addexperience.svg'} alt="no experience" style={{ width: '86px' }} />
											<h5 className="fw-bold">No Work Experience added</h5>
											<p className="fw-normal">Add your work experience</p>
											<Button primaryNull text={'Add Work Experience'} {...addExperienceModal()} />
										</div>
									)}

									{user?.experience?.length > 0 && (
										<div className="mt-4 add_experience_btn col-3">
											<Button
												small
												primaryNull
												text={
													<>
														<img src="/img/svg/plusCircle.svg" alt="box" className="svg" />
														Add
													</>
												}
												{...addExperienceModal()}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="section section-b py-4">
								<div className="heading d-flex align-items-center gap-2">
									<img src="/img/svg/education.svg" alt="education" style={{ width: '25px' }} />
									<h2 className="title">Education ({user?.education?.length})</h2>
								</div>
								<div className="row gap-4 align-items-center mt-5">
									{user?.education?.length > 0 ? (
										user?.education?.map((x, y) => (
											<div key={y} className="con-experience-block d-lg-flex align-items-start justify-content-between gap-1 col-lg-3">
												<div className="experience-block mb-4 mb-lg-0 w-100">
													<div className=" d-flex  justify-content-between">
														<div className="one d-flex align-items-center gap-2 ">
															<h2 className="job_title">{x?.school}</h2>
															<div></div>
														</div>
														<div>
															<button onClick={() => doDeleteEducation(y)} className="border-0">
																<img src="/img/svg/delete2.svg" alt="delete exp" />
															</button>
														</div>
													</div>
													<h3 className="school_degree">{x?.degree}</h3>

													<p className="period">
														{service.monthNames()[new Date(x.start_date).getMonth()]}&nbsp;
														{new Date(x.start_date).getFullYear()}&nbsp;-&nbsp;
														{x.end_date === 'present' ? 'Present' : `${service.monthNames()[new Date(x.end_date).getMonth()]} ${new Date(x.end_date).getFullYear()}`}
													</p>
												</div>
											</div>
										))
									) : (
										<div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center ">
											<img src={'/img/svg/addeducation.svg'} alt="no experience" style={{ width: '100px' }} />
											<h5 className="fw-bold">No Education added</h5>
											<p className="fw-normal">Add your Education here</p>
											<Button primaryNull text={'Add Education'} {...addEducationModal()} />
										</div>
									)}

									{user?.education?.length > 0 && (
										<div className="mt-4 add_experience_btn col-3">
											<Button
												text={
													<>
														<img src="/img/svg/plusCircle.svg" alt="box" className="svg" />
														Add
													</>
												}
												secondary
												{...addEducationModal()}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="control-form d-lg-flex gap-3  my-4" style={{ padding: '20px 0' }}>
						<div className="mb-5 mb-lg-0">
							<Button
								text={
									<>
										<i className="fa-solid fa-arrow-left"></i>
										<h1>One Step back</h1>
									</>
								}
								primaryNull
								onClick={() => navigate(-1)}
							/>
						</div>

						<div className="mb-4 mb-lg-0">
							<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={handleSubmit} disabled={isLoading} />
						</div>
					</div>
				</div>
			</div>
			<Modal {...updateProfileModal()} />
			<Modal {...updateBio()} />
			<Modal {...addSkills()} />

			<Modal modalWidth={'700px'} {...addExperienceModal()} />
			<Modal modalWidth={'700px'} {...addEducationModal()} />
		</div>
	);
};

export default ProfilePreview;

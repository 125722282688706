import React, { useState, useEffect } from 'react';
import { Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Flag from 'react-world-flags'; // You can install this package for flag images

const LanguagePicker = () => {
  const languages = [
    { code: 'en', name: 'English', flag: 'GB' },
    { code: 'es', name: 'Spanish', flag: 'ES' },
    { code: 'fr', name: 'French', flag: 'FR' },
    { code: 'de', name: 'German', flag: 'DE' },
    { code: 'zh-CN', name: 'Chinese (Simplified)', flag: 'CN' },
    { code: 'zh-TW', name: 'Chinese (Traditional)', flag: 'TW' },
    { code: 'hi', name: 'Hindi', flag: 'IN' },
    { code: 'ar', name: 'Arabic', flag: 'AE' },
    { code: 'pt', name: 'Portuguese', flag: 'PT' },
    { code: 'ru', name: 'Russian', flag: 'RU' },
    { code: 'ja', name: 'Japanese', flag: 'JP' },
    { code: 'ko', name: 'Korean', flag: 'KR' },
    { code: 'it', name: 'Italian', flag: 'IT' },
    { code: 'nl', name: 'Dutch', flag: 'NL' },
    { code: 'pl', name: 'Polish', flag: 'PL' },
    { code: 'tr', name: 'Turkish', flag: 'TR' },
    { code: 'sv', name: 'Swedish', flag: 'SE' },
    { code: 'fi', name: 'Finnish', flag: 'FI' },
    { code: 'da', name: 'Danish', flag: 'DK' },
    { code: 'no', name: 'Norwegian', flag: 'NO' },
    { code: 'el', name: 'Greek', flag: 'GR' },
    { code: 'he', name: 'Hebrew', flag: 'IL' },
    { code: 'id', name: 'Indonesian', flag: 'ID' },
    { code: 'ms', name: 'Malay', flag: 'MY' },
    { code: 'th', name: 'Thai', flag: 'TH' },
    { code: 'vi', name: 'Vietnamese', flag: 'VN' },
    { code: 'hu', name: 'Hungarian', flag: 'HU' },
    { code: 'cs', name: 'Czech', flag: 'CZ' },
    { code: 'sk', name: 'Slovak', flag: 'SK' },
    { code: 'uk', name: 'Ukrainian', flag: 'UA' },
    { code: 'ro', name: 'Romanian', flag: 'RO' },
    { code: 'bg', name: 'Bulgarian', flag: 'BG' },
    { code: 'sr', name: 'Serbian', flag: 'RS' },
    { code: 'hr', name: 'Croatian', flag: 'HR' },
    { code: 'lt', name: 'Lithuanian', flag: 'LT' },
    { code: 'lv', name: 'Latvian', flag: 'LV' },
    { code: 'et', name: 'Estonian', flag: 'EE' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedFlag, setSelectedFlag] = useState('GB');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    // Load Google Translate script
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);

    // Define callback function for Google Translate initialization
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'google_translate_element'
      );
    };

    return () => {
      // Cleanup script on unmount
      document.body.removeChild(script);
    };
  }, []);

  const handleLanguageChange = (languageCode, flagCode) => {
    const translateDropdown = document.querySelector('.goog-te-combo');
    if (translateDropdown) {
      translateDropdown.value = languageCode;
      // Trigger change event to translate the page
      translateDropdown.dispatchEvent(new Event('change', { bubbles: true }));
    }

    // Update the selected language and flag
    setSelectedLanguage(languageCode);
    setSelectedFlag(flagCode);
    setAnchorEl(null); // Close the menu after selection
  };

  const handleClickFlag = (event) => {
    setAnchorEl(event.currentTarget); // Open the dropdown
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Close the dropdown
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        padding: '10px',
        borderRadius: '4px',
      }}
    >
      {/* Display the flag based on the selected language */}
      <IconButton onClick={handleClickFlag} sx={{ padding: 0 }}>
        <Flag code={selectedFlag} style={{ width: '30px', height: '30px' }} />
      </IconButton>

      {/* Language Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{ marginTop: '8px' }}
      >
        {languages.filter((language) => language.code !== selectedLanguage).map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => handleLanguageChange(language.code, language.flag)}
          >
            <ListItemIcon>
              <Flag code={language.flag} style={{ width: '24px', height: '24px' }} />
            </ListItemIcon>
            <ListItemText>{language.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <div id="google_translate_element" style={{ display: 'none' }}></div>
    </Box>
  );
};

export default LanguagePicker;

import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import service from 'services';

const ChatList = ({ chatsList = { conversations: [] }, onSelectChat, socket, loading, activeRole }) => {
	const [selectedChatId, setSelectedChatId] = useState(null);
	const ACTIVE_ROLE = service.getUserType();
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredChats, setFilteredChats] = useState(chatsList?.conversations);

	useEffect(() => {
		// Filter conversations based on the search term
		const results = chatsList?.conversations?.filter((chat) => {
			if (activeRole === service.RECRUITER) {
				const fullName = `${chat.talent?.first_name} ${chat.talent?.last_name}`;
				const title = chat.talent?.title;
				return fullName.toLowerCase().includes(searchTerm.toLowerCase()) || title.toLowerCase().includes(searchTerm.toLowerCase());
			} else {
				const fullName = `${chat.recruiter?.first_name} ${chat.recruiter?.last_name}`;
				const companyName = chat.recruiter?.company_name;
				return fullName.toLowerCase().includes(searchTerm.toLowerCase()) || companyName.toLowerCase().includes(searchTerm.toLowerCase());
			}
		});
		setFilteredChats(results);
	}, [searchTerm, chatsList?.conversations, activeRole]);

	const handleChatClick = async (chat) => {
		setSelectedChatId(chat._id);
		if (ACTIVE_ROLE === service.RECRUITER) {
			chat.recruiterUnreadMessages = 0;
		} else if (ACTIVE_ROLE === service.TALENT) {
			chat.talentUnreadMessages = 0;
		}

		let userId;
		if (ACTIVE_ROLE === service.RECRUITER) {
			userId = chat.recruiter?._id;
		} else {
			userId = chat?.talent?._id;
		}
		const data = {
			conversationId: chat._id,
			userId: userId,
		};

		// Wrap the socket emit call in a promise
		const emitJoin = (data) => {
			return new Promise((resolve, reject) => {
				socket.emit('join', data, (response) => {
					if (response.error) {
						reject(response.error);
					} else {
						resolve(response);
					}
				});
			});
		};

		try {
			const joinResponse = await emitJoin(data);
			console.log('Join response:', joinResponse);

			// Wait for 3 seconds before setting the selected chat
			await new Promise((resolve) => setTimeout(resolve, 50));
			onSelectChat(chat);
			setFilteredChats((prevChats) => {
				const updatedChats = prevChats.filter((c) => c._id !== chat._id);
				return [chat, ...updatedChats];
		});
		} catch (error) {
			console.error('Error joining chat:', error);
		}
	};

	const skeletonItems = new Array(5).fill(0);

	const formatTime = (dateString) => {
		const date = new Date(dateString);
		const options = {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		};
		return new Intl.DateTimeFormat('en-US', options).format(date);
	};

	const renderChatDetails = (chat) => {
		if (activeRole === service.RECRUITER) {
			return (
				<div className="d-flex flex-column gap-2">
					<p style={{ fontWeight: '500', fontSize: '14px', color: '#2E2E30' }}>{service.truncateText(`${chat.talent?.first_name} ${chat.talent?.last_name}`, 16)}</p>
					<p className="mb-1" style={{ color: '#D2671A', fontWeight: '600' }}>
						{service.truncateText(chat.talent?.title, 16)}
					</p>
				</div>
			);
		} else {
			return (
				<div className="d-flex flex-column gap-2">
					<p style={{ fontWeight: '500', fontSize: '14px', color: '#2E2E30' }}>{service.truncateText(`${chat.recruiter?.first_name} ${chat.recruiter?.last_name}`, 16)}</p>
					<p className="mb-1" style={{ color: '#D2671A', fontWeight: '600' }}>
						{service.truncateText(chat.recruiter?.company_name, 16)}
					</p>
				</div>
			);
		}
	};

	const profileImage = (chat) => {
		if (activeRole === service.RECRUITER) {
			return (
				<figure className="m-0" style={{ minWidth: '50px', width:'30%',maxWidth:'80px',height:'80px', border: '5px solid #FFB700' }}>
					<img src={chat.talent?.profile_picture?.href_link || 'https://t3.ftcdn.net/jpg/06/33/54/78/240_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg'} alt="placeholder" style={{ width: '100%', height: '70px', objectFit: 'cover' }} />
				</figure>
			);
		} else {
			return (
				<figure className="m-0" style={{ minWidth: '50px', width:'30%',maxWidth:'80px',height:'80px', border: '5px solid #FFB700' }}>
					<img src={chat.recruiter?.profile_picture?.href_link || 'https://t3.ftcdn.net/jpg/06/33/54/78/240_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg'} alt="placeholder" style={{ width: '100%', height: '70px', objectFit: 'cover' }} />
				</figure>
			);
		}
	};

	const renderUnread = (details) => {
		const unreadMessages = ACTIVE_ROLE === service.RECRUITER ? details?.recruiterUnreadMessages : details?.talentUnreadMessages;

		if (unreadMessages > 0) {
			return <p style={{ fontSize: '13px', color: '#D5D5D6', backgroundColor: '#CC5600', borderRadius: '1000px', padding: '2px 6px' }}>{unreadMessages}</p>;
		}
		return null;
	};

	return (
		<aside style={{ backgroundColor: '#ECECEC', padding: '20px', borderRadius: '15px', minHeight: '700px' }}>
			<div>
				<h2 style={{ fontWeight: '800', paddingBottom: '30px' }}>Conversations ({chatsList?.totalConversation || 0})</h2>
				<input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder={`Search here`} className="shadow" style={{ padding: '15px', borderRadius: '10px', outline: 'none', border: 'none' }} />
			</div>
			<div className="list-group h-100 overflow-auto d-flex flex-column gap-3 my-3" style={{ maxHeight: '500px', height: '500px' }}>
				{loading ? (
					skeletonItems.map((_, index) => (
						<div key={index} className="d-flex align-items-center gap-2 p-2" style={{ borderRadius: '10px', backgroundColor: 'white' }}>
							<Skeleton variant="circular" width={40} height={40} />
							<div className="flex-grow-1">
								<Skeleton variant="text" height={20} width="80%" />
								<Skeleton variant="text" height={15} width="60%" />
							</div>
						</div>
					))
				) : filteredChats?.length > 0 ? (
					filteredChats?.map((chat) => (
						<article
							key={chat._id}
							className="list-group-item list-group-item-action d-flex align-items-center gap-2 p-2"
							onClick={() => handleChatClick(chat)}
							style={{
								cursor: 'pointer',
								backgroundColor: 'white',
								borderRadius: '10px',
								border: selectedChatId === chat._id ? '3px solid #F0CDB3' : 'none', // Add border to selected chat
							}}>
							{profileImage(chat)}
							<div className="flex-grow-1" style={{ padding: '0 2px' }}>
								<div className="mb-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
									{renderChatDetails(chat)}
									<p style={{ color: '#828283', fontWeight: '600' }}>{formatTime(chat?.lastMessageAt)}</p>
								</div>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<p className="mb-0" style={{ color: '#969697', fontWeight: '400' }}>
										{service.truncateText(`${chat?.lastMessage}`, 16)}
									</p>
									{renderUnread(chat)}
								</div>
							</div>
						</article>
					))
				) : (
					<div>
						<p>No conversations yet!</p>
					</div>
				)}
			</div>
		</aside>
	);
};

export default ChatList;

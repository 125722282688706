import { useEffect, useRef } from 'react';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import Navbar from 'views/_shared/navbar/Navbar';
import './css/Onboarding.style.sass';
import { navbarComp as RecruiterNav } from 'views/dashboard/recruiter/navbar-components/navbar-comp.jsx';
import { navbarComp as TalentNav } from 'views/dashboard/talent/navbar-components/navbar-comp';
import ChoosePlan from './section-1/ChoosePlan';
import SectionA from './section-2/PlanDetails';
import SectionB from './section-3/Receipt';
import { useSearchParams } from 'react-router-dom';
// import api from 'services/api';



function PremiumUpgrade() {
  const top = useRef(null);
  const userType = service.getUserType()
  const [query] = useSearchParams();


  useEffect(() => {
    if (top.current) {
      top.current.scrollIntoView({ behavior: 'smooth' });
    }
    service.setPageTitle('Premium Upgrade');
  }, []);




  return (
    <div id="Onboarding_Main_Component" ref={top}>
      {userType === service.RECRUITER ?	<Navbar {...RecruiterNav} /> : <Navbar {...TalentNav} />}

      <div className="con-margin-height">
        {query.get('section') === '1' && <ChoosePlan />}
        {query.get('section') === '2' && <SectionA  />}
        {query.get('section') === '3' && <SectionB  />}
      </div>
      <Footer />
    </div>
  );
}

export default PremiumUpgrade;

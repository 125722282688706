import Button from 'components/buttons/Button';
import ProgressBar from 'components/progress-bar/ProgressBar';
import UploadViewer from 'components/upload-viewer/UploadViewer';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import service from 'services';
import api from 'services/api';
import style from 'views/jobs/job-details/modals/css/Modal.module.sass';

function ModalBodyC({ data, callback }) {
	const { jobId } = useParams();
	const [picture, setPicture] = useState(null);
	const navigate = useNavigate();
	const [applying, setApplying] = useState(false);

	function getProfile() {
		switch (service.getUserType()) {
			case service.TALENT:
				api.talents.profile.get().then((response) => setPicture(response?.data?.result?.profile_picture?.href_link));
				return;
			case service.FREELANCER:
				api.freelancers.profile.get().then((response) => setPicture(response?.data?.result?.profile_picture?.href_link));
				return;
			default:
				break;
		}
	}

	useEffect(getProfile, []);

	const doApply = () => {
		setApplying(true);
		const formData = new FormData();

		delete data.location;
		delete data.title;

		formData.append('applicant_first_name', data['applicant_first_name']);
		formData.append('applicant_last_name', data['applicant_last_name']);
		formData.append('applicant_email', data['applicant_email']);
		// formData.append('applicant_phone_number', data['applicant_phone_number']);
		!data.cv ? delete data.cv : formData.append('cv', data['cv']);
		!data.cover_letter ? delete data.cover_letter : formData.append('cover_letter', data['cover_letter']);

		function successHandler(response) {
			const result = response?.data?.result;
			const postBody = { required_skills: data.required_skills, remote_work: data.remote_work };
			navigate('/dashboard/jobs/applied-jobs');

			if (service.getUserType() === service.TALENT) {
				api.talents.jobs.applyToJobQuestions(result._id, postBody).then(callback).catch(console.error);
			} else {
				api.freelancers.jobs.applyToJobQuestions(result._id, postBody).then(callback).catch(console.error);
			}
		}
		function onFinal() {
			setApplying(false);
		}

		if (service.getUserType() === service.TALENT) {
			service.getUserType() === service.TALENT && api.talents.jobs.applyToJob(jobId, formData).then(successHandler).catch(console.error).finally(onFinal);
		} else {
			service.getUserType() === service.FREELANCER && api.freelancers.jobs.applyToJob(jobId, formData).then(successHandler).catch(console.error).finally(onFinal);
		}
	};

	return (
		<div className={`${style['container-3']}`}>
			<div className={style.header}>
				<ProgressBar percent="100" />
			</div>

			<div className={style['con-form']}>
				<h2 className={style.title}>Review your Application</h2>

				<div className={style['con-section']}>
					<div className={style.section}>
						<h1 className={style.heading}>Contact Info</h1>

						<div>
							<div className={`${style['user-section']} d-flex align-items-start justify-content-between pb-3`}>
								<div className="d-flex align-items-center gap-3">
									<div className={style['user-img']}>
										<div className={style.bg}>
											<div className={style.img}>
												<img src={picture || '/img/pic.jpg.png'} alt="" />
											</div>
										</div>
									</div>

									<div className={style['user-info']}>
										<h1 className="pb-2">
											{data?.applicant_first_name && service.titleCase(data?.applicant_first_name)} {data?.applicant_last_name && service.titleCase(data?.applicant_last_name)}
										</h1>
										<h2 className="pb-1">{data?.title}</h2>

										<div className="d-flex align-items-center gap-1">
											<i className="fa-solid fa-location-dot"></i>
											{data?.world_wide === true ? (
												<p>{data?.world_wide && service.truncateText(service.titleCase('worldwide'))}</p>
											) : (
												<p>
													{data?.location?.state && service.titleCase(data?.location?.state)}, {data?.location?.country && service.titleCase(data?.location?.country)}
												</p>
											)}
										</div>
									</div>
								</div>

								<div>
									<Button
										small
										text={'Edit'}
										primaryNull
										{...{
											modal: true,
											doDismissModal: true,
											modalTarget: 'job-details-modal-a',
										}}
									/>
								</div>
							</div>

							<div className="pb-2">
								<p className={style['title-tag']}>Email Address</p>
								<h1 className={style['body-tag']}>{data?.applicant_email || `jamesjoe@gmail.com`}</h1>
							</div>

							{/* <div className="py-2">
								<p className={style['title-tag']}>Country Code</p>
								<h1 className={style['body-tag']}>Nigeria(+234)</h1>
							</div>

							<div className="py-2">
								<p className={style['title-tag']}>Phone Number</p>
								<h1 className={style['body-tag']}>{data?.applicant_phone_number || `802343232`}</h1>
							</div> */}

							<div className="py-2 d-lg-flex align-items-center gap-5">
								{data?.cv && (
									<div className="mb-3 mb-lg-0">
										<p className={`${style['title-tag']} pb-2`}>Resume</p>
										<UploadViewer filename={data?.cv?.name} />
									</div>
								)}

								{data?.cover_letter && (
									<div>
										<p className={`${style['title-tag']} pb-2`}>Cover Letter</p>
										{data?.cover_letter && <UploadViewer filename={data?.cover_letter?.name} />}
									</div>
								)}
							</div>
						</div>
					</div>

					<div className={style.section}>
						<h1 className={style.heading}>Questions</h1>

						<div className="d-flex align-items-start justify-content-between">
							<div>
								{data?.required_skills?.length &&
									data?.required_skills?.map((skill, idx) => (
										<div className="pt-3 pb-2" key={idx}>
											<p className={style['title-tag']}>
												How many years of work experience do you have with <u>{skill?.name}</u>?
											</p>
											<h1 className={style['body-tag']}>{skill?.experience_years}</h1>
										</div>
									))}

								<div className="pt-3 pb-2">
									<p className={style['title-tag']}>Are you willing to work remotely?</p>
									<h1 className={style['body-tag']}>{data?.remote_work === 'true' ? 'Yes' : 'No'}</h1>
								</div>
							</div>

							<div>
								<Button
									{...{
										modal: true,
										doDismissModal: true,
										modalTarget: 'job-details-modal-b',
									}}
									small
									text="Edit"
									primaryNull
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="footer d-lg-flex align-items-center gap-3 p-4">
				<div className="mb-4 mb-lg-0">
					<Button
						small
						text={<h2>One Step Back</h2>}
						primaryNull
						{...{
							modal: true,
							doDismissModal: true,
							modalTarget: 'job-details-modal-a',
						}}
					/>
				</div>
				<div className="mb-4 mb-lg-0">
					<Button
						small
						disabled={applying === true}
						text={
							<>
								<h2>Submit Application </h2>
								<i className="fa-solid fa-arrow-right"></i>
							</>
						}
						{...{
							doDismissModal: false,
						}}
						onClick={doApply}
					/>
				</div>
			</div>
		</div>
	);
}

export default ModalBodyC;

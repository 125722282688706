import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import service from 'services';
import Footer from 'views/_shared/footer/Footer';
import RecruiterProfile from './recruiter/Profile';
import TalentProfile from './talent/Profile';

function Profile() {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);
	service.setPageTitle('Profile');

	const ACTIVE_ROLE = service.getUserType();
	const [query] = useSearchParams();

	console.log('here is value of query {KINDLY IGNORE FOR NOW} >>>>', query.get('view'));

	return (
		<div ref={top}>
			{ACTIVE_ROLE === service.RECRUITER && <RecruiterProfile />}
			{(ACTIVE_ROLE === service.TALENT || ACTIVE_ROLE === service.FREELANCER) && <TalentProfile />}

			<Footer />
		</div>
	);
}

export default Profile;


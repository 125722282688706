import Button from 'components/buttons/Button';
import { useEffect, useRef, useState } from 'react';
import service from 'services';
import './css/SectionA.style.sass';
import api from 'services/api';

function SectionA({ context, isLoading, callback }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const errorRef = useRef(undefined);

	const [talent, setTalent] = useState('');
	const [jobTitles, setJobTitles] = useState([]);
	const [selectedTalent] = useState([]);
	const [throwError, setThrowError] = useState(false);

	const page = 1;
	const limit = 10;

	useEffect(() => {
		api.recruiter
			.getJobTitles(page, limit)
			.then((res) => { setJobTitles(res.data?.result);})
			.catch((res) => console.log('main', res));
	}, [page, limit]);

	useEffect(()=>{
		setTalent(context?.title)
	},[context.title])

	function handleSuggestion(value) {
		setTalent(value);
	}

	// const removeActiveState = () => {
	// 	document.querySelectorAll('#con-talent-box').forEach((x) => x.classList.remove('active'));
	// };

	// const setActiveState = (index) => {
	// 	document.querySelectorAll('#con-talent-box')[index].classList.add('active');
	// };

	// const doSelectTalent = ({ currentTarget: ctg }, index) => {
	// 	if (!talent) {
	// 		removeActiveState();
	// 		setThrowError(false);
	// 		if (selectedTalent.some((x) => x.id === index)) {
	// 			ctg.classList.remove('active');
	// 			setSelectedTalent(selectedTalent.filter((x) => x.id !== index));
	// 		} else {
	// 			ctg.classList.add('active');
	// 			setSelectedTalent([{ title: allTalents[index].title, id: index }]);
	// 		}
	// 	}
	// };

	const handleTalentValue = ({ target: { value } }) => {
		if (!selectedTalent.length) {
			setThrowError(false);
			setTalent(value);
		}
	};

	const doSubmit = () => {
		if (!selectedTalent.length && !talent) {
			setThrowError(true);
			service.goTo(errorRef);
		} else {
			let body = selectedTalent[0]?.title || talent;
			callback(body);
		}
	};

	return (
		<div id="JobFlow_SectionA_Main_Container" ref={top}>
			<div className="section">
				<div className="con-context container-01" ref={errorRef}>
					<div className="my-5">
						<div className="context">
							<div className="title">
								<h1 className="mb-3">Get your Desired Talents come to you</h1>
								<h2 style={{ color: `${throwError ? 'red' : ''}` }}>Choose a skill</h2>
							</div>

							<div className="con-talent">
								{jobTitles.map(({ title }, idx) => {
									return (
										<div className="title_tag px-2 py-2 gap-3 text-center " style={{ borderColor: '#BD5A12', color: '#BD5A12', cursor: 'pointer', fontWeight: 700 }} key={idx} onClick={() => handleSuggestion(title)}>
											<span>{title}</span>
										</div>
									);
								})}
							</div>
						</div>

						<div className="con-search">
							<p className="title">Skill not found? Enter your preference</p>
							<div className="con-input">
								<input type="text" placeholder="Add Skills" value={talent} onChange={handleTalentValue} disabled={!!selectedTalent.length} />
							</div>
						</div>

						<div>
							<Button
								text={
									<>
										<h1>Next</h1>
										<i className="fa-solid fa-arrow-right"></i>
									</>
								}
								disabled={isLoading}
								onClick={doSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionA;

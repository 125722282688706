import Button from 'components/buttons/Button';
import { useState } from 'react';
import style from './css/Modal.module.sass';
import { useLocation } from 'react-router-dom';

function Modal({ callback }) {
	const contractType = ['Full-Time', 'Part-time', 'Contract'];
	const location=useLocation()


	const [form, setForm] = useState({
		job_title: '',
		contract_type: 1,
		company: '',
		start_date: '',
		end_date: '',
		location: '',
	});

	const [formErrors, setFormErrors] = useState(form);

	const [isPresent, setIsPresent] = useState(false);
	// Function to handle the "Present" checkbox click
	function handlePresentCheckbox() {
		setIsPresent(!isPresent);
		if (!isPresent) {
			setForm({ ...form, end_date: 'present' });
		} else {
			setForm({ ...form, end_date: '' });
		}
	}

	function handleOnChange(e) {
		const name = e.target.name;
		const value = e.target.value;
		setForm({ ...form, [`${name}`]: value });
		setFormErrors({ ...formErrors, [`${name}`]: `` });
	}

	function configFields(form) {
		form.contract_type = contractType[form.contract_type - 1]?.toLowerCase();
	}

	function hasErrors() {
		for (const field in form) {
			if (!form[field]) {
				validationHandler(field);
				console.warn(`{${field}} field is required`);

				return true;
			} else if (validations()) {
				return true;
			}
		}

		return false;
	}

	function validationHandler(field, errorMsg = 'field is required') {
		const formElement = document.forms[0].elements;
		formElement[field]?.focus();
		formElement[field]?.scrollIntoView({ block: 'center' });
		setFormErrors({ ...formErrors, [`${field}`]: errorMsg });
		console.log('value of field >>>>>', formElement);
	}

	function validations() {
		return false;
	}

	function handleOnSubmit() {
		console.log(location)
		if (!hasErrors()) {
			configFields(form);
			callback(form);
			setForm({ job_title: '', contract_type: 1, company: '', location: '', start_date: '', end_date: '' });
		}
		// if(location=== 'onboarding?section=12'  && !hasErrors()){
		// 	const data=[form]
		// 	api.talents.profile.update(data).then(successHandler).catch(errorHandler).finally(finalHandler);

		// }
	}

	function exitModal() {
		return {
			doDismissModal: true,
		};
	}

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
};

	return (
		<div>
			<form>
				<div className={style['con-form']}>
					<div className="d-flex align-items-lg-center flex-lg-nowrap flex-wrap gap-lg-3">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Job Title*</label>
							</div>

							<input type="text" placeholder="Enter Job title" name="job_title" value={form.job_title} onChange={handleOnChange} className={formErrors.job_title ? 'error' : ''} />
							<label className="error-msg mb-3">{formErrors.job_title}</label>
						</div>

						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Company Name*</label>
							</div>

							<input type="text" placeholder="Enter Company Name" name="company" value={form.company} onChange={handleOnChange} className={formErrors.company ? 'error' : ''} />
							<label className="error-msg mb-3">{formErrors.company}</label>
						</div>
					</div>

					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label className="d-flex align-items-center gap-2">
							
								Contract type
							</label>

							<label className="error-msg mb-3">{formErrors.location}</label>
						</div>

						<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
							{contractType.map((x, y) => (
								<Button small text={x} darkFill primaryNull={form.contract_type !== y + 1} onClick={() => setForm({ ...form, contract_type: y + 1 })} key={y} />
							))}
						</div>
					</div>

					<div className={`control-form ${style['con-date']}`}>
						<div className="d-flex flex-column flex-lg-row justify-content-start gap-3">
							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>Start Date*</label>
								</div>

								<input type="date" name="start_date" value={form.start_date} onChange={handleOnChange} className={formErrors.start_date ? 'error' : ''} max={getCurrentDate()}/>
								<label className="error-msg mb-3">{formErrors.start_date}</label>
							</div>

							<div className="w-100">
								<div className="d-flex justify-content-between">
									<label>End Date*</label>
								</div>

								<input type="date" name="end_date" value={form.end_date} onChange={handleOnChange} className={formErrors.end_date ? 'error' : ''} disabled={isPresent} min={form.start_date}/>
								<label className="error-msg mb-3">{formErrors.end_date}</label>
							</div>
						</div>
						<div className="d-flex gap-1 align-items-baseline">
							<input type="checkbox" checked={isPresent} onChange={handlePresentCheckbox} style={{ width: '30px' }} />
							<label className="fw-bold">Presently working here</label>
						</div>
					</div>

					<div className="d-flex align-items-lg-center flex-column flex-lg-row gap-lg-3">
						<div className="control-form">
							<div className="d-flex justify-content-between">
								<label>Location</label>
							</div>

							<input type="text" placeholder="Enter Location (e.g. Lagos, Nigeria)" name="location" value={form.location} onChange={handleOnChange} className={formErrors.location ? 'error' : ''} />
								<label className="error-msg mb-3">{formErrors.location}</label>
						</div>
					</div>

					<div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
						<div data-bs-dismiss="modal">{<Button text={<h2>Back</h2>} primaryNull />}</div>

						{!form.job_title || !form.contract_type || !form.company || !form.location || !form.start_date || !form.end_date ? (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								onClick={handleOnSubmit}
							/>
						) : (
							<Button
								text={
									<>
										<i className="fa-regular fa-floppy-disk"></i>
										<h2>Save</h2>
									</>
								}
								{...exitModal()}
								onClick={handleOnSubmit}
							/>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}

export default Modal;

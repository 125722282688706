import Button from "components/buttons/Button";
import { Link } from "react-router-dom";
import service from "services";

export default function Context() {
  return {
    rightContext: (
      <div className="d-flex align-items-center justify-content-between">
        <Button small emptyFill text="Home" redirect="/" />
        <Button small text="Sign up" />
        <Button small primaryEmptyFill text="Login" redirect="/login" />
      </div>
    ),
    sidebarContext: (
      <ul id="con-navbar-sidebar">
        <Link to="">
          <li>Discover Talents</li>
        </Link>
        <Link to="">
          <li>Discover Talents</li>
        </Link>
        <Link to="">
          <li>About {service.brand.name}s</li>
        </Link>
        <Link to="/register" className="active">
          <li>Register</li>
        </Link>
        <Link to="/login" className="active">
          <li>Login</li>
        </Link>
      </ul>
    ),
  };
}

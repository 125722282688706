import s from "./css/ProgressBar.module.sass";

function ProgressBar({ percent }) {
  return (
    <div className={s.progressBar}>
      <main>
        <div className={s.background}>
          <div
            className={s.bar}
            style={{ width: `${percent === `100` ? 98 : percent}%` }}
          ></div>
        </div>

        {percent === `100` && (
          <div className={s.check}>
            <i className="fa-solid fa-check"></i>
          </div>
        )}

        <h2>{percent}&#37;</h2>
      </main>
    </div>
  );
}

ProgressBar.defaultProps = {
  percent: 0,
};

export default ProgressBar;

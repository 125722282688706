import { globalEvents } from 'components/events';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import service from 'services';
import Dashboard from 'views/dashboard/Dashboard';
import JobFlow from 'views/dashboard/recruiter/job-flow/JobFlow';
import Search from 'views/dashboard/search/Search';
import Homepage from 'views/homepage/Homepage';
import JobEdit from 'views/jobs/job-details/job-edit/JobEdit';
import JobDetails from 'views/jobs/job-details/JobDetails';
import SavedJobs from 'views/jobs/saved-jobs/SavedJobs';
import Login from 'views/login/Login';
import Onboarding from 'views/onboarding/Onboarding';
import Profile from 'views/profile/Profile';
import TalentProfile from 'views/profile/talent/Profile';
import CreateProject from 'views/project/create-project/CreateProject';
import Project from 'views/project/Project';
import ProjectDetails from 'views/project/project-details/ProjectDetails';
import Register from 'views/register/Register';
import VerifyAccount from 'views/register/verify-account/VerifyAccount';
import Socials from 'views/social-success/Socials';
import SocialsLogin from 'views/social-success/SocialsLogin';
import RecommendedJobs from 'views/jobs/recommended-jobs/RecommendedJobs';
import EditProject from 'views/project/edit-project/EditProject';
import RecruiterViewTalent from 'views/recruiter-view-talent/talent/Profile';
import AppliedJobs from 'views/jobs/applied-jobs/AppliedJobs';
import Aboutus from 'views/aboutus/Aboutus';
import ResetPassword from 'components/forms/resetpassword/ResetPassword';
import Forgetpassword from 'components/forms/forgetpassword-form/Forgetpassword';
import ResetSuccessPage from 'components/forms/reset-success-page/ResetSuccessPage';
import AppliedJobsViewByRecruiter from 'views/jobs/recruiter-view-applied-jobs/AppliedJobsViewByRecruiter';
import Applicants from 'views/jobs/applicants/Applicants';
import Hire from 'views/hire/Hire';
import PostedJobsViewByRecruiter from 'views/jobs/recruiter-view-posted-jobs/PostedJobsViewByRecruiter';
import JobCriteria from 'views/jobs/job-details/JobCriteria';
import Conversations from 'views/chat/Conversations';
import PremiumUpgrade from 'views/premium-upgrade/PremiumUpgrage';
import PromoteJob from 'views/promote-job/PromoteJob';

function App() {
	const [hasToken, setHasToken] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(true);

	const authChecker = () => {
		if (service.getTokensLocalStorage() === null) authHandler(false);
	};

	const authHandler = (boolean) => {
		setHasToken(boolean);
		setIsAuthenticated(boolean);
	};
	// useState(authChecker);
	useEffect(() => {
		authChecker();
	});

	return (
		<div id="body" onClick={globalEvents}>
			<Routes>
				<Route path="" element={<Homepage />} />
				<Route path="aboutus" element={<Aboutus />} />
				<Route path="login" element={<Login isLogin={authHandler} />} />
				<Route path="verifyemail" element={<Forgetpassword />} />
				<Route path="reset-password" element={<ResetPassword />} />
				<Route path="resetpasswordsuccess" element={<ResetSuccessPage />} />

				<Route path="register" element={<Register handleAuth={authHandler} />} />
				<Route path="verify-account" element={<VerifyAccount />} />
				<Route path="auth/:userType" element={<Socials />} />
				<Route path="auth/generic" element={<SocialsLogin />} />

				<Route element={isAuthenticated && hasToken ? <>{<Outlet />}</> : <Navigate to="/login" />}>
					<Route path="onboarding" element={<Onboarding />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="dashboard/conversations/:recruiterId/:talentId" element={<Conversations />} />
					<Route path="dashboard/conversations" element={<Conversations />} />
					<Route path="dashboard/jobs/:jobId" element={<JobDetails />} />
					<Route path="dashboard/jobs/criteria/:jobId" element={<JobCriteria />} />
					<Route path="dashboard/jobs/create" element={<JobFlow />} />
					<Route path="dashboard/jobs/promote/:jobId" element={<PromoteJob />} />
					<Route path="dashboard/jobs/edit/:jobId" element={<JobEdit />} />
					<Route path="dashboard/profile" element={<Profile />} />
					<Route path="dashboard/talents/profile/:talentId" element={<TalentProfile />} />
					<Route path="dashboard/talents-profile/:jobId/:talentId" element={<RecruiterViewTalent />} />
					<Route path="dashboard/talents-profile/:talentId" element={<RecruiterViewTalent />} />
					<Route path="dashboard/jobs/recommended-jobs" element={<RecommendedJobs />} />
					<Route path="dashboard/jobs/saved-jobs" element={<SavedJobs />} />
					<Route path="dashboard/jobs/applied-jobs" element={<AppliedJobs />} />
					<Route path="dashboard/jobs/applied" element={<AppliedJobsViewByRecruiter />} />
					<Route path="dashboard/jobs/posted-jobs" element={<PostedJobsViewByRecruiter />} />
					<Route path="dashboard/jobs/applicants/:jobId" element={<Applicants />} />
					<Route path="dashboard/jobs/recruiter/hire/:jobId/:talentId" element={<Hire />} />
					<Route path="dashboard/jobs/drafts" element={<SavedJobs />} />
					<Route path="dashboard/projects/edit/:projectId" element={<EditProject />} />
					<Route path="dashboard/projects/create" element={<CreateProject />} />
					<Route path="dashboard/projects" element={<Project />} />
					<Route path="dashboard/to-premium" element={<PremiumUpgrade />} />
					<Route path="dashboard/search" element={<Search />} />
					<Route path="dashboard/projects/:projectId" element={<ProjectDetails />} />
				</Route>
			</Routes>
		</div>
	);
}

export default App;

import Button from 'components/buttons/Button';
import React, { useState } from 'react';
import api from 'services/api';

const SendUsMailModal = () => {
	const [details, setDetails] = useState({
		fullname: '',
		subject: '',
		email: '',
		phone: '',
		description: '',
	});

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setDetails((prev) => {
			return { ...prev, [name]: value };
		});
	};
	const handleOnSubmit = () => {
		const isEmptyField = Object.values(details).some((value) => value.trim() === '');

		if (isEmptyField) {
			alert('Please fill out all fields.');
			return;
		}
		api.contactUs
			.advertise(details)
			.then((res) => {
				alert('Advert request sent')
				const modalCloseButton = document.querySelector('[data-bs-dismiss="modal"]');
				if (modalCloseButton) {
					modalCloseButton.click();
				}
			})
			.catch((err) => console.log(err));
	};

	return (
		<form className="" style={{ padding: '24px' }}>
			<div className="row">
				<div className="col-lg-6">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Company Name *</label>
						</div>

						<input type="text" placeholder="Enter Company Name" name="fullname" onChange={handleChange} />
						{/* <label className="error-msg mb-3">{formErrors.school}</label> */}
					</div>
				</div>
				<div className="col-lg-6">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Subject *</label>
						</div>
						<input type="text" placeholder="Enter Subject here" name="subject" onChange={handleChange} />
					</div>
				</div>
				<div className="col-lg-6">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Email Address *</label>
						</div>

						<input type="email" placeholder="Enter Email Address" name="email" onChange={handleChange} />
						{/* <label className="error-msg mb-3">{formErrors.school}</label> */}
					</div>
				</div>
				<div className="col-lg-6">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Phone Number *</label>
						</div>

						<input type="number" placeholder="Phone Number" name="phone" onChange={handleChange} />
						{/* <label className="error-msg mb-3">{formErrors.school}</label> */}
					</div>
				</div>
				<div className="col-lg-12">
					<div className="control-form">
						<div className="d-flex justify-content-between">
							<label>Description *</label>
						</div>

						<textarea rows={4} name="description" id="" placeholder="What do you want to write about?" onChange={handleChange}></textarea>
						{/* <label className="error-msg mb-3">{formErrors.school}</label> */}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-end gap-2">
				<div data-bs-dismiss={`modal`}>
					<Button text="Cancel" primaryNull />
				</div>
				<Button icon={<i className="fa-regular fa-floppy-disk"></i>} text={'Save'} onClick={handleOnSubmit} />
			</div>
		</form>
	);
};

export default SendUsMailModal;

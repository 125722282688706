import Button from 'components/buttons/Button';
import LoaderButton from 'components/loader/loader-btn/LoaderButton';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import service from 'services';
// import api from 'services/api';
import './css/SectionC.style.sass';
import Tab from 'components/buttons/tabs/Tabs';

function SectionB({ hire, setHire, job,  }) {
	const top = useRef(undefined);
	useEffect(() => service.goTo(top), []);

	const navigate = useNavigate();
	const [isLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setHire(prevHire => ({
			...prevHire,
			[name]: value
		}));
	};

	const validateAndNavigate = () => {
    const { duration, salary, numberOfPositions } = hire;
    if (!duration || !salary || !numberOfPositions) {
      alert('All fields must be filled out.');
      return;
    }
    if (salary < job?.expected_pay) {
      alert(`Salary must be at least ${job?.expected_pay}.`);
      return;
    }
    // If validation passes, navigate to the next section
    navigate({ search: `?section=4` });
  };

	return (
		<div id="Onboarding_Talent_SectionC_Main_Component" ref={top} style={{ backgroundColor: '#FFFBF2', paddingBottom: '30px' }}>
			<div className="con-header">
				<div className="container-01 mb-5">
					<div className="header d-lg-flex align-items-start">
						<div className="col-12 col-lg-7">
							<div className="mb-3 d-flex align-items-center gap-1" style={{ color: '#CC5600', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate(-1)}>
								<i class="fa-solid fa-chevron-left"></i>
								<p>Back</p>
							</div>

							<h5>Welcome to Recruitment Wizard</h5>

							<div className='d-flex' style={{ gap: '20px' }}>
								<h1 className="mb-3 display">Let's get your Talent onboard!</h1>
								<img src="/img/svg/rocket.svg" alt="rocket" style={{ width: '60px' }} />
							</div>
						</div>

						<div className="col-12 col-lg-5">
							<div className="">
								<ProgressBar percent="50" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-01" style={{ backgroundColor: 'white', padding: '15px', borderRadius: '15px' }}>
				<div className="con-form">
					<div className="form">
						<form>

							<div className='d-lg-flex justify-content-between p-2'>
								<article>
									<h4>Select Terms</h4>
									<p>Ensure you review Criteria before selecting candidates</p>
								</article>
								<Button text={'Review Criteria'} secondaryNull onClick={()=>navigate(`/dashboard/jobs/criteria/${job?._id}`)}/>
							</div>

							<div className="d-lg-flex justify-content-between gap-2">
								<div className="control-form">
									<div className="d-flex justify-content-between">
										<label>Talent type</label>
									</div>
									<div className="d-flex align-items-center gap-1 mb-3">
										<Tab primaryOutline context={hire?.talentType} key={'y'} callback={''} />
									</div>
								</div>
							</div>

							<div className='my-4' style={{ border: '1px solid #2E2E30' }}></div>

							<div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
								<div className="control-form">
									<div className="d-lg-flex justify-content-between">
										<label>Nos of Talents required</label>
									</div>

									<input type="number" placeholder="Select the number of talent you need for this role" name="numberOfPositions" value={hire.numberOfPositions} onChange={handleChange} className={''} /*className={'formErrors.onsite_experience' ? 'error' : ''} */ min={1} />
									{/* <label className="error-msg mb-3">{'formErrors.onsite_experience'}</label> */}
								</div>

								<div className="control-form">
									<div className="d-lg-flex justify-content-between">
										<label>Duration (months)</label>
									</div>

									<input type="text" placeholder="6 months, 12 months" name="duration" value={hire.duration} onChange={handleChange} className={''} /*className={'formErrors.onsite_experience' ? 'error' : ''} */ min={1} />
									{/* <label className="error-msg mb-3">{'formErrors.remote_experience'}</label> */}
								</div>

								<div className="control-form">
									<div className="d-lg-flex justify-content-between">
										<label>Salary (per month)</label>
									</div>

									<input type="number" placeholder="5000" name="salary" value={hire.salary} onChange={handleChange} className={''} /*className={'formErrors.onsite_experience' ? 'error' : ''} */ min={job.expected_pay} />
									{/* <label className="error-msg mb-3">{'formErrors.onsite_experience'}</label> */}
								</div>
							</div>


							{/* Footer Buttons */}
							<div className="control-form d-lg-flex gap-3">
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<h1>Review Criteria</h1>
											</>
										}
										secondaryNull
										onClick={() => navigate(`/dashboard/jobs/criteria/${job?._id}`)}
									/>
								</div>
								<div className="mb-4 mb-lg-0">
									<Button
										text={
											<>
												<i className="fa-solid fa-arrow-left"></i>
												<h1>Back</h1>
											</>
										}
										primaryNull
										onClick={() => navigate(-1)}
									/>
								</div>

								<div className="mb-4 mb-lg-0">
									<Button text={<LoaderButton isLoading={isLoading} arrowDirection={<i className="fa-solid fa-arrow-right"></i>} text="Next" />} onClick={validateAndNavigate} disabled={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SectionB;

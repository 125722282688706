import Button from 'components/buttons/Button';
import JobCard from 'components/cards/job-card/JobCard';
import Message from 'components/message-api/Message';
import Modal from 'components/modals/Modal';
import DotPagination from 'components/pagination/pagination-dot/DotPagination';
import IndexPagination from 'components/pagination/pagination-index/IndexPagination';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import service from 'services';
import api from 'services/api/index.js';
import classNames from 'utils/conditional-css-classes/classNames';
import Navbar from 'views/_shared/navbar/Navbar.jsx';
import ProfileLevel from 'views/dashboard/components/profile-level/ProfileLevel';
import ContractPreferenceModal from 'views/profile/talent/modals/ContractPreferenceModal';
import './css/Talent.style.sass';
import './css/Talent.css';

import { navbarComp } from './navbar-components/navbar-comp';
import FeedBackModal from 'views/profile/talent/modals/FeedBackModal';
import SuccessModal from 'views/profile/talent/modals/SuccessModal';
import CurrencyInput from 'react-currency-input-field';
import currencyToSymbolMap from 'currency-symbol-map/map';

function Talent() {
	service.setPageTitle('Talent Dashboard');
	const userType = service.getUserType();

	const allCurrency = Object.keys(currencyToSymbolMap);

	const cardARef = useRef(undefined);
	const [jobAvailable, setJobAvailable] = useState(false);

	const [recommended, setRecommended] = useState([]);
	const [appliedJobs, setAppliedJobs] = useState([]);
	const [editRate, setEditRate] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState('USD');
	const [badges, setBadges] = useState([]);
	const [spaces, setSpaces] = useState({});

	const [user, setUser] = useState({});
	const [rate, setRate] = useState('');
	const [invite, setInvite] = useState('Send Invite');
	const [talent, setTalent] = useState('');

	const [complete, setComplete] = useState({});
	const contractPreference = useMemo(() => ['full-time', 'part-time'], []);
	const [contractPreferenceIdx, setContractPreferenceIdx] = useState(0);

	const [isLoading, setIsLoading] = useState(true);
	const [isModalLoading, setModalIsLoading] = useState(false);

	const [isLoadingUser, setIsLoadingUser] = useState(true);
	const [message, setMessage] = useState([]);
	const [search, setSearch] = useState('');

	const [showModal, setShowModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [premiumUser, setPremiumUser] = useState({});

	const navigate = useNavigate();
	const inputRateRef = useRef(null);
	const [hoveredIndex, setHoveredIndex] = useState(null);

	const [feedback, setFeedback] = useState({
		description: '',
		reason: '',
	});

	const backgroundStyle = {
		background: premiumUser ? `url('/img/svg/premium.svg')` : '#FFFCF3',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		height: '196px',
		width: '100%',
		boxShadow: `0px 4px 4px 0px #00000040 inset`,
	};

	const premiumGold = {
		padding: '6px 6px',
		background: `${premiumUser?.status === 'active' ? `linear-gradient(269.69deg, #BF953F -4.28%, #F4EB91 21.07%, #B38728 50.72%, #E7E097 76.07%, #AA771C 103.57%)` : `#CC5600`}`,
		height: 'fit-content',
		borderRadius: '10px',
	};

	const badgeImgStyle = {
		position: 'relative',
		display: 'inline-block',
	};

	const badgeStyle = {
		background: premiumUser?.status === 'active' ? '#FFFFFF7D' : `#ffffff`,
		minHeight: '34px',
	};

	const hoverTextStyle = {
		display: 'none', // Initially hide the text
		position: 'absolute',
		top: '100%', // Position the text below the image
		left: '50%',
		transform: 'translateX(-50%)',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		color: 'white',
		padding: '5px',
		borderRadius: '3px',
		whiteSpace: 'nowrap',
		zIndex: 1,
	};

	const star = ({ color }) => {
		return (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_d_5923_19478)">
					<path
						d="M9.31628 0.564844L10.3678 2.96328C10.4074 3.05355 10.4705 3.13154 10.5504 3.18912C10.6304 3.2467 10.7244 3.28175 10.8225 3.29063L13.3842 3.52031C13.6741 3.5625 13.7897 3.91797 13.5796 4.12266L11.6499 5.74375C11.4936 5.875 11.4225 6.08125 11.4655 6.28047L12.0264 8.90625C12.0757 9.19453 11.7733 9.41484 11.5139 9.27813L9.278 7.96875C9.19367 7.91925 9.09766 7.89316 8.99987 7.89316C8.90209 7.89316 8.80608 7.91925 8.72175 7.96875L6.48581 9.27734C6.22722 9.41328 5.92409 9.19375 5.97331 8.90547L6.53425 6.27969C6.57644 6.08047 6.50612 5.87422 6.34987 5.74297L4.41941 4.12344C4.21003 3.91953 4.32566 3.56328 4.61472 3.52109L7.17644 3.29141C7.27459 3.28253 7.36855 3.24748 7.44853 3.1899C7.52851 3.13232 7.59157 3.05433 7.63112 2.96406L8.68269 0.565625C8.81316 0.303125 9.18659 0.303125 9.31628 0.564844Z"
						fill={color}
					/>
				</g>
				<path
					d="M9.2396 3.10713L9.06147 1.33994C9.05444 1.2415 9.03413 1.07275 9.19194 1.07275C9.31694 1.07275 9.38491 1.33291 9.38491 1.33291L9.91929 2.75166C10.1208 3.2915 10.038 3.47666 9.84351 3.58604C9.62007 3.71104 9.29038 3.61338 9.2396 3.10713Z"
					fill={color}
				/>
				<path
					d="M11.4438 5.58662L12.9766 4.39053C13.0524 4.32725 13.1891 4.22646 13.0797 4.11162C12.993 4.021 12.7586 4.15146 12.7586 4.15146L11.4172 4.67568C11.0172 4.81396 10.7516 5.01865 10.7281 5.27646C10.6977 5.62021 11.0063 5.88506 11.4438 5.58662Z"
					fill={color}
				/>
				<defs>
					<filter id="filter0_d_5923_19478" x="0.3125" y="0.368652" width="17.3745" height="16.9512" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
						<feOffset dy="4" />
						<feGaussianBlur stdDeviation="2" />
						<feComposite in2="hardAlpha" operator="out" />
						<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5923_19478" />
						<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5923_19478" result="shape" />
					</filter>
				</defs>
			</svg>
		);
	};

	const hoverTextVisibleStyle = {
		...hoverTextStyle,
		display: 'block',
	};

	const handleFeedbackChange = (e) => {
		const { name, value } = e.target;
		setFeedback((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// the pagination for this page
	const itemsPerPage = 2;
	const [recommendedPageIndex, setRecommendedPageIndex] = useState(0);
	// const [appliedPageIndex, setAppliedPageIndex] = useState(0);

	const handleRecommendedPageChange = (newPageIndex) => {
		setRecommendedPageIndex(newPageIndex);
	};

	// const handleAppliedPageChange = (newPageIndex) => {
	// 	setAppliedPageIndex(newPageIndex);
	// };

	const getDisplayedJobs = (jobs, pageIndex) => {
		const startIndex = pageIndex * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return jobs.slice(startIndex, endIndex);
	};

	const [currentPage, setCurrentPage] = useState(1);

	const pageCount = Math.ceil(recommended.length / 4);

	const onPageChange = (newPage) => {
		if (newPage >= 1 && newPage <= pageCount) {
			setCurrentPage(newPage);
		}
	};

	const startIndex = (currentPage - 1) * 4;
	const endIndex = startIndex + 4;
	const displayedJobs = recommended.slice(startIndex, endIndex);

	const displayedRecommendedJobs = getDisplayedJobs(recommended, recommendedPageIndex);

	// const displayedAppliedJobs = getDisplayedJobs(appliedJobs, appliedPageIndex);

	function getJobs() {
		function successHandler(response) {
			const result = response?.data?.result;
			setRecommended(result.filter((job) => job?.status === 'published' && job?.isJobApproved !== false));
		}

		function onFinal() {
			setIsLoading(false);
		}

		switch (service.getUserType()) {
			case service.TALENT:
				api.talents.jobs.recommended().then(successHandler).catch(console.error).finally(onFinal);
				api.talents.jobs
					.appliedJobs()
					.then((res) => setAppliedJobs(res?.data?.result))
					.catch(console.error)
					.finally(onFinal);
				return;
			case service.FREELANCER:
				api.freelancers.jobs.recommended().then(successHandler).catch(console.error).finally(onFinal);
				api.freelancers.jobs
					.appliedJobs()
					.then((res) => setAppliedJobs(res?.data?.result))
					.catch(console.error)
					.finally(onFinal);
				return;
			default:
				break;
		}
	}

	function getUser() {
		function successHandler() {
			return [
				(response) => {
					const result = response?.data?.result;
					const index = contractPreference.findIndex((contract) => contract.toLowerCase() === result?.full_time_decision?.toLowerCase());

					setUser(result);
					// setIsVisible(result.isVisible);
					setContractPreferenceIdx(index);
					setJobAvailable(result?.isAvailable);
				},
				(response) => {
					const result = response?.data?.result;
					setComplete(result);
				},
			];
		}

		function handleError(response) {
			try {
				if (response.data.success === false) {
					service.reloadPage();
				}
			} catch (error) {
				console.error('An error occurred:', error);
			}
		}

		function onFinal() {
			setIsLoadingUser(false);
		}

		if (userType === 'talent') {
			api.talents.profile.get().then(successHandler()[0]).catch(handleError).finally(onFinal);
			api.talents.profile.completeStatus().then(successHandler()[1]).catch(handleError).finally(onFinal);
		} else {
			api.freelancers.profile.get().then(successHandler()[0]).catch(handleError).finally(onFinal);
			api.freelancers.profile.completeStatus().then(successHandler()[1]).catch(handleError).finally(onFinal);
		}
	}

	useEffect(() => {
		if (user) {
			setRate(user?.expected_income);
			setSelectedCurrency(user?.expected_salary_currency);
		}
	}, [user]);

	function getBadges() {
		function handleSuccess(response) {
			setBadges(response.data);
		}
		function handleError(response) {
			console.log(response);
		}

		if (userType === 'talent') {
			api.talents.getBadges(user?.id).then(handleSuccess).catch(handleError).finally();
		} else {
			api.freelancers.getBadges(user?.id).then(handleSuccess).catch(handleError).finally();
		}
	}

	function getPremiumDetails() {
		api.talents.subscriptions
			.getSubscriptions()
			.then((res) => {
				setPremiumUser(res.data);
			})
			.catch(console.error);
	}
	useEffect(() => {
		getPremiumDetails();
	}, []);

	useEffect(getJobs, [userType]);

	useEffect(() => {
		if (user?.id) {
			getBadges();
		}
		// eslint-disable-next-line
	}, [user, userType]);
	useEffect(getUser, [userType, contractPreference]);

	const handleCardAScroll = () => {
		if (cardARef.current.scrollLeft % 3 === 0) {
		} else if (cardARef.current.scrollLeft % 2 === 0) {
		} else {
		}
	};

	function doHandleAvailability() {
		setJobAvailable(!jobAvailable);

		function successHandler(response) {
			const result = response?.data?.result;
			setJobAvailable(result.isAvailable);
		}

		if (userType === 'talent') {
			api.talents.profile.update({ isAvailable: !jobAvailable }).then(successHandler).catch(console.error);
		} else {
			api.freelancers.profile.update({ isAvailable: !jobAvailable }).then(successHandler).catch(console.error);
		}
	}
	function doHandleContractPreference(idx) {
		setContractPreferenceIdx(idx);

		function successHandler(response) {
			const result = response?.data?.result;
			const index = contractPreference.findIndex((contract) => contract.toLowerCase() === result?.full_time_decision?.toLowerCase());
			setContractPreferenceIdx(index);
		}

		if (userType === 'talent') {
			api.talents.profile.update({ full_time_decision: contractPreference[idx] }).then(successHandler).catch(console.error);
		} else {
			api.freelancers.profile.update({ full_time_decision: contractPreference[idx] }).then(successHandler).catch(console.error);
		}
	}

	function selectContractPreference({ idx }) {
		return {
			modal: true,
			isStatic: true,
			modalTarget: `update-contract-preference-${idx}`,
			modalTitle: 'warning',
			modalBody: <ContractPreferenceModal index={idx} callback={doHandleContractPreference} />,
		};
	}

	const handleEditRatePerHour = () => {
		setRate(rate);
		setEditRate(!editRate);
	};
	useEffect(() => {
		if (editRate && inputRateRef.current) {
			inputRateRef.current.focus();
		}
	}, [editRate]);
	const handleCloseSuccessModal = () => {
		setShowSuccessModal(false);
	};
	const handleCancel = () => {
		setShowModal(false);
	};

	function toggleSave(id) {
		function successHandler() {
			setMessage([...message, { text: 'Job saved successfully' }]);

			clearMessages();
		}
		function errorHandler(response) {
			const result = response.data.result;
			setMessage([...message, { text: result }]);
			clearMessages();
		}

		if (userType === 'talent') {
			api.talents.jobs.addToBookmark(id).then(successHandler).catch(errorHandler);
		} else {
			api.freelancers.jobs.addToBookmark(id).then(successHandler).catch(errorHandler);
		}
	}

	function clearMessages() {
		function clear() {
			setMessage([]);
		}

		setTimeout(clear, service.errorMilliSecond);
	}

	const handleOnChange = (e) => {
		const value = e.target.value;
		setSearch(value);
	};

	const doFeedback = () => {
		setModalIsLoading(true);
		api.talents
			.createFeedback(feedback)
			.then((res) => {
				if (res.status === 200) {
					setShowModal(false);
					setShowSuccessModal(true);
					setFeedback(feedback);
				}
			})
			.catch((res) => console.log(res))
			.finally(() => setModalIsLoading(false));
	};

	const handleSubmit = (e) => {
		e?.preventDefault();
		if (search.trim()) {
			navigate({ pathname: '/dashboard/search', search: `type=${search}&search=${search}&callback=find-job` });
		}
	};

	function handleReferral() {
		setInvite('Sending Invite');
		const data = {
			email: talent,
		};

		function handleSuccess(response) {
			if (response.success === true) {
				setInvite('Invite sent!');
			}
		}
		function handleError(response) {
			if (response.success === false) {
				setInvite('Try again');
			} else if (response.data.result === 'user already signed up') {
				alert('user already signed up');
			}
		}

		function onFinal() {
			setTimeout(() => {
				setTalent('');
				setInvite(invite);
			}, 2000);
		}
		if (userType === 'talent') {
			api.talents.refer.referTalent(data).then(handleSuccess).catch(handleError).finally(onFinal);
		} else {
			api.freelancers.refer.referTalent(data).then(handleSuccess).catch(handleError).finally(onFinal);
		}
	}

	function getAdverts() {
		function successHandler(response) {
			const talentRooms = response?.data.filter((room) => room.name === 'talent');

			talentRooms.forEach((room) => {
				api.adverts
					.getSpaces(room._id)
					.then((spacesResponse) => {
						const spacesData = spacesResponse.data;

						// Transform the array into an object keyed by space name
						const spacesByName = spacesData.reduce((acc, item) => {
							acc[item.name] = {
								_id: item._id,
								room: item.room,
								desktop: item?.imagePlaceholders?.desktop,
								mobile: item?.imagePlaceholders?.mobile,
								tablet: item?.imagePlaceholders?.tablet,
							};
							return acc;
						}, {});

						// Save the transformed data into the state
						setSpaces(spacesByName);
					})
					.catch((error) => {
						console.error(`Error fetching spaces for recruiter room ${room._id}:`, error);
					});
			});
		}

		function errorHandler(error) {
			console.error('Error fetching rooms:', error);
		}
		api.adverts.getRooms().then(successHandler).catch(errorHandler);
	}

	useEffect(getAdverts, []);

	const updateRate = () => {
		setIsLoading(true);
		function successHandler(response) {
			getUser();
		}
		if (rate) {
			const hourlyRate = parseFloat(rate) || 0;
			const serviceCharge = hourlyRate * 0.1;
			const expectedIncome = hourlyRate - serviceCharge;

			api.freelancers.profile
				.update({ hourly_rate: hourlyRate, expected_income: expectedIncome, expected_salary_currency: selectedCurrency })
				.then(successHandler)
				.catch(console.error)
				.finally(() => setIsLoading(false));
		}
	};

	return (
		<div id="Dashboard_Talent_Main_Container">
			<Navbar {...navbarComp} />

			<div className="con-margin-height">
				<div className="con-section container-01">
					<div className="row mb-5">
						<div className="col-12 col-lg-8 mb-5 mb-lg-0 overflow-hidden">
							<div className="con-section-1">
								<div className="section-c ">
									<div className="title d-flex justify-content-between align-items-center py-3 border-bottom">
										<div>
											<h2>Recommended just for you</h2>
											{/* <p className="mt-2 mb-4">Based on your profile</p> */}
										</div>

										<div>
											{recommended.length > 0 && (
												<a class="view_more" href="/dashboard/jobs/recommended-jobs">
													View more
													<i className="fa-solid fa-arrow-right mx-2"></i>
												</a>
											)}
										</div>
									</div>

									<div className="body my-3" ref={cardARef} onScroll={handleCardAScroll}>
										<div className="con-card-job con-card-jod-e gap-4 ">
											{!isLoading &&
												recommended.length > 0 &&
												displayedRecommendedJobs.map((job, y) => (
													<div className="card-job colored_card" key={y}>
														<div className=" d-flex align-items-center justify-content-between black_header">
															<div className="d-flex align-items-center">
																{[1, 1, 1].map((_, y) => (
																	<div className={`box box-${y + 1}`} key={y}></div>
																))}
															</div>

															<i className="fa-solid fa-xmark"></i>
														</div>

														<div className="context d-flex align-items-start gap-3">
															<div className="con-class d-flex justify-content-between" style={{ width: '100%' }}>
																<div className="class-1" style={{ width: '90%' }}>
																	<h2 className="d-flex align-items-center gap-3" title={job.title}>
																		{service.truncateText(job.title, 18)} <i>{job.job_type}</i>
																	</h2>
																	<h3 className="mt-2 mb-3" title={job?.company_name}>
																		{job?.company_name ? service.truncateText(job?.company_name) : `company name`}
																	</h3>
																	{job?.world_wide === true ? (
																		<h4 className="mb-3">{job?.world_wide && service.truncateText(service.titleCase('worldwide'))}</h4>
																	) : (
																		<h4 className="mb-3" title={`${job?.location?.state} ${job?.location?.country}`}>
																			{job?.location?.state ? service.truncateText(service.titleCase(job?.location.state), 12) : `state`}, {job?.location?.country ? service.truncateText(service.titleCase(job?.location.country), 12) : `country`}
																		</h4>
																	)}
																	<p>
																		{job?.currency}
																		{job.expected_pay.toLocaleString()}/yr
																	</p>
																</div>

																<div className="class-2 d-flex flex-column align-items-end justify-content-between" style={{ width: '10%' }}>
																	<i className="fa-regular fa-bookmark" onClick={() => toggleSave(job?._id)}></i>

																	<Link to={`/dashboard/jobs/${job?._id}`} className="arrow-btn outline">
																		<i className="fa-solid fa-arrow-right"></i>
																	</Link>
																</div>
															</div>
														</div>
													</div>
												))}
										</div>
										{!isLoading && recommended.length < 1 && (
											<div className="d-flex flex-column justify-content-center align-items-center text-center m-auto">
												<img src="/img/svg/nojob.svg" alt="oops" className="img-fluid w-25" style={{ width: '100px' }} />
												<h3 className="my-2">Oops!</h3>
												<p>Complete your profile to have recommended courses tailored to you</p>
											</div>
										)}
										<div className="con-animated-bg">{isLoading && [1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
									</div>

									{recommended.length > itemsPerPage && (
										<div className="d-flex justify-content-center mb-3 py-3">
											<DotPagination totalItems={recommended.length} itemsPerPage={itemsPerPage} activeIndex={recommendedPageIndex} onChange={handleRecommendedPageChange} />
										</div>
									)}
								</div>

								{spaces['Top left']?.desktop?.url !== null && (
									<div className="small_advert">
										<img src={spaces['Top left']?.desktop?.url} alt="" />
									</div>
								)}

								<form className="section-b outline-wrap" onSubmit={handleSubmit}>
									{/* <div className="con-select">
										<select>
											<option value="">Search for Opportunity</option>
										</select>
									</div> */}
									<div className="con-input my-3 my-lg-0">
										<input type="text" placeholder="Search for jobs by title, like Designer..." onChange={handleOnChange} />
									</div>
									<button className="con-submit d-flex d-lg-block" onClick={handleSubmit}>
										<div className="arrow-btn">
											<img src="/img/svg/search.svg" alt="" style={{ width: '18px', height: '18px' }} />
										</div>
									</button>
								</form>
								{recommended.length > 0 && (
									<div className="section-c">
										<div className="title">
											<h2 className="mt-2 mb-4">Jobs on {user?.title && service.titleCase(user?.title)}</h2>
										</div>

										<div className="body">
											<div className="con-card-job">{!isLoading && recommended.length > 0 && displayedJobs.map((job, y) => job.status === 'published' && <JobCard key={y} data={job} />)}</div>
											<div className="con-animated-bg">{isLoading && [1, 1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
										</div>

										{recommended.length > 2 && (
											<div className="footer d-flex justify-content-center">
												<IndexPagination pageCount={pageCount} currentPage={currentPage} onPageChange={onPageChange} />
											</div>
										)}
									</div>
								)}

								<div className="section-c my-4 " style={{ minHeight: '650px', maxHeight: '100%' }}>
									<div className="title d-flex justify-content-between align-items-center py-3 border-bottom">
										<h2>Jobs applied for</h2>
										<a class="view_more" href="/dashboard/jobs/applied-jobs">
											View more
											<i className="fa-solid fa-arrow-right mx-2"></i>
										</a>
									</div>

									<div className="body my-3" ref={cardARef} onScroll={handleCardAScroll}>
										<div className="con-card-job  con-card-jod-e">
											{!isLoading &&
												appliedJobs.length > 0 &&
												appliedJobs.slice(0, 8).map(({ job }, y) => {
													return (
														<div className="card-job" key={y}>
															<div className="header d-flex align-items-center  justify-content-between">
																<div className="d-flex align-items-center gap-2">
																	{[1, 1, 1].map((_, y) => (
																		<div className={`box box-${y + 1}`} key={y}></div>
																	))}
																</div>

																<i className="fa-solid fa-xmark"></i>
															</div>

															<div className="context d-flex align-items-start gap-3">
																<div className="con-class d-flex justify-content-between" style={{ width: '100%' }}>
																	<div className="class-1" style={{ width: '90%' }}>
																		<h2 className="d-flex align-items-center gap-3">
																			{job?.title} <i>{job?.job_type}</i>
																		</h2>
																		<h3 className="mt-2 mb-3" title={job?.company_name}>
																			{job?.company_name ? service.truncateText(job?.company_name) : `company name`}
																		</h3>
																		<p>
																			{job?.currency}
																			{job?.expected_pay.toLocaleString()}/yr
																		</p>
																	</div>

																	<div className="class-2 d-flex flex-column align-items-end justify-content-between" style={{ width: '10%' }}>
																		<i className="fa-regular fa-bookmark" onClick={() => toggleSave(job?._id)}></i>

																		<Link to={`/dashboard/jobs/${job?._id}`} className="arrow-btn outline">
																			<i className="fa-solid fa-arrow-right"></i>
																		</Link>
																	</div>
																</div>
															</div>
														</div>
													);
												})}

											{!isLoading && appliedJobs.length < 1 && (
												<div className="d-flex flex-column justify-content-center align-items-center text-center " style={{ translate: '50%', height: '650px', maxHeight: 'auto' }}>
													<img src="/img/svg/nojob.svg" alt="oops" className="img-fluid w-25" />
													<h3 className="my-2">Oops!</h3>
													<p>You haven't applied for any job</p>
												</div>
											)}
										</div>
										<div className="con-animated-bg">{isLoading && [1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-4">
							<div className="con-section-2">
								{!isLoadingUser && user && (
									<div style={premiumGold}>
										<h3 className="text-center premium_user my-2">{premiumUser?.status === 'active' ? 'Premium' : 'Regular'} User</h3>
										<div className="con-user-dp align-items-start px-3" style={backgroundStyle}>
											<div className="d-flex align-items-center gap-3">
												<div className="con-user-img">
													<div className="bg">
														<div className="img">
															<img src={user?.profile_picture?.href_link || '/img/pic.jpg.png'} alt="" />
														</div>
													</div>
													<div className="icon"></div>
												</div>

												<div className="con-user-data d-flex flex-column  my-3">
													<div style={{ display: 'flex', gap: '5px' }}>
														<h2 className={`user-name ${premiumUser ? 'text-white' : 'text-black'} `} title={`${user?.first_name} ${user?.last_name}`}>
															{user?.first_name && service.titleCase(user?.first_name)} {user?.last_name && service.titleCase(user?.last_name)}
														</h2>
														<img src={user?.isProfileApproved ? '/img/svg/verified.svg' : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
													</div>
													<p className={`user-role ${premiumUser ? 'text-white' : 'text-black'}`} title={user?.title}>
														{user?.title && service.titleCase(service.truncateText(user?.title, 20))}
													</p>
													<Link to={'/dashboard/profile'} className="user-cta mt-1">
														View Profile
													</Link>
													<div className="user-visible"></div>
												</div>
											</div>
											<div className=" w-100 badge_con">
												<h4 className={`my-1 ${premiumUser ? 'text-white' : 'text-black'}`}>Badge</h4>

												<div className="badge w-100 d-flex gap-2 justify-content-start align-items-center" style={badgeStyle}>
													{badges?.length > 0 ? (
														badges.map(({ badge }, index) => {
															return (
																<div key={badge._id} className="badge_img" style={badgeImgStyle} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
																	<img src={badge.image} alt="" />
																	<div className="hover_text" style={hoveredIndex === index ? hoverTextVisibleStyle : hoverTextStyle}>
																		{badge.name}
																	</div>
																</div>
															);
														})
													) : (
														<p className={premiumUser?.status === 'active' ? 'badge_empty' : 'badge_empty_gray'}>You have no badge yet</p>
													)}
												</div>
											</div>
										</div>
									</div>
								)}
								{!isLoadingUser &&
									user && (
										<div className="d-flex justify-content-start  align-items-center gap-3 my-3 ps-3">
											<div>
												<h2 className="link" style={{ fontWeight: 600, fontSize: '.9rem', color: '#CC5600', cursor: 'pointer', textDecoration: 'underline' }} data-bs-toggle="modal" data-bs-target="#premium-perks">
													Premium Perks
												</h2>
											</div>
											{premiumUser?.status !== 'active' && (
												<div>
													<a href="/dashboard/to-premium?section=1" className="premium_perk d-flex gap-1">
														<div>
															<img src="/img/svg/crown.svg" alt="" style={{ width: 'unset', height: 'unset' }} />
														</div>
														<p style={{ textDecoration: 'underline' }}>Upgrade to Premium</p>
													</a>
												</div>
											)}
										</div>
									)}
								<div className="con-animated-bg">{isLoadingUser && [1, 1, 1].map((x, y) => <div className={'animated-bg'} key={y}></div>)}</div>

								{!isLoadingUser && user && (
									<div className="sections">
										<div className="mb-5">
											<ProfileLevel level={complete?.completeness_value} />
										</div>
										<div className="my-4  feedButton">
											<div className="my-4 d-flex gap-2 w-100">
												<Button
													text={
														<>
															<i class="fa-regular fa-comment"></i>
															Leave a Feedback
														</>
													}
													onClick={() => setShowModal(true)}
													primaryNull
												/>

												{showModal && (
													<FeedBackModal
														option={true}
														title="Enter Your Question Here"
														modalTitle={'FEEDBACK'}
														details={feedback}
														Subject={'Subject'}
														subjects={[
															{ value: 'sub1', label: 'Sub 1' },
															{ value: 'sub2', label: 'Sub 2' },
															{ value: 'sub3', label: 'Sub 3' },
														]}
														placeHolderText={'What do you want to write about?'}
														handleChange={handleFeedbackChange}
														handleSubmit={doFeedback}
														onCancel={handleCancel}
														isLoading={isModalLoading}
													/>
												)}

												{showSuccessModal && <SuccessModal text="Feedback Sent!" handleClose={handleCloseSuccessModal} modalTitle={'FEEDBACK'} modalMessage={'Your feedback has been submitted successfully'} />}
											</div>
										</div>

										{spaces['Bottom left']?.tablet?.url !== null && (
											<div className="bg_advert">
												<img src={spaces['Bottom left']?.tablet?.url} alt="" />
											</div>
										)}

										<div className="section-001 py-3">
											<div className="con-title my-3">
												<h2>Job Availability</h2>
											</div>

											<div className="con-body d-flex align-items-center gap-2" onClick={doHandleAvailability}>
												<div className={`checkbox`} style={{ backgroundColor: jobAvailable && '#CC5600' }}>
													<div className={`checker ${classNames(jobAvailable && `active`)}`}></div>
												</div>
												<p>Open for jobs at the moment</p>
											</div>
										</div>
										<div className="rate_perhour border-top py-3">
											{userType === service.FREELANCER && (
												<div>
													<h4>Rate (per hour)</h4>

													<div className="per_hour_input_con my-3">
														{editRate && (
															<select value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
																<option defaultValue>Select Currency</option>

																{allCurrency.map((currency) => {
																	return (
																		<option value={currency} key={currency} className="my-3">
																			{currency}
																		</option>
																	);
																})}
															</select>
														)}
														<CurrencyInput
															disabled={!editRate}
															prefix={currencyToSymbolMap[user?.expected_salary_currency]}
															suffix="/h"
															id="input-example"
															name="input-name"
															placeholder="Please enter a your rate per hour"
															decimalsLimit={2}
															onValueChange={(value) => setRate(value)}
															value={rate}
															className="per_hour_input"
															ref={inputRateRef}
														/>

														<div>
															<div className="edit_action_img " onClick={handleEditRatePerHour}>
																<img
																	src={!editRate ? '/img/svg/edit3.svg' : '/img/svg/save2.svg'}
																	alt=""
																	onClick={() => {
																		if (editRate) {
																			updateRate();
																		} else {
																			setEditRate(true);
																		}
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
										<div className="border-bottom  my-3">
											{userType === 'talent' && (
												<div className="section-002 my-3">
													<div className="con-title">
														<h2>Contract Preference</h2>
													</div>

													<div className="con-body d-flex align-items-lg-center flex-column flex-lg-row gap-3">
														{contractPreference.map((x, y) => (
															<div key={y}>
																<Button primaryNull={contractPreference[contractPreferenceIdx] !== x} darkFill text={<h2>{service.titleCase(x)}</h2>} {...selectContractPreference({ idx: y })} />

																<Modal {...selectContractPreference({ idx: y })} />
															</div>
														))}
													</div>
												</div>
											)}
										</div>
										<div>
											<h3 className="referral_title my-2">Referrals</h3>
											<div className="referral_bg  d-flex justify-content-center  flex-column  align-items-center">
												<div className="mb-4">
													<div className="referrals_img">
														<img src="/img/handshake.png" alt="hand shake" />
													</div>
												</div>
												<div className="bg-white px-3 py-2 ">
													<h2 className="referral_text">
														Refer <span>FRONTEERS</span> to a <br /> Talent
													</h2>
												</div>
												<h2 style={{ cursor: 'pointer' }} className="premium_perk my-3" data-bs-toggle="modal" data-bs-target="#referral-link">
													See Perks
												</h2>

												<div className="w-100">
													<div className="search_talent w-100">
														<input type="text" onChange={(e) => setTalent(e.target.value)} placeholder="Enter Talent email" value={talent} />
													</div>
												</div>
												<div className="my-4">
													<Button darkFill text={<h2>{invite}</h2>} onClick={handleReferral} disabled={talent === ''} />
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				{...{
					modalTarget: 'premium-perks',
					modalWidth: '540px',
					modalTitle: 'premium perks',
					modalBody: (
						<div className="referal-modal-body d-flex flex-column p-4 py-5">
							<h2 style={{ color: '#CC5600', fontWeight: 700, textAlign: 'center' }}>Perks of being a Premium User</h2>
							<div className={`d-flex flex-column gap-4 py-4`}>
								<ul>
									{premiumUser?.plan?.perks?.map((perk, index) => (
										<li key={index} className="d-flex gap-3 mt-2">
											<div>{star('#CC5600')}</div>
											<span>{perk}</span>
										</li>
									))}
								</ul>
							</div>
						</div>
					),
				}}
			/>
			<Modal
				{...{
					modalTarget: 'premium-perks',
					modalWidth: '540px',
					modalTitle: 'premium perks',
					modalBody: (
						<div className="referal-modal-body d-flex flex-column align-items-center gap-4 py-5">
							<h2 style={{ color: '#CC5600', fontWeight: 700 }}>Perks of being a Premium User</h2>
							<div className={`d-flex flex-column align-items-center gap-4 py-4`}>
								<ul>
									{premiumUser?.plan?.perks?.map((perk, index) => (
										<li key={index} className="d-flex gap-3 mt-2">
											<div>{star('#CC5600')}</div>
											<span>{perk}</span>
										</li>
									))}
								</ul>
							</div>
						</div>
					),
				}}
			/>
			<Modal
				{...{
					modalTarget: 'referral-link',
					modalWidth: '540px',
					modalTitle: 'refer a talent',
					modalBody: (
						<div className="referal-modal-body d-flex flex-column align-items-center gap-4 py-5">
							<img src="/img/Vector.png" alt="" />
							<p>Refer a talent and get 20% off his first employment</p>
						</div>
					),
				}}
			/>

			{message.length > 0 && <Message messages={message} />}
		</div>
	);
}

export default Talent;

import Tab from 'components/buttons/tabs/Tabs';
import style from './css/FilterModal.module.sass';
import { useState } from 'react';
import { Country, State } from 'country-state-city';

function FilterModal({ onFilterChange }) {
	const [selectedFilters, setSelectedFilters] = useState({});
	const [selectedGender, setSelectedGender] = useState('');
	const [selectedContractType, setSelectedContractType] = useState('');
	// const [selectedStatusType, setSelectedStatusType] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedState, setSelectedState] = useState('');
	const [selectedJobType, setSelectedJobType] = useState('')

	// eslint-disable-next-line no-unused-vars
	const [countries, setCountries] = useState(Country.getAllCountries());
	const [statesOfSelectedCountry, setStatesOfSelectedCountry] = useState([]);

	// New function to handle gender selection
	function handleGenderSelection(gender) {
		setSelectedFilters((prevFilters) => ({
			...prevFilters,
			gender,
		}));
		setSelectedGender(gender);
	}

	// New function to handle contract type selection
	function handleContractTypeSelection(contract_type) {
		setSelectedFilters((prev) => ({
			...prev,
			contract_type,
		}));
		setSelectedContractType(contract_type);
	}


	// function handleStatusTypeSelection(status) {
	// 	setSelectedFilters((prev) => ({
	// 		...prev,
	// 		status,
	// 	}));
	// 	setSelectedStatusType(status);
	// }

	// New function to handle continent selection
	function handleJobTypeSelection(job_type) {
		setSelectedFilters((prev) => ({
			...prev,
			job_type,
		}))
		setSelectedJobType(job_type)
	}

	// New function to handle location selection
	function handleCountrySelection(event) {
		const selectedCountryName = event.target.value;
		const selectedCountry = countries.find((country) => country.name === selectedCountryName);
		const { name: country } = selectedCountry
		if (selectedCountry) {
			setSelectedFilters((prev) => ({
				...prev,
				country,
			}));
			setSelectedCountry(country);
			const states = State.getStatesOfCountry(selectedCountry.isoCode);
			setSelectedFilters((prev) => ({
				...prev,
				state: '',
			}));
			setStatesOfSelectedCountry(states);
		} else {
			setSelectedFilters((prev) => ({
				...prev,
				country: '',
				state: '',
			}))
			setStatesOfSelectedCountry([]);
		}
	}

	function handleStateSelection(event) {
		const selectedStateName = event.target.value;
		const selectedState = statesOfSelectedCountry.find((state) => state.name === selectedStateName);
		const { name: state } = selectedState
		if (selectedState) {
			setSelectedFilters((prev) => ({
				...prev,
				state,
			}));
			setSelectedState(state);
		} else {
			setSelectedFilters((prev) => ({
				...prev,
				state: 'No state',
			}));
			setSelectedState({ name: 'No state' })
		}
	}

	// Function to handle the "Apply" button click
	function handleApplyFilters() {
		// Call the onFilterChange callback with the selected filters
		onFilterChange(selectedFilters);
	}
	function cancelFilters() {
		setSelectedFilters({});
		setSelectedGender('');
		setSelectedContractType('');
		// setSelectedStatusType('');
		setSelectedCountry('');
		setSelectedState('');
		setSelectedJobType('');
		setStatesOfSelectedCountry([]);
	}

	return (
		<div className={`${style['modal-container']} modal fade`} id="filter-modal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div className={`${style.dialog} modal-dialog`}>
				<div className={`${style['modal-content']} modal-content`}>
					<div className={`${style['modal-header']} modal-header`}>
						<div className={`${style['modal-title']} modal-title d-flex align-items-center gap-3`} id="staticBackdropLabel">
							<i className="fa-solid fa-sliders"></i>
							<p>Filters</p>
						</div>
						<button type="button" className="fa-solid fa-arrow-left" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>

					<div className={`${style['modal-body']} modal-body`}>
						<div className={style['con-section']}>
							<div className={style['section-a']}>
								<div className={style.title}>
									<i className="fa-solid fa-location-dot"></i>
									<p>Gender</p>
								</div>
								<div className={style.context}>
									<div className={style['con-tab']}>
										<div className="d-flex align-items-center gap-1 mb-3">
											{['Male', 'Female', 'Any'].map((x, y) => (

												<Tab primaryOutline darkBg={selectedGender === x.toLowerCase()} context={x} key={y} callback={() => handleGenderSelection(x.toLowerCase())} />
											))}
										</div>
									</div>
								</div>
							</div>

							<div className={style['section-b']}>
								<div className={style.title}>
									<i className="fa-solid fa-location-dot"></i>
									<p>Contract Type</p>
								</div>
								<div className={style.context}>
									<div className={style['con-tab']}>
										<div className="d-flex align-items-center gap-1 mb-3">
											{['Full-Time', 'Part-Time', 'Freelancer'].map((x, y) => (
												<Tab primaryOutline darkBg={selectedContractType === x.toLowerCase()} context={x} key={y} callback={() => handleContractTypeSelection(x.toLowerCase())} />
											))}
										</div>
									</div>
								</div>
							</div>


							{/* <div className={style['section-b']}>
								<div className={style.title}>
									<i className="fa-solid fa-location-dot"></i>
									<p>Status</p>
								</div>
								<div className={style.context}>
									<div className={style['con-tab']}>
										<div className="d-flex align-items-center gap-1 mb-3">
											{['Verified', 'Pending'].map((x, y) => (
												<Tab primaryOutline darkBg={selectedStatusType === x.toLowerCase()} context={x} key={y} callback={() => handleStatusTypeSelection(x.toLowerCase())} />
											))}
										</div>
									</div>
								</div>
							</div> */}

							<div className={style['section-c']}>
								<div className={style.title}>
									<i className="fa-solid fa-location-dot"></i>
									<p>Job Type</p>
								</div>
								<div className={style.context}>
									<div className={style['con-tab']}>
										<div className="d-flex align-items-center gap-1 mb-3">
											{['Remote', 'Onsite', 'Hybrid'].map((x, y) => (
												<Tab primaryOutline darkBg={selectedJobType === x.toLowerCase()} context={x} key={y} callback={() => handleJobTypeSelection(x.toLowerCase())} />
											))}
										</div>
									</div>
								</div>
							</div>

							<div className={style['section-d']}>
								<div className={style.title}>
									<i className="fa-solid fa-location-dot"></i>
									<p>Location</p>
								</div>
								<div className={style.context}>
									<div className={style['con-tab']}>
										<div className="d-flex align-items-center gap-1 mb-3">
											<div className="control-form">
												<select name="" value={selectedCountry} onChange={handleCountrySelection}>
													<option>Select Country</option>
													{countries.map(({ name }, y) => {
														return (
															<option key={y} value={name}>
																{name}
															</option>
														);
													})}
												</select>
											</div>
										</div>
										<div className="d-flex align-items-center gap-1 mb-3">
											<div className="control-form">
												<select name="" value={selectedState} onChange={handleStateSelection}>
													<option>Select State</option>
													{statesOfSelectedCountry.length === 0 ? (
														<option key={'no_state'} value="No State">No State</option>
													) : statesOfSelectedCountry.map(({ name }, y) => (
														<option key={y} value={name}>
															{name}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div style={{ display: 'flex', gap: '10px' }}>
								<button type="button" className="btn btn-dark text-white py-2 px-4 rounded-1" data-bs-dismiss="modal" onClick={handleApplyFilters}>
									Apply
								</button>
								<button type="button" className="btn btn-dark text-white py-2 px-4 rounded-1" data-bs-dismiss="modal" onClick={cancelFilters}>
									Reset Filter
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FilterModal;

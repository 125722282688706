import * as Yup from 'yup';

export const values = {
	phone_number: '',
	company_name: '',
	recruiter_position: '',
	company_website: '',
	staff_capacity: '1-10',
};

export const schema = Yup.object().shape({
	phone_number: Yup.string()
		.matches(/^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,8}$/im, 'invalid phone number')
		.required('phone number is required'),
	company_name: Yup.string().required('company name is required'),
	recruiter_position: Yup.string().required('company position is required'),
	company_website: Yup.string().required('company url/link is required'),
});
